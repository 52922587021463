import React, { useState, useEffect, useRef } from 'react';
import LoginForm from './forms/LoginFormConponent';
import MarkerThumbnailComponent from './MarkerThumbnailComponent';
import RegisterForm from './forms/RegisterFormConponent';
import './Unregistered.css';
import { Tabs, Tab } from 'react-bootstrap';
import googleLogo from './assets/google_G_logo.png';
import fbLogo from './assets/f_logo_RGB-Blue_250.png';
import { TextureLoader } from 'three';
import CloseButton from 'react-bootstrap/CloseButton'
import { Loader } from '@googlemaps/js-api-loader';
import { CreateFileFromLocal } from './helper/image.js'
import GoogleMapComponent from './GoogleMapComponent'
import { NearestPointOnPath, DouglasPeucker, NormalizePoint, FilterPlaceResults } from './helper/calculation.js'
import ImageViewer from './ImageViewerComponent';

const loader = new Loader({
    apiKey: "AIzaSyDCHOO41I21tKSMCzpoXI0Aizz39epDs2g",
    version: "beta",
    mapIds: ["1a9316535142ffae"],
    libraries: ["geometry", "places"]
});

const defaultMapOptions = {
    backgroundColor: "#9ec1f7",
    center: {
        lat: 21.5022096,
        lng: -157.88833
    },
    minZoom: 9.5,
    maxZoom: 12,
    tilt: 45,
    mapId: "1a9316535142ffae",
    clickableIcons: false, // do not allow clicking of anything on Google Map
    disableDefaultUI: true,
};

const hawaiiDemo = {
    init: {
        lat: 21.5022096,
        lng: -157.88833,
        zoom: 13
    },
    points: [
        {
            name: "Polynesian Cultural Center",
            lat: 21.640183333333333,
            lng: -157.92065555555556,
            image: "/demo/hawaii/01Polynesian.jpg",
            originalImageWidth: 2743,
            originalImageHeight: 3657,
        },
        {
            name: "Pearl Harbor",
            lat: 21.368537,
            lng: -157.938961,
            image: "/demo/hawaii/02PearlHarbor.jpg",
            originalImageWidth: 3873,
            originalImageHeight: 2182,
        },
        {
            name: "Hanauma Bay Lookout",
            lat: 21.2728,
            lng: -157.69615277777777,
            image: "/demo/hawaii/03Hanauma.jpg",
            originalImageWidth: 3546,
            originalImageHeight: 2659,
        }
    ]
};

const UnregisteredComponent = (props) => {
    const [tabKey, setTabKey] = useState('login');
    const [formClass, setFormClass] = useState("unregistered-div-hidden");
    const [map, setMap] = useState();
    const [maps, setMaps] = useState();
    const [directionsService, setDirectionsService] = useState();
    const [markers, setMarkers] = useState([]);
    const stateRef = useRef();
    const googleMapJs = useRef();
    const [googleMapRefReady, setGoogleMapRefReady] = useState(false);
    const [displayImage, setDisplayImage] = useState(false);
    const [clickedMarker, setClickedMarker] = useState();


    stateRef.current = tabKey;

    const [data, setData] = useState("");
    const [formError, setFormError] = useState(false);
    const [formErrorText, setFormErrorText] = useState("");

    useEffect(() => {
        /*
            DO NOT USE new Google Identity Service Sign In With Google.
            need a way to customize css style of the login button.
         */
        const googleScriptTag = document.createElement('script');
        googleScriptTag.defer = true;
        // googleScriptTag.src = 'https://accounts.google.com/gsi/client';
        // googleScriptTag.addEventListener('load', () => {
        //     console.log("Google Script is loaded.");
        //     window.google.accounts.id.initialize({
        //         client_id: "<GOOGLE CLIENT ID>",
        //         callback: handleGoogleAuthEvent // need to change this to pass token.credential
        //     });
        //     window.google.accounts.id.renderButton(
        //         document.getElementById("buttonDiv"),
        //         {
        //             theme: "outline",
        //             size: "large",
        //             text: "continue_with",
        //             type: "standard",
        //             logo_alignment: "left"
        //         }
        //     );
        // });

        // Google Sign-In (Legacy)
        googleScriptTag.src = 'https://apis.google.com/js/platform.js';
        googleScriptTag.addEventListener('load', () => {
            window.gapi.load('auth2', function () {
                // Retrieve the singleton for the GoogleAuth library and set up the client.
                let auth2 = window.gapi.auth2.init({
                    client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID
                });
                auth2.attachClickHandler("sign-in-button-test", {},
                    function (googleUser) {
                        console.log("onsuccess");
                        let googleToken = googleUser.getAuthResponse().id_token;
                        handleGoogleAuthEvent(googleToken);
                    }, function (error) {
                        console.log(error);
                    });
            });
            // DO NOT USE render, because this will automatically sign in the current user, which triggers onsuccess.
            // window.gapi.signin2.render('sign-in-with-google', {
            //     'scope': 'profile', // default: profile
            //     'width': "auto", // default: 120
            //     'height': 50, // default: 36
            //     'longtitle': true,
            //     'theme': 'light',
            //     'onsuccess': function(googleUser) {
            //         let googleToken = googleUser.getAuthResponse().id_token
            //     },
            //     'onfailure': function() {
            //         console.log("onfailure");
            //     }
            //   });
        });
        document.body.appendChild(googleScriptTag);

        // DO NOT NEED meta tag now, since we pass google id in init()
        // create meta tag
        // let googleMetaTag = document.createElement('meta');
        // googleMetaTag.name = 'google-signin-client_id';
        // googleMetaTag.content = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
        // document.head.appendChild(googleMetaTag);
        loader.load().then((google) => {
            let _map = new google.maps.Map(
                document.getElementById("landing-googlemap"),
                defaultMapOptions);
            setMap(_map);
            setMaps(google.maps);
            setDirectionsService(new google.maps.DirectionsService);
            googleMapSetRestriction(_map);
        });

    }, []);

    useEffect(() => {
        if (googleMapJs != null && googleMapJs.current != null) {
            setGoogleMapRefReady(true);
        }
    }, [googleMapJs, googleMapJs.current]);

    useEffect(() => {
        if (googleMapRefReady === true) {
            let _markers = [];
            let demo = hawaiiDemo;
            for (let i = 0; i < demo.points.length; i++) {
                CreateFileFromLocal(
                    demo.points[i].image,
                    demo.points[i].name,
                    "image/jpeg",
                    demo.points[i].originalImageWidth,
                    demo.points[i].originalImageHeight,
                    function (newFile) {
                        let marker = {
                            location: demo.points[i],
                            file: newFile
                        }
                        _markers = [..._markers, marker];
                        setMarkers(_markers);
                    }
                );
            }

            // draw route, do not need to wait for image to fully load
            let origin = {
                lat: 21.640183333333333,
                lng: -157.92065555555556,
            };
            let destination = {
                lat: 21.2728,
                lng: -157.69615277777777
            };
            let waypoints = [{
                location: {
                    lat: 21.368537,
                    lng: -157.938961,
                }
            }];
            googleMapJs.current.route(origin, destination, waypoints);
        }
    }, [googleMapRefReady]);

    /*
        used by Facebook Login In button click event
     */
    function handleFacebookAuthEvent() {
        console.log("handleFacebookAuthEvent " + stateRef.current);
        if (stateRef.current === "login") {
            handleFacebookLoginEvent();
        } else if (stateRef.current === "register") {
            handleFacebookRegisterEvent();
        } else {
            console.log("Something wrong. Please reload page and try again.");
        }
    }

    /*
        used by new Google Identity Service Sign In With Google
    */
    function handleGoogleAuthEvent(gToken) {
        console.log("handleGoogleAuthEvent " + stateRef.current);
        if (stateRef.current === "login") {
            handleGoogleLoginEvent(gToken);
        } else if (stateRef.current === "register") {
            handleGoogleRegisterEvent(gToken);
        } else {
            console.log("Something wrong. Please reload page and try again.");
        }
    }

    function handleLoginEvent(values) {
        console.log("handleLoginEvent");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "email": values.email,
                "password": values.password
            })
        };
        fetch("/api/user/login", requestOptions)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((res) => res.json())
            .then((data) => {
                props.onLoginFormSuccessful(data);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setFormError(true);
                setFormErrorText("Error! Please try again.");
            });
    }

    function handleFacebookLoginEvent() {
        console.log("handleFacebookLoginEvent");
        if (hasValidFbToken()) {
            console.log("fb is already connected.");
            onFbLoginSuccessful();
        } else {
            console.log("fb is NOT connected.");
            window.FB.login(function (response) {
                if (response.authResponse) {
                    onFbLoginSuccessful(response);
                } else {
                    console.log('User cancelled login or did not fully authorize.');
                }
            });
        }
    }

    function handleFacebookRegisterEvent() {
        if (hasValidFbToken()) {
            registerFacebookUser(props.fbToken);
        } else {
            window.FB.login(function (response) {
                if (response.authResponse) {
                    props.setFbToken(response.authResponse);
                    registerFacebookUser(response.authResponse);
                } else {
                    console.log('User cancelled login or did not fully authorize.');
                }
            });
        }
    }

    // login this user using fb response and fb id.
    function onFbLoginSuccessful(fbLoginResp) {
        if (fbLoginResp) props.setFbToken(fbLoginResp.authResponse);
        window.FB.api('/me', function (meResp) {
            console.log(meResp);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "facebook_id": meResp.id,
                    "facebook_access_token": fbLoginResp?.authResponse?.accessToken ?? props.fbToken?.accessToken
                })
            };
            fetch("/api/user/login", requestOptions)
                .then(function (resp) {
                    if (!resp.ok) {
                        return resp.text().then(text => { throw new Error(text) })
                    } else {
                        return resp;
                    }
                })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                    props.onLoginFormSuccessful(data);
                })
                .catch(err => {
                    console.error('There was an error!', err);
                    console.log(err);
                    props.setLoggedIn(false);
                    setFormError(true);
                    if (err.message == "User not registered") {
                        setFormErrorText("Please register with Facebook");
                    } else {
                        setFormErrorText("Error! Please try again.");
                    }
                });
        });
    }

    function hasValidFbToken() {
        if (props.fbToken?.data_access_expiration_time && props.fbToken.data_access_expiration_time > new Date().getTime() / 1000) {
            return TextureLoader;
        } else {
            return false;
        }
    }

    function handleGoogleLoginEvent(gToken) {
        console.log("handleGoogleLoginEvent");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "google_token": gToken,
            })
        };
        fetch("/api/user/login", requestOptions)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((res) => res.json())
            .then((data) => {
                props.onLoginFormSuccessful(data);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setFormError(true);
                setFormErrorText("Error! Please try again.");
            });
    }

    /// 
    /// REGISTER
    /// 
    function handleRegisterEvent(values) {
        console.log("handleRegisterEvent");
        let dob = new Date(values.dobyyyy, values.dobmm - 1, values.dobdd);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: values.username,
                full_name: values.fullname,
                email: values.email,
                password: values.password,
                date_of_birth: dob.toISOString(),
            })
        };
        fetch("/api/user/register", requestOptions)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((res) => res.json())
            .then((data) => {
                props.onRegisterFormSuccessful("postRegisterByEmail", data);
            })
            .catch((error) => {
                console.log('There was an error!');
                console.log(error);
                // let errorMsg = error;
                // console.log(errorMsg);
                // console.log(errorMsg.toLowerCase());
                // if ((errorMsg ?? "").toLowerCase().includes("unable to register at this time")) {
                //     errorMsg = "Unable to register at this time.";
                // } else {
                //     errorMsg = "Error! Please try again.";
                // }
                setFormError(true);
                setFormErrorText("Error! Please try again.");
            });
    }

    function registerFacebookUser(fbToken) {
        window.FB.api('/me?fields=email,first_name,last_name,name', function (meResp) {
            console.log(meResp);
            let registerRequest = {
                facebook_id: meResp.id,
                first_name: meResp.first_name,
                last_name: meResp.last_name,
                email: meResp.email,
                full_name: meResp.name,
                facebook_access_token: fbToken.accessToken
            }

            window.FB.api('/me/picture?type=large&redirect=false', function (profilePictureResp) {
                console.log(profilePictureResp);
                const profilePictureUrl = profilePictureResp?.data?.url;
                if (profilePictureUrl) {
                    registerRequest.profilePhoto = "fb_" + Math.round(new Date().getTime() / 1000);
                }
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(registerRequest)
                };
                fetch("/api/user/register", requestOptions)
                    .then(function (response) {
                        if (!response.ok) {
                            throw Error(response.statusText);
                        }
                        return response;
                    })
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.isNewUser === false) {
                            props.onLoginFormSuccessful(data);
                        } else {
                            // should check resposne here.
                            if (profilePictureUrl) {
                                console.log(profilePictureUrl);
                                // download photo
                                fetch(profilePictureUrl, {
                                    method: 'GET',
                                })
                                    .then(function (response) {
                                        if (!response.ok) {
                                            throw Error(response.statusText);
                                        }
                                        return response;
                                    })
                                    .then((res) => res.blob())
                                    .then((blob) => {
                                        // save photo to aws
                                        let formData = new FormData();
                                        formData.append('folder', "profile");
                                        formData.append('file', blob);
                                        formData.append('filename', registerRequest.profilePhoto);
                                        fetch("/api/file", {
                                            method: 'POST',
                                            headers: {
                                                'Authorization': 'Bearer ' + data.accessToken,
                                            },
                                            body: formData
                                        })
                                            .then(function (response) {
                                                if (!response.ok) {
                                                    throw Error(response.statusText);
                                                }
                                                return response;
                                            })
                                            .then((resp) => {
                                                console.log("success");
                                                props.onRegisterFormSuccessful("postRegisterByFacebook", data);
                                            })
                                            .catch(error => {
                                                console.error('There was an error!', error);
                                                // TODO: remove the profile photo from DB.
                                            });
                                    })
                                    .catch(error => {
                                        console.log('There was an error!', error);
                                    });
                            } else {
                                props.onRegisterFormSuccessful("postRegisterByFacebook", data);
                            }
                        }
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                        // let errorMsg = error;
                        // console.log(errorMsg);
                        // console.log(errorMsg.toLowerCase());
                        // if ((errorMsg ?? "").toLowerCase().includes("unable to register at this time")) {
                        //     errorMsg = "Unable to register at this time.";
                        // } else {
                        //     errorMsg = "Error! Please try again.";
                        // }
                        setFormError(true);
                        setFormErrorText("Error! Please try again.");
                    });
            });
        });
    }

    function handleGoogleRegisterEvent(gToken) {
        console.log("handleGoogleRegisterEvent");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "google_token": gToken,
            })
        };
        fetch("/api/user/register", requestOptions)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((res) => res.json())
            .then((data) => {
                props.onRegisterFormSuccessful("postRegisterByFacebook", data);
            })
            .catch((error) => {
                console.log('There was an error!');
                console.log(error);
                setFormError(true);
                setFormErrorText("Error! Please try again.");
            });
    }

    function onClickSignUp() {
        // animate signup/login form.
        console.log("onClickSignUp");
        setFormClass("unregistered-div-show");
    }

    function onClickCloseSignUp() {
        // animate hide signup/login form.
        setFormClass("unregistered-div-hidden");
    }

    function onSwitchTab(t) {
        // clear error
        setFormError(false);
        setFormErrorText("");

        // set tab
        setTabKey(t);
    }

    function googleMapSetRestriction(_map) {
        let screenWitdh = window.innerWidth;
        if (screenWitdh <= 500) {
            // mobile
            _map.setOptions({
                restriction: {
                    latLngBounds: {
                        // north: 22.661043290053666,
                        // south: 20.96514139019381,
                        // west: -158.366574319011,
                        // east: -157.54196884883407,
                        north: 24,
                        south: 19,
                        west: -159,
                        east: -157,
                    },
                    strictBounds: false,
                },
                zoom: 9.5
            });
        } else {
            // desktop
            _map.setOptions({
                restriction: {
                    latLngBounds: {
                        // north: 21.9,
                        // south: 21.1,
                        // west: -159,
                        // east: -157.5,
                        north: 24,
                        south: 19,
                        west: -159,
                        east: -157,
                    },
                    strictBounds: false,
                },
                zoom: 10,
            });
        }

    }

    function renderDirectionResult(directionsRenderer, response) {
        var overview_path = response.routes[0].overview_path;
        new maps.Polyline({
            path: overview_path,
            geodesic: true,
            strokeColor: '#ff8a14', // FF5656, ff8a14
            strokeWeight: 3,
            strokeOpacity: 0.5,
            map: map
        });
        map.setTilt(45);

        let points = overview_path.map(p => NormalizePoint(p));
        let result = DouglasPeucker(points, 0, overview_path.length - 1, 0.027);

        new maps.Marker({
            position: { lat: result[0].lat, lng: result[0].lng },
            map: map,
            icon: {
                path: maps.SymbolPath.CIRCLE,
                scale: 4,
                strokeColor: "#9d26ff"
            }
        });
        new maps.Marker({
            position: { lat: result[result.length - 1].lat, lng: result[result.length - 1].lng },
            map: map,
            icon: {
                path: maps.SymbolPath.CIRCLE,
                scale: 4,
                strokeColor: "#9d26ff"
            }
        });

        // console.log("starting animation in 3s");
        const timer = setTimeout(() => {
            console.log('This will run after 3 second!');
            googleMapJs.current.animate(
                result,
                20000,
                false);
        }, 3000);
    }

    function handleMarkerClick(_clickedMarker) {
        setClickedMarker(_clickedMarker);
        setDisplayImage(true);
    }

    function onImageViewerClose(e) {
        setDisplayImage(false);
    }

    return (
        <>
            <a href="/" className="unregistered-navbar-brand-on-map">Route</a>

            <div className="landing-all">
                <div className="landing-left">
                    <h1>Travel The World</h1>
                    <h1>With Friends</h1>
                    <p>Start sharing and exploring today!</p>
                    <button className="landing-left-signup-button" onClick={onClickSignUp}>Login</button>
                </div>

                <div className={formClass}>
                    <div className="registration-login-inner-div">
                        <div className="registration-login-close">
                            <CloseButton onClick={onClickCloseSignUp} />
                        </div>
                        <div className="registration-login rounded shadow-sm" >
                            <Tabs activeKey={tabKey} onSelect={onSwitchTab} id="registration-login-tabs" className="mb-3">
                                <Tab eventKey="login" title="Login">
                                    <LoginForm handleLoginEvent={handleLoginEvent}></LoginForm>
                                    {formError &&
                                        <div className="login-register-error-message">{formErrorText}</div>
                                    }

                                    {/* <div id="buttonDiv"></div> */}
                                    {/* <div id="g_id_onload"
                                        data-client_id={`${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`}
                                        data-login_uri={`${process.env.REACT_APP_SERVER_URL}/signin-google`}
                                        data-auto_prompt="false">
                                    </div>
                                    <div class="g_id_signin"
                                        data-type="standard"
                                        data-size="large"
                                        data-theme="outline"
                                        data-text="sign_in_with"
                                        data-shape="rectangular"
                                        data-logo_alignment="left">
                                    </div> */}
                                </Tab>
                                <Tab eventKey="register" title="Register">
                                    <RegisterForm handleRegisterEvent={handleRegisterEvent}></RegisterForm>
                                    {formError &&
                                        <div className="login-register-error-message">{formErrorText}</div>
                                    }
                                </Tab>
                            </Tabs>

                            <div className="social-network-login">
                                <img className="social-network-login-icon" src={fbLogo} alt="Facebook Logo" onClick={handleFacebookAuthEvent} />
                                <img id="sign-in-button-test" className="social-network-login-icon" src={googleLogo} alt="Google Logo" onClick={function () { }} />
                                {/* <div id="buttonDiv"></div>
                                <div id="sign-in-with-google"></div>
                                <button id="sign-in-button-test">Login With Google</button> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div id="landing-googlemap" className='landing-googlemap'>
                    {map && maps && <GoogleMapComponent
                        ref={el => { googleMapJs.current = el; }}
                        map={map}
                        maps={maps}
                        directionsService={directionsService}
                        renderDirectionResult={renderDirectionResult}
                    />}
                </div>
                {markers.map((m, index) => {
                    return <MarkerThumbnailComponent
                        key={`${m.location.lat}:${m.location.lng}:${m.file.lastModified}`}
                        marker={m}
                        map={map}
                        maps={maps}
                        handleMarkerClick={handleMarkerClick}
                        draftMode={false}
                        handleDraftMarkerDelete={() => { }}
                    />;
                })}

                <div className={displayImage ? "unregistered-imageViewer-show" : "left-imageViewer-hide"}>
                    <ImageViewer
                        clickedMarker={clickedMarker}
                        displayImage={displayImage}
                        onCloseHandler={onImageViewerClose}
                    />
                </div>
            </div>

            <div className="unregistered-footer">
                <div className="unregistered-footer-link"><a href="privacy">Privacy</a></div>
                <div className="unregistered-footer-link"><a href="tos">Terms</a></div>
                <div className="unregistered-footer-trademark">ZheRoute © 2022</div>
            </div>
        </>
    )
}
export default UnregisteredComponent;