import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './test.css';
import Cookies from 'js-cookie';
import { Loader } from '@googlemaps/js-api-loader';
import GoogleMapComponent from '../GoogleMapComponent.js'
import ImageViewer from '../ImageViewerComponent'
import { GOOGLE_MAP_PLACE_TYPES_1,GOOGLE_MAP_PLACE_TYPES_2, FilterPlaceResults } from '../helper/calculation.js'
import { getGpsData } from '../helper/image-get-exif';

// API loading parameters
const loader = new Loader({
    apiKey: "AIzaSyDCHOO41I21tKSMCzpoXI0Aizz39epDs2g",
    version: "beta",
    mapIds: ["1a9316535142ffae"],
    libraries: ["geometry", "places"]
});
const defaultMapOptions = {
    center: {
        lat: 50,
        lng: 0,
    },
    zoom: 7,
    tilt: 45,
    mapId: "1a9316535142ffae",
    disableDefaultUI: true,
};

const TestComponent = (props) => {
    const [fileName, setFileName] = useState("N/A");
    const [lat, setLat] = useState("N/A");
    const [lon, setLon] = useState("N/A");
    const [gpsData, setGpsData] = useState();
    useEffect(() => {

        // loader.load().then((google) => {
        //     const map = new google.maps.Map(
        //         document.getElementById("map"),
        //         defaultMapOptions
        //     );

        //     let placesService = new google.maps.places.PlacesService(map);
            
        //     map.addListener("click", (event) => {
        //         console.log(GOOGLE_MAP_PLACE_TYPES_1);
        //         console.log(GOOGLE_MAP_PLACE_TYPES_2);
        //         let latlng = event.latLng;
        //         let nearByServiceReq = {
        //             location: latlng,
        //             radius: '100',
        //         };
        //         placesService.nearbySearch(nearByServiceReq, function (results, status) {
        //             console.log(results);
        //             let filtered = FilterPlaceResults(results);
        //             filtered.forEach((f) => {console.log(f.name + f.types.join(',') );});
                    

        //             // this doenst work. dont do this.
        //             // if (filtered[0].types[0] == "point_of_interest" && filtered[0].types[1] == "establishment") {
        //             //     // this means there is nothing meaningful nearby.
        //             //     // so should search by distance.
        //             //     let nearByServiceReq2 = {
        //             //         location: latlng,
        //             //         rankBy: google.maps.places.RankBy.DISTANCE,
        //             //         type: "point_of_interest"
        //             //     };
        //             //     placesService.nearbySearch(nearByServiceReq2, function (r2, s2) {
        //             //         console.log(r2);
        //             //         let filtered2 = FilterPlaceResults(r2);
        //             //         filtered2.forEach((f) => {console.log(f.name + f.types.join(',') );});
                            
        //             //         // console.log(FilterPlaceResults(results));
        //             //         if (s2 == 'OK') {
        //             //         } else {
        //             //         }
        //             //     });
        //             // }
        //             // console.log(FilterPlaceResults(results));
        //             if (status == 'OK') {
        //             } else {
        //             }
        //         });
        //     });
            
        // });
    }, []);

    const TiffTags = {
        0x0100: "ImageWidth",
        0x0101: "ImageHeight",
        0x8769: "ExifIFDPointer",
        0x8825: "GPSInfoIFDPointer",
        0xA005: "InteroperabilityIFDPointer",
        0x0102: "BitsPerSample",
        0x0103: "Compression",
        0x0106: "PhotometricInterpretation",
        0x0112: "Orientation",
        0x0115: "SamplesPerPixel",
        0x011C: "PlanarConfiguration",
        0x0212: "YCbCrSubSampling",
        0x0213: "YCbCrPositioning",
        0x011A: "XResolution",
        0x011B: "YResolution",
        0x0128: "ResolutionUnit",
        0x0111: "StripOffsets",
        0x0116: "RowsPerStrip",
        0x0117: "StripByteCounts",
        0x0201: "JPEGInterchangeFormat",
        0x0202: "JPEGInterchangeFormatLength",
        0x012D: "TransferFunction",
        0x013E: "WhitePoint",
        0x013F: "PrimaryChromaticities",
        0x0211: "YCbCrCoefficients",
        0x0214: "ReferenceBlackWhite",
        0x0132: "DateTime",
        0x010E: "ImageDescription",
        0x010F: "Make",
        0x0110: "Model",
        0x0131: "Software",
        0x013B: "Artist",
        0x8298: "Copyright"
    };


    const GPSTags = {
        0x0000: "GPSVersionID",
        0x0001: "GPSLatitudeRef",
        0x0002: "GPSLatitude",
        0x0003: "GPSLongitudeRef",
        0x0004: "GPSLongitude",
        0x0005: "GPSAltitudeRef",
        0x0006: "GPSAltitude",
        0x0007: "GPSTimeStamp",
        0x0008: "GPSSatellites",
        0x0009: "GPSStatus",
        0x000A: "GPSMeasureMode",
        0x000B: "GPSDOP",
        0x000C: "GPSSpeedRef",
        0x000D: "GPSSpeed",
        0x000E: "GPSTrackRef",
        0x000F: "GPSTrack",
        0x0010: "GPSImgDirectionRef",
        0x0011: "GPSImgDirection",
        0x0012: "GPSMapDatum",
        0x0013: "GPSDestLatitudeRef",
        0x0014: "GPSDestLatitude",
        0x0015: "GPSDestLongitudeRef",
        0x0016: "GPSDestLongitude",
        0x0017: "GPSDestBearingRef",
        0x0018: "GPSDestBearing",
        0x0019: "GPSDestDistanceRef",
        0x001A: "GPSDestDistance",
        0x001B: "GPSProcessingMethod",
        0x001C: "GPSAreaInformation",
        0x001D: "GPSDateStamp",
        0x001E: "GPSDifferential"
    };
    
    const handleChange = (e) => {
        console.log("handleChange called");
        var files = e.target.files;
        var file = files[0];
        console.log(file);
        setFileName(file.name);
        getGpsData(file, setGpsData);
    }

    useEffect(() => {
        console.log("useEffect[gpsData]");
        if (gpsData) {
            console.log("gpsdata is available now.");
            setLat(formatLatLonToString(gpsData["GPSLatitude"], gpsData["GPSLatitudeRef"]));
            setLon(formatLatLonToString(gpsData["GPSLongitude"], gpsData["GPSLongitudeRef"]));
        }
    }, [gpsData]);

    const getStringFromDB = (buffer, start, length) => {
        var outstr = "";
        for (var n = start; n < start + length; n++)
        {
            outstr += String.fromCharCode(buffer.getUint8(n));
        }
        return outstr;
    }

    const readEXIFData = (file, start) => {
        var bigEnd,
            tags, tag,
            exifData, gpsData;

        // test for TIFF validity and endianness
        if (file.getUint16(start) == 0x4949)
        {
            bigEnd = false;
        } else if (file.getUint16(start) == 0x4D4D)
        {
            bigEnd = true;
        } else
        {
            console.log("Not valid TIFF data! (no 0x4949 or 0x4D4D)");
            return false;
        }

        if (file.getUint16(start + 2, !bigEnd) != 0x002A)
        {
            console.log("Not valid TIFF data! (no 0x002A)");
            return false;
        }

        var firstIFDOffset = file.getUint32(start + 4, !bigEnd);

        if (firstIFDOffset < 0x00000008)
        {
            console.log("Not valid TIFF data! (First offset less than 8) firstIFDOffset < 0x00000008");
            return false;
        }

        tags = readTags(file, start, start + firstIFDOffset, TiffTags, bigEnd);

        if (tags.GPSInfoIFDPointer)
        {
            console.log("tags.GPSInfoIFDPointer true");
            gpsData = readTags(file, start, start + tags.GPSInfoIFDPointer, GPSTags, bigEnd);
            console.log(gpsData);
            setLat(formatLatLonToString(gpsData["GPSLatitude"], gpsData["GPSLatitudeRef"]));
            setLon(formatLatLonToString(gpsData["GPSLongitude"], gpsData["GPSLongitudeRef"]));
        }
        return tags;
    }

    const formatLatLonToString = (_v, dir) =>
    {
        let a = _v[0];
        let b = _v[1];
        let c = _v[2];
        return ConvertDMSToDD(a, b, c, dir);
    }

    const ConvertDMSToDD = (degrees, minutes, seconds, direction) => {
        var dd = degrees + minutes/60 + seconds/(60*60);
    
        if (direction == "S" || direction == "W") {
            dd = dd * -1;
        } // Don't do anything for N or E
        return dd;
    }

    const readTags = (file, tiffStart, dirStart, strings, bigEnd) => {
        console.log("=== readTags ===");
        console.log(tiffStart);
        console.log(dirStart);
        console.log(strings);
        console.log(bigEnd);
        var entries = file.getUint16(dirStart, !bigEnd),
            tags = {},
            entryOffset, tag,
            i;

        for (i = 0; i < entries; i++)
        {
            entryOffset = dirStart + i * 12 + 2;
            tag = strings[file.getUint16(entryOffset, !bigEnd)];
            if (!tag) console.log("Unknown tag: " + file.getUint16(entryOffset, !bigEnd));
            tags[tag] = readTagValue(file, entryOffset, tiffStart, dirStart, bigEnd);
        }
        return tags;
    }


    const readTagValue = (file, entryOffset, tiffStart, dirStart, bigEnd) =>
    {
        var type = file.getUint16(entryOffset + 2, !bigEnd),
            numValues = file.getUint32(entryOffset + 4, !bigEnd),
            valueOffset = file.getUint32(entryOffset + 8, !bigEnd) + tiffStart,
            offset,
            vals, val, n,
            numerator, denominator;

        switch (type)
        {
            case 1: // byte, 8-bit unsigned int
            case 7: // undefined, 8-bit byte, value depending on field
                if (numValues == 1)
                {
                    return file.getUint8(entryOffset + 8, !bigEnd);
                } else
                {
                    offset = numValues > 4 ? valueOffset : (entryOffset + 8);
                    vals = [];
                    for (n = 0; n < numValues; n++)
                    {
                        vals[n] = file.getUint8(offset + n);
                    }
                    return vals;
                }

            case 2: // ascii, 8-bit byte
                offset = numValues > 4 ? valueOffset : (entryOffset + 8);
                return getStringFromDB(file, offset, numValues - 1);

            case 3: // short, 16 bit int
                if (numValues == 1)
                {
                    return file.getUint16(entryOffset + 8, !bigEnd);
                } else
                {
                    offset = numValues > 2 ? valueOffset : (entryOffset + 8);
                    vals = [];
                    for (n = 0; n < numValues; n++)
                    {
                        vals[n] = file.getUint16(offset + 2 * n, !bigEnd);
                    }
                    return vals;
                }

            case 4: // long, 32 bit int
                if (numValues == 1)
                {
                    return file.getUint32(entryOffset + 8, !bigEnd);
                } else
                {
                    vals = [];
                    for (n = 0; n < numValues; n++)
                    {
                        vals[n] = file.getUint32(valueOffset + 4 * n, !bigEnd);
                    }
                    return vals;
                }

            case 5:    // rational = two long values, first is numerator, second is denominator
                if (numValues == 1)
                {
                    numerator = file.getUint32(valueOffset, !bigEnd);
                    denominator = file.getUint32(valueOffset + 4, !bigEnd);
                    val = new Number(numerator / denominator);
                    val.numerator = numerator;
                    val.denominator = denominator;
                    return val;
                } else
                {
                    vals = [];
                    for (n = 0; n < numValues; n++)
                    {
                        numerator = file.getUint32(valueOffset + 8 * n, !bigEnd);
                        denominator = file.getUint32(valueOffset + 4 + 8 * n, !bigEnd);
                        vals[n] = new Number(numerator / denominator);
                        vals[n].numerator = numerator;
                        vals[n].denominator = denominator;
                    }
                    return vals;
                }

            case 9: // slong, 32 bit signed int
                if (numValues == 1)
                {
                    return file.getInt32(entryOffset + 8, !bigEnd);
                } else
                {
                    vals = [];
                    for (n = 0; n < numValues; n++)
                    {
                        vals[n] = file.getInt32(valueOffset + 4 * n, !bigEnd);
                    }
                    return vals;
                }

            case 10: // signed rational, two slongs, first is numerator, second is denominator
                if (numValues == 1)
                {
                    return file.getInt32(valueOffset, !bigEnd) / file.getInt32(valueOffset + 4, !bigEnd);
                } else
                {
                    vals = [];
                    for (n = 0; n < numValues; n++)
                    {
                        vals[n] = file.getInt32(valueOffset + 8 * n, !bigEnd) / file.getInt32(valueOffset + 4 + 8 * n, !bigEnd);
                    }
                    return vals;
                }
        }
    }


    return (
        <div>
            <div className="test-body">
                <input
                    type="file"
                    accept=".jpg, .png, .heif, .heic"
                    onChange={handleChange}
            />
            </div>
            <p>Filename: {fileName}</p>
            <p>Lat: {lat}</p>
            <p>Lon: {lon}</p>
            <h4>{lat},{lon}</h4>
        </div>
    )
};
export default TestComponent;
