import React, { useEffect, useState } from 'react';
import axios from 'axios';

/*
    profilePhoto: blob
    userId: if need download, then send this parameter instead of profilePhoto.
    className: must provide a class with height and width. Use class so that we can use media query from parent
 */
const ProfilePhotoComponent = (props) => {
    const [ready, setReady] = useState(false);
    const [profilePhotoSrc, setProfilePhotoSrc] = useState(null);

    useEffect(() => {
        if (props.profilePhoto) {
            console.log("props.profilePhoto provided");
            setProfilePhotoSrc(window.URL.createObjectURL(props.profilePhoto));
        } else if (!props.profilePhoto && props.userId) {
            console.log("props.userId provided");
            axios.get(
                `/api/user/${props.userId}/profilephoto`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                    },
                    responseType: 'blob'
                }
            )
            .then(res => {
                setProfilePhotoSrc(window.URL.createObjectURL(res.data));
            })
            .catch(error => {
                console.log('There was an error while fetching profile photo!', error);
            });
        } else {
            console.log("nothing provided, show default photo");
            setReady(true);
        }
    }, []);

    useEffect(() => {
        setReady(true); // set true first, to make sure the image placeholder is loaded first.
        if (props.profilePhoto) {
            setProfilePhotoSrc(window.URL.createObjectURL(props.profilePhoto));
        }
    }, [props.profilePhoto]);

    return (
        <>
            {ready &&
                (
                    profilePhotoSrc
                    ?
                    <div
                        className={props.className}
                        style={{
                            "background": "url(" + profilePhotoSrc + ") center / cover"
                        }}
                    >
                    </div>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={props.className} viewBox="0 0 16 16">
                        <path d="M4.968 9.75a.5.5 0 1 0-.866.5A4.498 4.498 0 0 0 8 12.5a4.5 4.5 0 0 0 3.898-2.25.5.5 0 1 0-.866-.5A3.498 3.498 0 0 1 8 11.5a3.498 3.498 0 0 1-3.032-1.75zM7 5.116V5a1 1 0 0 0-1-1H3.28a1 1 0 0 0-.97 1.243l.311 1.242A2 2 0 0 0 4.561 8H5a2 2 0 0 0 1.994-1.839A2.99 2.99 0 0 1 8 6c.393 0 .74.064 1.006.161A2 2 0 0 0 11 8h.438a2 2 0 0 0 1.94-1.515l.311-1.242A1 1 0 0 0 12.72 4H10a1 1 0 0 0-1 1v.116A4.22 4.22 0 0 0 8 5c-.35 0-.69.04-1 .116z" />
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-1 0A7 7 0 1 0 1 8a7 7 0 0 0 14 0z" />
                    </svg>
                )
            }
        </>
    )
}
export default ProfilePhotoComponent;