import { forwardRef, useImperativeHandle } from 'react';
import { Tween, update, Easing } from "@tweenjs/tween.js";

/*
    handles all interactions inside map
    route()
    animate()
*/
const GoogleMapComponent = (props, ref) => {
    useImperativeHandle(ref, () => ({
        route(origin, destination, waypoints) {
            route(origin, destination, waypoints)
        },

        animate(rdpPoints, totalDuration, showCurrMarker) {
            animate(rdpPoints, totalDuration, showCurrMarker)
        }
    }), [])

    const route = (origin, destination, waypoints) => {
        var rendererOptions = {
            map: props.map,
            suppressMarkers: true
        }
        let directionsRenderer = new props.maps.DirectionsRenderer(rendererOptions);
        // directionsRenderer.setMap(this.state.map);
        var routeParams = {
            origin: origin,
            destination: destination,
            travelMode: "DRIVING"
        };
        if (waypoints.length > 0) {
            routeParams["waypoints"] = waypoints;
        }
        props.directionsService.route(routeParams, function (response, status) {
            if (status === 'OK') {
                console.log(response);
                // rendering customize here.
                props.renderDirectionResult(directionsRenderer, response);
            } else {
                console.log('Directions request failed due to ' + status);
            }
        });
    }

    const animate = (rdpPoints, totalDuration = 15000, showCurrMarker = true) => {
        if (rdpPoints && rdpPoints.length > 1) {
            animatePath(rdpPoints, 0, rdpPoints.length - 1, totalDuration, showCurrMarker);
        }
    }

    const animatePath = (overview_path, pathStart, pathEnd, totalDuration, showCurrMarker) => {
        console.log(overview_path);
        console.log(pathStart + " " + pathEnd + " " + totalDuration);

        let self = this;
        let totalLength = 0;
        let animationPointsAndDuration = [];
        let currPosMarker;
        if (showCurrMarker === true) {
            currPosMarker = new props.maps.Marker({
                position: { lat: 0, lng: 0 },
                map: props.map,
                icon: {
                    path: props.maps.SymbolPath.CIRCLE,
                    scale: 8,
                    strokeColor: "#393"
                }
            });
        }
        let originalCenter = props.map.getCenter();
        let currZoom = props.map.getZoom();
        let currTilt = props.map.getTilt();
        let currHeading = props.map.getHeading();

        // for each path, calculate start and end data.
        // if there are 10 pints, then pathStart will be 0, pathEnd will be 9
        for (let i = pathStart; i < pathEnd; i++) {
            let pathAtI = overview_path[i];
            let pathAtINext = overview_path[i + 1];
            let pathLengthSquared = 10000000 * Math.sqrt(Math.pow(pathAtI.lat - pathAtINext.lat, 2) + Math.pow(pathAtI.lng - pathAtINext.lng, 2));
            totalLength += pathLengthSquared;

            let currAnimationStart = {
                lat: pathAtI.lat,
                lng: pathAtI.lng
            };
            let currAnimationEnd = {
                lat: pathAtINext.lat,
                lng: pathAtINext.lng,
            };
            // heading need to be set as the END point's heading, because we are moving from current camera position to the END camera position.
            let headingAtNext = props.maps.geometry.spherical.computeHeading(
                new props.maps.LatLng(pathAtI.lat, pathAtI.lng),
                new props.maps.LatLng(pathAtINext.lat, pathAtINext.lng)
            );
            let currAnimationTilt = 60;
            let nextAnimationTilt = 60;
            let currAnimationZoom = Math.min(18, Math.ceil(currZoom + 1));
            let nextAnimationZoom = Math.min(18, Math.ceil(currZoom + 1));
            let currAnimationHeading = headingAtNext;
            let nextAnimationHeading = headingAtNext;
            if (i === pathStart) {
                currAnimationTilt = currTilt;
                currAnimationZoom = currZoom;
                currAnimationHeading = currHeading;
            // } else if (i === pathEnd - 1) {
            //     nextAnimationTilt = currTilt;
            //     nextAnimationZoom = currZoom;
            //     nextAnimationHeading = currHeading;
            //     // if this is not starting point, then start heading is the previous location's end heading
            //     currAnimationHeading = animationPointsAndDuration[i - 1].end.heading;
            } else {
                // if this is not starting point, then start heading is the previous location's end heading
                currAnimationHeading = animationPointsAndDuration[i - 1].end.heading;
            }

            // heading correction, so we dont move camera heading by 180 degree
            if (Math.abs(currAnimationHeading - nextAnimationHeading) > 180) {
                if (currAnimationHeading > 0) {
                    currAnimationHeading -= 360;
                } else if (currAnimationHeading < 0) {
                    currAnimationHeading += 360;
                }
            }
            animationPointsAndDuration.push({
                start: {
                    location: currAnimationStart,
                    lat: pathAtI.lat,
                    lng: pathAtI.lng,
                    tilt: currAnimationTilt,
                    zoom: currAnimationZoom,
                    heading: currAnimationHeading
                },
                end: {
                    location: currAnimationEnd,
                    lat: pathAtINext.lat,
                    lng: pathAtINext.lng,
                    tilt: nextAnimationTilt,
                    zoom: nextAnimationZoom,
                    heading: nextAnimationHeading
                },
                length: pathLengthSquared
            });
        }
        let time = totalDuration / totalLength;
        for (let i = pathStart; i < pathEnd; i++) {
            animationPointsAndDuration[i]["time"] = time * animationPointsAndDuration[i]["length"];
        }

        console.log(animationPointsAndDuration);

        /*
            starting animation:
            from: center, heading = 0
            to: point[0], heading = angle of point[0] -> point[1]
        */
        let startingCameraPosition = {
            tilt: props.map.getTilt(),
            heading: props.map.getHeading() ?? 0,
            zoom: props.map.getZoom(),
            center: {
                lat: props.map.getCenter().lat(),
                lng: props.map.getCenter().lng(),
            },
        };
        let index1CameraPositoin = {
            tilt: props.map.getTilt(),
            heading: props.map.getHeading() ?? 0,
            zoom: props.map.getZoom(),
            center: {
                lat: animationPointsAndDuration[0].start.lat,
                lng: animationPointsAndDuration[0].start.lng,
            }
        };

        let startingTween = new Tween(startingCameraPosition) // Create a new tween that modifies 'cameraOptions'.
            .to(index1CameraPositoin, 2000) // Move to destination in 15 second.
            .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
            .onUpdate(() => {
                props.map.moveCamera(startingCameraPosition);
            })
            .onComplete(() => {
            });

        // build route animation
        // if (overview_path.length > 2) {
        // }
        // create tweens
        let tweens = [];
        for (let i = 0; i < animationPointsAndDuration.length; i = i + 1) {
            let startPoint = animationPointsAndDuration[i].start;
            let endPoint = animationPointsAndDuration[i].end;
            let startCameraPosition = {
                tilt: startPoint.tilt,
                heading: startPoint.heading,
                zoom: startPoint.zoom,
                center: {
                    lat: startPoint.lat,
                    lng: startPoint.lng,
                }
            };
            let endCameraPosition = {
                tilt: endPoint.tilt,
                heading: endPoint.heading,
                zoom: endPoint.zoom,
                center: {
                    lat: endPoint.lat,
                    lng: endPoint.lng,
                }
            };

            let tweenCurr = new Tween(startCameraPosition)
                .to(endCameraPosition, animationPointsAndDuration[i].time) // Move to destination in 15 second.
                .easing(Easing.Linear.None) // Use an easing function to make the animation smooth.
                .onUpdate((currCameraPos) => {
                    props.map.moveCamera(startCameraPosition);
                    if (showCurrMarker === true) {
                        currPosMarker.setPosition(currCameraPos.center);
                    }
                })
                .onComplete((z) => {
                });
            tweens.push(tweenCurr);


            // if (i == 1) {
            //     console.log(i);
            //     console.log(animationPointsAndDuration[i].start.lat + " " + animationPointsAndDuration[i].start.lng);
            //     console.log(animationPointsAndDuration[i+10].end.lat + " " + animationPointsAndDuration[i+10].end.lng);
            //     var cameraB = {
            //         tilt: this.state.map.getTilt(),
            //         heading: this.state.map.getHeading() ?? 0,
            //         zoom: this.state.map.getZoom(),
            //         center: {
            //             lat: animationPointsAndDuration[i].start.lat,
            //             lng: animationPointsAndDuration[i].start.lng,
            //         }
            //     };
            //     tweenB = new Tween(cameraB)
            //         .to({
            //             tilt: this.state.map.getTilt(),
            //             heading: this.state.map.getHeading() ?? 0,
            //             zoom: this.state.map.getZoom(),
            //             center: {
            //                 lat: animationPointsAndDuration[i+10].end.lat,
            //                 lng: animationPointsAndDuration[i+10].end.lng,
            //             }
            //         }, 2000) // Move to destination in 15 second.
            //         .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
            //         .onUpdate(() => {
            //             self.state.map.moveCamera(cameraB);
            //         })
            //     ;
            //     tweens.push(tweenB);
            // }

            // if (i == 11){
            //     console.log(i+1);
            //     console.log(animationPointsAndDuration[i+1].start.lat + " " + animationPointsAndDuration[i+1].start.lng);
            //     console.log(animationPointsAndDuration[100].end.lat + " " + animationPointsAndDuration[100].end.lng);
            //     var cameraC = {
            //         tilt: this.state.map.getTilt(),
            //         heading: this.state.map.getHeading() ?? 0,
            //         zoom: this.state.map.getZoom(),
            //         center: {
            //             lat: animationPointsAndDuration[i+1].start.lat,
            //             lng: animationPointsAndDuration[i+1].start.lng,
            //         }
            //     };
            //     tweenC = new Tween(cameraC)
            //         .to({
            //             tilt: this.state.map.getTilt(),
            //             heading: this.state.map.getHeading() ?? 0,
            //             zoom: this.state.map.getZoom(),
            //             center: {
            //                 lat: animationPointsAndDuration[100].end.lat,
            //                 lng: animationPointsAndDuration[100].end.lng,
            //             }
            //         }, 2000) // Move to destination in 15 second.
            //         .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
            //         .onUpdate(() => {
            //             self.state.map.moveCamera(cameraC);
            //         })
            //     ;
            //     tweens.push(tweenC);
            // }
        }

        /*
            ending animation:
            from: point[n-2], heading = angle of point[n-2] -> point[n-1]
            to: original center, zoom, heading = 0
        */
        let endingCameraPosition = {
            tilt: currTilt,
            heading: currHeading ?? 0,
            zoom: currZoom,
            center: {
                lat: originalCenter.lat(),
                lng: originalCenter.lng(),
            },
        };
        let indexLastCameraPositoin = {
            tilt: animationPointsAndDuration[animationPointsAndDuration.length-1].end.tilt,
            heading: animationPointsAndDuration[animationPointsAndDuration.length-1].end.heading ?? 0,
            zoom: animationPointsAndDuration[animationPointsAndDuration.length-1].end.zoom,
            center: {
                lat: animationPointsAndDuration[animationPointsAndDuration.length-1].end.lat,
                lng: animationPointsAndDuration[animationPointsAndDuration.length-1].end.lng,
            }
        };
        let endingTween = new Tween(indexLastCameraPositoin) // Create a new tween that modifies 'cameraOptions'.
            .to(endingCameraPosition, 2000) // Move to destination in 15 second.
            .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
            .onUpdate(() => {
                props.map.moveCamera(indexLastCameraPositoin);
            })
            .onComplete(() => {
            });

        // chain all the tweens together: start -> tween[0] -> ... tween[n-1] -> end
        if (overview_path.length <= 1) {
            startingTween.chain(endingTween);
        } else {
            startingTween.chain(tweens[0]);
            for (let i = 0; i < tweens.length; i++) {
                if (i + 1 < tweens.length) {
                    // chain the next tween
                    tweens[i].chain(tweens[i + 1]);
                } else {
                    // chain the ending tween
                    tweens[i].chain(endingTween);
                }
            }
        }
        startingTween.start();
        requestAnimationFrame(function animate(timestamp) {
            requestAnimationFrame(animate);
            update(timestamp);
        });
    }

    return (null);
};
export default forwardRef(GoogleMapComponent);
