import React, { Component } from 'react';
import axios from 'axios';
import ImageViewer from './ImageViewerComponent';
import './Home.css';
import HeaderComponent from './common/HeaderComponent';
import { Button, Col } from 'react-bootstrap'
import { Loader } from '@googlemaps/js-api-loader';
import { NearestPointOnPath, DouglasPeucker, NormalizePoint, FilterPlaceResults } from './helper/calculation.js'
import { Tween, update, Easing } from "@tweenjs/tween.js";
import SideFriendListComponent from './SideFriendComponent';
import SideCreateComponent from './SideCreateComponent';
import MarkerThumbnailComponent from './MarkerThumbnailComponent'
import GoogleMapComponent from './GoogleMapComponent'
import ProfileSetupComponent from './forms/ProfileSetupComponent';
import SearchFriendComponent from './forms/SearchFriendComponent';
import DraftComponent from './forms/DraftComponent';
import ImageUploadComponent from './ImageUploadComponent';
import ProfilePhotoComponent from './common/ProfilePhotoComponent';

let isRefreshingToken = false;
axios.interceptors.response.use(
    function (successRes) {
        return successRes;
    },
    async function (error) {
        const status = error.response ? error.response.status : null;
        if (status === 401 || status === 403) {
            const originalRequest = error.config;
            console.log(originalRequest);
            console.log(isRefreshingToken);
            originalRequest.__retryCount = originalRequest.__retryCount || 0;
            console.log("retry count: " + originalRequest.__retryCount);
            if (originalRequest.__retryCount >= 3) {
                console.log("axios.interceptors.response return error after 3 retries");
                console.log(error);
                isRefreshingToken = false;
                return Promise.reject(error);
            }

            if (isRefreshingToken) {
                console.log("WAITING REFRESHING START some other process is renewing token. delay 1s and continue this request.");
                await new Promise(resolve => setTimeout(resolve, 500));
                let newAccessToken = localStorage.getItem("access_token");
                originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
                console.log("retrying original request after some other proess renewed token.");
                return axios.request(originalRequest);
            } else {
                isRefreshingToken = true;
                originalRequest.__retryCount += 1;

                // refresh access token
                let startTime = new Date();
                console.log(originalRequest.headers);
                const refreshedToken = await axios.get('/api/auth/renew-token', {
                    headers: originalRequest.headers
                });
                let endTime = new Date();
                console.log("RENEW TOKEN TOOK: " + ((endTime - startTime) / 1000) + " seconds.");
                const newAccessToken = refreshedToken.data.accessToken;
                if (newAccessToken) {
                    // store access token in local storage
                    localStorage.setItem("access_token", newAccessToken);
                    // must do headers['Authorization']. headers.authorization will ADD another Bearer <token> to this header.
                    originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
                    isRefreshingToken = false;
                }
                console.log("retrying original request after current process renewed token.");
                return axios.request(originalRequest);
            }
        } else {
            isRefreshingToken = false;
            return Promise.reject(error);
        }
    }
);

const loader = new Loader({
    apiKey: "AIzaSyDCHOO41I21tKSMCzpoXI0Aizz39epDs2g",
    version: "beta",
    mapIds: ["1a9316535142ffae"],
    libraries: ["geometry", "places"]
});

const initialState = {
    currUserDisplay: {},
    draft: {
        draftMode: false,
        createTimeEpochInSeconds: null,
        markers: [],
        route: {}
    },
    directionsRenderers: [],
    routePolylines: [],
    markers: [],
    file: null,
    sideTab: "FEED", // FEED or CREATE
    rdpEpsilon: 0.0001,
    rdpMarkers: [],
    rdpPolylines: [],
    createTimeEpochInSeconds: null, // TODO: may be not used. if not, remove.
    env: "production",
    feed: [], // array of routes search result, order by create date desc
    feedSearchBefore: null, // createTimeEpochInSeconds of the last feed
    displayImage: false,
    clickedMarker: null,
    editProfile: false,
    footerTab: "HOME",
    currentViewedFeed: null,
    zDisplayedMarkers: [], // track current markers on map. should pre-populate html.
};

const defaultMapOptions = {
    center: {
        lat: 40.762312,
        lng: -73.989345
    },
    zoom: 12,
    tilt: 45,
    mapId: "1a9316535142ffae",
    clickableIcons: false, // do not allow clicking of anything on Google Map
    disableDefaultUI: true,
};

export default class HomeComponent extends Component {
    // defaultProps is Component class properties.
    // this.props.center
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            ...props
        };
        this.handleAnimateEvent = this.handleAnimateEvent.bind(this);
        this.renderDirectionResult = this.renderDirectionResult.bind(this);
        this.imageUploadRef = React.createRef();
    }

    componentDidMount() {
        let self = this;
        loader.load().then((google) => {
            const map = new google.maps.Map(
                self.googleMapDiv,
                defaultMapOptions);
            this.setState({
                loaded: true,
                map: map,
                maps: google.maps,
                google: google,
                directionsService: new google.maps.DirectionsService,
                placesService: new google.maps.places.PlacesService(map),
                bounds: new google.maps.LatLngBounds()
            });

            map.addListener("click", function (e) {
                console.log(e);
                console.log("map clicked");
                // e.stop(); // this will stop click event propagation to google map elements.
            });
            // map.setZoom(14);
            // map.setMapTypeId("satellite");
            // map.setTilt(45);
            // map.addListener("zoom_changed", () => {
            //     self.setState({zoomLevel: map.getZoom() + " " + map.getTilt() + " " + map.getHeading()});
            // });
            // map.addListener("heading_changed", () => {
            //     self.setState({zoomLevel: map.getZoom() + " " + map.getTilt() + " " + map.getHeading()});
            // });

            // detect current user location
            if (navigator?.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        map.setCenter(pos);
                        map.setZoom(12);
                    },
                    () => {
                        // error here.
                    }
                );
            }
        });

        axios.get("/api/env")
            .then(res => {
                console.log("axios.get /api/env");
                console.log(res.data);
                this.setState({ env: res.data.env })
            })
            ;

        axios.get("/api/user/me", {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("access_token")
            }
        })
            .then(res => {
                console.log("axios.get /api/user/me");
                console.log(res.data);
                this.setState({ currUserDisplay: res.data[0] })
                if (res.data[0].username === undefined || res.data[0].username === null) {
                    this.setState({
                        profileSetupView: "postRegisterMissingUserName",
                        editProfile: true
                    });
                }
            });
    }

    fetchFeed = (searchBefore, size) => {
        console.log("fetching feed from home: " + searchBefore + " " + size);
        if (searchBefore === null) {
            searchBefore = Math.round(new Date().getTime() / 1000);
        }
        axios.get(
            `/api/user/me/feed?timeBefore=${searchBefore}&size=${size ?? 10}`,
            {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                }
            }
        )
            .then(res => {
                console.log("search from home completed.");
                let data = res.data;
                console.log(data);
                if (data && data.length > 0) {
                    this.setState(prevState => ({
                        feed: [...prevState.feed, ...data],
                        feedSearchBefore: data[data.length - 1].createTimeEpochInSeconds
                    }));
                }
            });
    }

    setCreateTimeEpochInSeconds = () => {
        if (this.state.draft.createTimeEpochInSeconds === null) {
            this.clearObjectsOnMap();
            let timestamp = Math.round(new Date().getTime() / 1000);
            this.setState(prevState => ({
                draft: {
                    ...prevState.draft,
                    createTimeEpochInSeconds: timestamp
                },
                bounds: new this.state.google.maps.LatLngBounds() // create new bound
            }));
        }
    }

    /*
        in draft mode, when a photo is uploaded, drop it on map, and extend bound.
    */
    draftPhotoUploadedEvent = (file) => {
        console.log("draftPhotoUploadedEvent");
        const myLatLng = { lat: file.latitude, lng: file.longitude };

        let marker = {
            routeCreateTimeEpochInSeconds: this.state.draft.createTimeEpochInSeconds,
            location: myLatLng,
            file: file,
        };

        let nearByServiceReq = {
            location: new this.state.google.maps.LatLng(file.latitude, file.longitude),
            radius: '100',
        };
        this.state.placesService.nearbySearch(nearByServiceReq, function (results, status) {
            console.log(results);
            if (status == 'OK') {
                let filteredResults = FilterPlaceResults(results);
                marker.placeResults = filteredResults;
            } else {
                console.log('nearbySearch error: ' + status);
            }
        });

        this.state.map.panTo(myLatLng);
        this.state.bounds.extend(myLatLng);
        this.state.map.fitBounds(this.state.bounds);

        // store the newly uploaded photo into the state, so we can use it in other area.
        // ...prevState is a clone of the array
        this.setState(prevState => ({
            footerTab: "ADDPHOTO",
            markers: this.sortMarkersByDate([...prevState.markers, marker]),
            draft: {
                ...prevState.draft,
                draftMode: true,
                markers: this.sortMarkersByDate([...prevState.draft.markers, marker])
            }
        }));
    }

    sortMarkersByDate = (markers, filterByDateTime) => {
        // add marker index on route for each markers.
        if (filterByDateTime) {
            markers = markers.filter(m => m.routeCreateTimeEpochInSeconds === filterByDateTime);
        }
        markers = markers.sort((a, b) => a.file.datetime > b.file.datetime ? 1 : -1);
        if (filterByDateTime) {
            markers.forEach((m, i) => m.positionOnRoute = i);
        }
        return markers;
    }

    handleDraftMarkerDelete = (marker) => {
        console.log("handleDraftMarkerDelete");
        console.log(marker);

        this.setState(prevState => ({
            markers: this.sortMarkersByDate(prevState.markers.filter(m => m.positionOnRoute !== marker.positionOnRoute)),
            draft: {
                ...prevState.draft,
                markers: this.sortMarkersByDate(prevState.draft.markers.filter(m => m.positionOnRoute !== marker.positionOnRoute))
            }
        }));

        // re-route
        this.handleReadyToRouteEvent();
    }

    handleDraftLocationSelected = (marker, location) => {
        console.log("handleDraftLocationSelected");
        console.log(marker);
        console.log(location);
        // copy markers
        let updatedMarkers = [...this.state.markers];
        let markerToUpdate = updatedMarkers.find(m => {
            return m.location.lat === marker.location.lat
                && m.location.lng === marker.location.lng
                && m.file.lastModified === marker.file.lastModified
        });
        // copy draft.markers
        let updatedDraftMarkers = [...this.state.draft.markers];
        let draftMarkerToUpdate = updatedDraftMarkers.find(m => {
            return m.location.lat === marker.location.lat
                && m.location.lng === marker.location.lng
                && m.file.lastModified === marker.file.lastModified
        });

        // update state
        if (location?.place_id === undefined || location?.place_id === null) {
            // this is in case if user wants to reset place to nothing.
            markerToUpdate.placeResult = {};
            draftMarkerToUpdate.placeResult = {};
        } else {
            markerToUpdate.placeResult = {
                name: location.name,
                place_id: location.place_id,
                types: location.types,
                vicinity: location.vicinity
            };
            draftMarkerToUpdate.placeResult = {
                name: location.name,
                place_id: location.place_id,
                types: location.types,
                vicinity: location.vicinity
            };
        }

        // update state
        this.setState(prevState => ({
            markers: updatedMarkers,
            draft: {
                ...prevState.draft,
                markers: updatedDraftMarkers
            }
        }));
    }

    handleMarkerClick = (marker) => {
        console.log("handleMarkerClick");
        console.log(marker);
        if (this.state.draft.draftMode
            && marker.placeResult == null
            && marker.placeResults
            && marker.placeResults.length > 0
        ) {
            // if draft mode and image is being opened, and placeResult has not been set, and there are places
            // then set placeResult to the first place
            this.handleDraftLocationSelected(marker, marker.placeResults[0]);
        }
        this.setState({
            clickedMarker: marker,
            displayImage: true,
            file: marker.file
        });
        // if change is less than both the width and height of the map, the transition will be smoothly animated.
        this.state.map.panTo(marker.location);
    }

    handleReadyToRouteEvent = () => {
        console.log("handleReadyToRouteEvent");

        // reset existing items on the map
        this.state.rdpMarkers.forEach((r) => {
            r.setMap(null);
        });

        this.setState(prevState => ({
            rdpMarkers: []
        }));

        // get direction result
        this.handleRouteEvent();

        // add marker index on route for each markers.
        this.state.markers
            .filter(m => m.routeCreateTimeEpochInSeconds === this.state.draft.createTimeEpochInSeconds)
            .sort((a, b) => a.file.datetime > b.file.datetime ? 1 : -1)
            .forEach((m, i) => m.positionOnRoute = i);

        const draftMarkersSorted = [].concat(this.state.draft.markers).sort((a, b) => a.file.datetime > b.file.datetime ? 1 : -1);
        let waypoints = [];
        for (var i = 0; i < draftMarkersSorted.length; i++) {
            waypoints.push({
                // datetime: draftMarkersSorted[i].file.datetime,
                file: draftMarkersSorted[i].file,
                location: {
                    lat: draftMarkersSorted[i].file.latitude,
                    lng: draftMarkersSorted[i].file.longitude
                },
                media: {
                    photo: {
                        name: draftMarkersSorted[i].file.name,
                        originalImageWidth: draftMarkersSorted[i].file.originalImageWidth,
                        originalImageHeight: draftMarkersSorted[i].file.originalImageHeight
                    }
                },
                positionOnRoute: i,
                routeCreateTimeEpochInSeconds: this.state.draft.createTimeEpochInSeconds,
            });
        }
        let draftRoute = {
            createTimeEpochInSeconds: this.state.draft.createTimeEpochInSeconds,
            travelMode: "DRIVING",
            waypoints: waypoints,
            permission: "PUBLIC"
        }

        this.setState(prevState => ({
            draft: {
                ...prevState.draft,
                route: draftRoute
            }
        }))
    }

    /*
        1. call Google Direction Service to get the DirectionsResults
        2. find waypoint indexes on the DirectionsResults
        3. for each path between each waypoint, call RDP to get simplified path
        4. animate from beginning to last, stop at each waypoint for 2s.
    */
    handleRouteEvent = () => {
        console.log("handleRouteEvent");
        const photosSortedByDatetime = [].concat(this.state.markers).sort((a, b) => a.file.datetime > b.file.datetime ? 1 : -1);
        const start = photosSortedByDatetime[0].file;
        const end = photosSortedByDatetime[photosSortedByDatetime.length - 1].file;

        console.log(start);
        console.log(end);

        let origin = { lat: start.latitude, lng: start.longitude };
        let destination = { lat: end.latitude, lng: end.longitude };
        let waypoints = [];
        for (var i = 1; i < photosSortedByDatetime.length - 1; i++) {
            waypoints.push({
                location: {
                    lat: photosSortedByDatetime[i].file.latitude,
                    lng: photosSortedByDatetime[i].file.longitude
                }
            });
        }
        this.route(origin, destination, waypoints);
    }

    handleFeedClickEvent = (data) => {
        console.log("home handleFeedClickEvent");
        console.log(data);

        // reset current map data
        this.clearObjectsOnMap();

        this.setState({ currentViewedFeed: data });

        data.clicked = true;
        let self = this;
        let start = data.waypoints[0];
        let end = data.waypoints[data.waypoints.length - 1];
        let waypointsLocation = data.waypoints.slice(1, data.waypoints.length - 1).map(w => {
            return {
                location: w.location
            }
        });
        this.route(start.location, end.location, waypointsLocation);

        data.waypoints.forEach((w, i) => {
            this.downloadPhoto(data.user.userId, data.createTimeEpochInSeconds, w.media.photo.name, function (blob) {
                const url = URL.createObjectURL(blob);
                console.log("file");
                console.log(url);
                var newFile = new File(
                    [blob],
                    w.media.photo.name,
                    {
                        type: blob.type
                    }
                );
                newFile.originalImage = url;
                newFile.originalImageWidth = w.media.photo.originalImageWidth ?? 500;
                newFile.originalImageHeight = w.media.photo.originalImageHeight ?? 500;

                w.routeCreateTimeEpochInSeconds = data.createTimeEpochInSeconds;
                w.positionOnRoute = i;
                w.file = newFile;
                self.setState(prevState => ({
                    markers: [...prevState.markers, w]
                }));
            });
        });
    }

    downloadPhoto = (userId, routeTimeEpochInSeconds, filename, onDownLoadSuccess) => {
        console.log("downloadPhoto");
        var params = "userId=" + userId + "&filename=" + filename + "&createTimeEpochInSeconds=" + routeTimeEpochInSeconds;
        axios.get("/api/file?" + params, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("access_token")
            },
            responseType: 'blob'
        })
            .then(resp => {
                console.log(resp);
                // Create blob link to download
                // this.setState({imageUrl: url});
                onDownLoadSuccess(resp.data);
            })
            .catch(error => {
                console.log('There was an error!', error);
            });
    }

    route = (origin, destination, waypoints) => {
        this.googleMapJs.route(origin, destination, waypoints);
    }

    /*
        2. find waypoint indexes on the DirectionsResults
        3. for each path between each waypoint, call RDP to get simplified path
        4. animate from beginning to last, stop at each waypoint for 2s.
    */
    renderDirectionResult(directionsRenderer, response) {
        var overview_path = response.routes[0].overview_path;
        // directionsRenderer.setDirections(response); // Google's default rendering (light blue)
        let routePolyline;
        if (this.state.routePolylines && this.state.routePolylines.length > 0) {
            routePolyline = this.state.routePolylines[0];
            routePolyline.setPath(overview_path);
        } else {
            routePolyline = new this.state.maps.Polyline({
                path: overview_path,
                geodesic: true,
                strokeColor: '#ff8a14', // FF5656, ff8a14
                strokeWeight: 3,
                strokeOpacity: 0.5,
                map: this.state.map
            });
        }
        // this.gMapFitBounds(response.routes[0].bounds);
        this.state.map.fitBounds(response.routes[0].bounds, { top: 140, bottom: 10, left: 40, right: 40 });
        this.state.map.setTilt(45);

        let points = overview_path.map(p => NormalizePoint(p));
        console.log("beginning total points: " + points.length);
        let result = DouglasPeucker(points, 0, overview_path.length - 1, this.state.rdpEpsilon);
        console.log(result);

        let rdpMarkers = [];
        let rdpPolylines = [];
        let rdpMarkerStart = new this.state.maps.Marker({
            position: { lat: result[0].lat, lng: result[0].lng },
            map: this.state.map,
            icon: {
                path: this.state.maps.SymbolPath.CIRCLE,
                scale: 5,
                strokeColor: "#9d26ff"
            }
        });
        rdpMarkers.push(rdpMarkerStart);
        let rdpMarkerEnd = new this.state.maps.Marker({
            position: { lat: result[result.length - 1].lat, lng: result[result.length - 1].lng },
            map: this.state.map,
            icon: {
                path: this.state.maps.SymbolPath.CIRCLE,
                scale: 5,
                strokeColor: "#9d26ff"
            }
        });
        rdpMarkers.push(rdpMarkerEnd);

        // store directionsRenderer to state, so we can use it in other area.
        this.setState(prevState => ({
            directionsRenderers: [...prevState.directionsRenderers, directionsRenderer], // ...prevState is a clone of the array
            routePolylines: [routePolyline],
            lastRouteResponse: response,
            rdpPoints: result,
            rdpMarkers: rdpMarkers,
            rdpPolylines: rdpPolylines
        }));
    }

    handleSaveRouteEvent = (title, location) => {
        console.log("handleSaveRouteEvent");
        if (!this.state.draft.draftMode) {
            console.log("nothing to save.");
            return;
        }
        const draftMarkersSorted = [].concat(this.state.draft.markers).sort((a, b) => a.file.datetime > b.file.datetime ? 1 : -1);
        let waypoints = [];
        for (var i = 0; i < draftMarkersSorted.length; i++) {
            // save files
            let formData = new FormData();
            formData.append('folder', this.state.draft.createTimeEpochInSeconds);
            formData.append('file', draftMarkersSorted[i].file);
            axios.post(
                "/api/file",
                formData,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
                        "Content-Type": "multipart/form-data"
                    }
                }
            )
                .then(resp => {
                    console.log("success")
                    console.log(resp);
                })
                .catch(error => {
                    console.log('There was an error!', error);
                });

            waypoints.push({
                // datetime: draftMarkersSorted[i].file.datetime,
                location: {
                    lat: draftMarkersSorted[i].file.latitude,
                    lng: draftMarkersSorted[i].file.longitude
                },
                placeResult: draftMarkersSorted[i].placeResult,
                media: {
                    photo: {
                        name: draftMarkersSorted[i].file.name,
                        originalImageWidth: draftMarkersSorted[i].file.originalImageWidth,
                        originalImageHeight: draftMarkersSorted[i].file.originalImageHeight,
                        createDate: draftMarkersSorted[i].file.datetime
                    }
                }
            });
        }

        if (waypoints && waypoints.length > 0) {
            // save route data
            let routeRequest = {
                "route": {
                    "createTimeEpochInSeconds": this.state.draft.createTimeEpochInSeconds,
                    "travelMode": "DRIVING",
                    "waypoints": waypoints,
                    "permission": "PUBLIC",
                    "title": title,
                    "location": location
                }
            }

            axios.post(
                "/api/route",
                routeRequest,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
                    }
                }
            )
                .then(resp => {
                    console.log(resp.data);
                    console.log("saved successfully");
                })
                .catch(error => {
                    console.log('There was an error!', error);
                });

            // add to feed, reset draft
            let addToFeed = routeRequest.route;
            addToFeed.waypoints = this.state.draft.route.waypoints.map(
                w => {
                    // add placeResult in waypoints.
                    let matchingMarker = this.state.draft.markers.find(m => m.location.lat == w.location.lat && m.location.lng == w.location.lng && m.file.lastModified == w.file.lastModified)
                    if (matchingMarker.placeResult && matchingMarker.placeResult.name) {
                        w.placeResult = matchingMarker.placeResult;
                    }
                    return w;
                }
            );
            addToFeed.user = this.state.currUserDisplay;

            this.setState(prevState => ({
                feed: [addToFeed, ...prevState.feed],
                draft: initialState.draft
            }));
        } else {
            console.log("nothing to save.");
            this.setState(prevState => ({
                draft: initialState.draft
            }));
        }
    }

    handleAminateRdpEvent = () => {
        this.googleMapJs.animate(this.state.rdpPoints);
    }

    handleAnimateEvent() {
        console.log("NOT USED handleAnimateEvent");
        const self = this;

        var directionsRoute = this.state.lastRouteResponse.routes[0];
        var overview_path = directionsRoute.overview_path;
        // var overview_polyline = directionsRoute.overview_polyline;

        // const lineSymbol = {
        //     path: this.state.maps.SymbolPath.CIRCLE,
        //     scale: 8,
        //     strokeColor: "#393",
        // };

        var polylines = [];

        for (var i = 0; i < overview_path.length - 1; i = i + 1) {
            var currPolyline = new this.state.maps.Polyline({
                path: [overview_path[i], overview_path[i + 1]],
                geodesic: true,
                strokeColor: '#FFA500',
                strokeWeight: 5
            });
            polylines.push(currPolyline);
        }

        var polylineIndex = 0;
        var myFunction = function () {
            console.log("myFunction");
            polylines[polylineIndex].setMap(self.state.map);
            polylineIndex++;

            if (polylineIndex < polylines.length) {
                setTimeout(myFunction, 20);
            }
        }
        setTimeout(myFunction, 20);
    }

    handleDebugEvent = () => {
        let bounds = this.state.map.getBounds();
        console.log(bounds);
        var overlay = new this.state.google.maps.OverlayView();
        overlay.draw = function () { };
        overlay.setMap(this.state.map);
        var projection = overlay.getProjection();

        let sw0 = bounds.getSouthWest();
        let ne0 = bounds.getNorthEast();
        console.log(sw0.lat() + " - " + sw0.lng());
        console.log(ne0.lat() + " - " + ne0.lng());

        let swBottmLeft = this.state.map.getProjection().fromLatLngToPoint(sw0); // convert to world coordinat, which does not change by zoom level.
        console.log(swBottmLeft);

        // Get pixels:    
        var sw = projection.fromLatLngToDivPixel(bounds.getSouthWest());
        var ne = projection.fromLatLngToDivPixel(bounds.getNorthEast());
        console.log(sw);
        console.log(ne);

    }

    handleClearEvent = () => {
        this.state.directionsRenderers.forEach((dr) => {
            dr.setMap(null);
        });
        this.state.rdpMarkers.forEach((r) => {
            r.setMap(null);
        });
        this.setState(prevState => (initialState));
    }

    onImageViewerClose = (e) => {
        console.log("click closed");
        this.setState({ displayImage: false });
    }

    handleSwitchSideEvent = () => {
        if (this.state.sideTab === "FEED") {
            this.setState({ sideTab: "CREATE" });
        } else {
            this.setState({ sideTab: "FEED" });
        }
    }

    onClickAddFriend = () => {
        console.log("add friend");
        this.setState({
            searchFriend: true,
            footerTab: "ADDFRIEND"
        });
    }

    onAddFriendClose = (e) => {
        console.log("AddFriendClose closed");
        this.setState({
            searchFriend: false,
            footerTab: "HOME"
        });
    }

    // left: dir = -1. right: dir = 1
    onClickImageArrowHandler = (data, dir) => {
        console.log("onClickImageArrowHandler " + dir);
        // console.log(data);

        // load image
        let routeIndex = this.state.feed.map(f => f.createTimeEpochInSeconds).indexOf(data.routeCreateTimeEpochInSeconds);

        // check if we are on draft first
        if (routeIndex < 0 && this.state.draft.draftMode) {
            // draft mode
            let imageOnRouteIndex = data.positionOnRoute + dir;
            let draftMarkers = this.state.draft.markers;
            if (imageOnRouteIndex < 0) {
                // for now, do not move to previous route
                // routeIndex = Math.max(routeIndex - 1, 0);
                imageOnRouteIndex = 0;
            } else if (imageOnRouteIndex > draftMarkers.length - 1) {
                // for now, do not move to next route
                // routeIndex = Math.min(routeIndex + 1, this.state.feed.length - 1);
                imageOnRouteIndex = 0;
            }
            console.log(imageOnRouteIndex);
            console.log(draftMarkers);
            this.handleMarkerClick(draftMarkers[imageOnRouteIndex]);
        } else {
            // normal view mode
            let currRoute = this.state.feed[routeIndex];
            let imageOnRouteIndex = data.positionOnRoute + dir;
            if (imageOnRouteIndex < 0) {
                // for now, do not move to previous route
                // routeIndex = Math.max(routeIndex - 1, 0);
                imageOnRouteIndex = 0;
            } else if (imageOnRouteIndex > currRoute.waypoints.length - 1) {
                // for now, do not move to next route
                // routeIndex = Math.min(routeIndex + 1, this.state.feed.length - 1);
                imageOnRouteIndex = 0;
            }
            // currRoute = this.state.feed[routeIndex];
            this.handleMarkerClick(currRoute.waypoints[imageOnRouteIndex]);
        }
    }

    onClickCloseProfileSetup = () => {
        console.log("onClickCloseProfileSetup");
        this.setState({
            profileSetupView: null,
            editProfile: false
        });
    }

    handleUploadImageDivClicked = (e) => {
        console.log("handleUploadImageDivClicked");
        this.imageUploadRef.current.fileUploadAction(); // this will click this div again.
    }

    navigationButton = (e) => {
        console.log("navigationButton " + e);
        if (e === "HOME" && this.state.footerTab !== "HOME") {
            this.clearObjectsOnMap();
        }
        this.setState({ footerTab: e });
    }

    clearObjectsOnMap = () => {
        console.log("clearObjectsOnMap");
        this.state.directionsRenderers.forEach((dr) => {
            dr.setMap(null);
        });
        this.state.rdpMarkers.forEach((r) => {
            r.setMap(null);
        });
        this.state.routePolylines.forEach((rp) => {
            rp.setMap(null);
        });
        this.setState({
            rdpMarkers: [],
            rdpPoints: [],
            directionsRenderers: [],
            markers: [],
            routePolylines: [],
            draft: initialState.draft,
            clickedMarker: null,
            displayImage: false,
            currentViewedFeed: null
        });
    }

    /*
        when like/dislike, should also update the data in feed.
        otherwise, next time feed is clicked, this data is lost unless re-pull from DB
     */
    onClickLike = () => {
        console.log("onClickLike");
        console.log(this.state.currentViewedFeed);

        /*
            if not liked, then like

            if liked, then dislike

            save to DB
            add animation to existing Liked By (current user profile photo)
         */
        if (this.state.currentViewedFeed) {
            let like = true;
            let datetime = new Date();
            if (this.state.currentViewedFeed.likes?.some(a => a.userId == this.state.currUserDisplay.userId)) {
                like = false;
            }
            axios.patch(
                "/api/route",
                {
                    userId: this.state.currentViewedFeed.user.userId,
                    createTimeEpochInSeconds: this.state.currentViewedFeed.createTimeEpochInSeconds,
                    like: like,
                    datetime: datetime
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                    }
                }
            )
                .then(res => {
                    let updatedCurrViewedFeed = {
                        ...this.state.currentViewedFeed
                    }
                    if (like) {
                        let newLike = {
                            userId: this.state.currUserDisplay.userId,
                            datetime: datetime
                        };
                        if (updatedCurrViewedFeed.likes) {
                            updatedCurrViewedFeed.likes.push(newLike);
                        } else {
                            updatedCurrViewedFeed.likes = [newLike];
                        }
                    } else {
                        let self = this;
                        updatedCurrViewedFeed.likes = updatedCurrViewedFeed.likes.filter(function (obj) {
                            return obj.userId !== self.state.currUserDisplay.userId;
                        });
                    }
                    this.setState({
                        currentViewedFeed: updatedCurrViewedFeed
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    render() {
        return (
            <>
                {this.state.draft.draftMode &&
                    <DraftComponent
                        handleSaveRouteEvent={this.handleSaveRouteEvent}
                        navigationButton={this.navigationButton} />
                }
                <div className='main-view-header'>
                    <HeaderComponent onClickLogout={this.props.onClickLogout} />
                </div>
                <div className="main-view">
                    <div className={`main-view-relative ${this.state.currentViewedFeed != null ? "main-view-relative-active" : ""}`}>
                        <div className="main-view-content">
                            <div className={this.state.displayImage ? 'googlemap-half' : 'googlemap'}
                                ref={(ref) => { this.googleMapDiv = ref }}
                            >
                                {this.state.map && <GoogleMapComponent
                                    ref={(ref) => { this.googleMapJs = ref }}
                                    map={this.state.map}
                                    maps={this.state.maps}
                                    directionsService={this.state.directionsService}
                                    renderDirectionResult={this.renderDirectionResult}
                                />}
                            </div>

                            <div className={this.state.displayImage ? "left-imageViewer-show" : "left-imageViewer-hide"}>
                                <ImageViewer
                                    clickedMarker={this.state.clickedMarker}
                                    displayImage={this.state.displayImage}
                                    onCloseHandler={this.onImageViewerClose}
                                    onClickImageArrowHandler={this.onClickImageArrowHandler}
                                    zDisplayedMarkers={this.state.zDisplayedMarkers}
                                    draftMode={this.state.draft.draftMode}
                                    handleDraftLocationSelected={this.handleDraftLocationSelected}
                                />
                            </div>

                            {
                                this.state.currentViewedFeed &&
                                <div className="main-view-info">
                                    <div className="main-view-info-details">
                                        <div className="main-view-info-details-row">
                                            <div>
                                                <svg className="main-view-info-details-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" ><path d="M19 4h-3V2h-2v2h-4V2H8v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM5 20V7h14V6l.002 14H5z"></path><path d="M7 9h10v2H7zm0 4h5v2H7z"></path></svg>
                                            </div>
                                            <div>
                                                <span>{this.state.currentViewedFeed?.title}</span>
                                            </div>
                                        </div>
                                        {/* <div className="main-view-info-details-row">
                                            <div>
                                                <svg className="main-view-info-details-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" ><path d="M12 2C7.589 2 4 5.589 4 9.995 3.971 16.44 11.696 21.784 12 22c0 0 8.029-5.56 8-12 0-4.411-3.589-8-8-8zm0 12c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"></path></svg>
                                            </div>
                                            <div>
                                                <span>{this.state.currentViewedFeed?.location}</span>
                                            </div>
                                        </div> */}
                                    </div>

                                    {
                                        this.state.currentViewedFeed.likes &&
                                        <div className="main-view-info-liked-by">
                                            {
                                                this.state.currentViewedFeed.likes.some((obj) => obj.userId === this.state.currUserDisplay.userId)
                                                &&
                                                <div className="main-view-info-liked-by-profile-photo-wrapper" style={{ zIndex: 15 }}>
                                                    <ProfilePhotoComponent
                                                        key="main-view-info-liked-by-curr"
                                                        userId={this.state.currUserDisplay.userId}
                                                        className="main-view-info-liked-by-profile-photo"
                                                    />
                                                </div>
                                            }
                                            {
                                                this.state.currentViewedFeed.likes.filter(obj => obj.userId !== this.state.currUserDisplay.userId).map((l, index) =>
                                                    <div key={`main-view-info-liked-by-photo-div-${index}`} className="main-view-info-liked-by-profile-photo-wrapper"
                                                        style={{ zIndex: 10 - index }}>
                                                        <ProfilePhotoComponent
                                                            key={`main-view-info-liked-by-${index}`}
                                                            profilePhoto={l.profilePhotoBlob}
                                                            className="main-view-info-liked-by-profile-photo"
                                                        />
                                                    </div>
                                                )
                                            }
                                            {this.state.currentViewedFeed.likes.length > 3 && <p>&middot;&middot;&middot;</p>}
                                        </div>
                                    }

                                    <div className="main-view-info-like" onClick={this.onClickLike}>
                                        {
                                            this.state.currentViewedFeed.likes?.some(a => a.userId === this.state.currUserDisplay.userId)
                                                ?
                                                <svg className="main-view-info-like-true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <defs>
                                                        <linearGradient id="heart-liked-gradient" x2="0.35" y2="1">
                                                            <stop offset="0%" stopColor="var(--color-stop)" />
                                                            <stop offset="30%" stopColor="var(--color-stop)" />
                                                            <stop offset="100%" stopColor="var(--color-bot)" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path d="M20.205 4.791a5.938 5.938 0 0 0-4.209-1.754A5.906 5.906 0 0 0 12 4.595a5.904 5.904 0 0 0-3.996-1.558 5.942 5.942 0 0 0-4.213 1.758c-2.353 2.363-2.352 6.059.002 8.412L12 21.414l8.207-8.207c2.354-2.353 2.355-6.049-.002-8.416z"></path>
                                                </svg>
                                                :
                                                <svg className="main-view-info-like-false" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24">
                                                    <path d="M12 4.595a5.904 5.904 0 0 0-3.996-1.558 5.942 5.942 0 0 0-4.213 1.758c-2.353 2.363-2.352 6.059.002 8.412l7.332 7.332c.17.299.498.492.875.492a.99.99 0 0 0 .792-.409l7.415-7.415c2.354-2.354 2.354-6.049-.002-8.416a5.938 5.938 0 0 0-4.209-1.754A5.906 5.906 0 0 0 12 4.595zm6.791 1.61c1.563 1.571 1.564 4.025.002 5.588L12 18.586l-6.793-6.793c-1.562-1.563-1.561-4.017-.002-5.584.76-.756 1.754-1.172 2.799-1.172s2.035.416 2.789 1.17l.5.5a.999.999 0 0 0 1.414 0l.5-.5c1.512-1.509 4.074-1.505 5.584-.002z">
                                                    </path>
                                                </svg>
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                        {(this.state.profileSetupView || this.state.editProfile) &&
                            <>
                                <div className="main-view-profile-setup-absolute" />
                                <div className="main-view-profile-setup">
                                    <ProfileSetupComponent
                                        profileSetupView={this.props.profileSetupView ?? this.state.profileSetupView}
                                        onClickCloseProfileSetup={this.onClickCloseProfileSetup}
                                    />
                                </div>
                            </>
                        }
                        {this.state.searchFriend &&
                            <>
                                <div className="main-view-profile-setup-absolute" />
                                <SearchFriendComponent onCloseHandler={this.onAddFriendClose} />
                            </>
                        }
                    </div>

                    {this.state.currUserDisplay.userId &&
                        <div className={this.state.draft.draftMode
                            ? 'main-view-side-draft-mode-on'
                            : `main-view-side ${this.state.currentViewedFeed != null ? "main-view-side-active" : ""}`} >
                            <SideFriendListComponent
                                sideTab={this.state.sideTab}
                                feed={this.state.feed}
                                feedSearchBefore={this.state.feedSearchBefore}
                                fetchFeed={this.fetchFeed}
                                handleFeedClickEvent={this.handleFeedClickEvent}
                                currentViewedFeed={this.state.currentViewedFeed}
                            />
                        </div>}

                    {this.state.markers.map((m, index) => {
                        return <MarkerThumbnailComponent
                            key={`${m.location.lat}:${m.location.lng}:${m.file.lastModified}`}
                            marker={m}
                            map={this.state.map}
                            maps={this.state.maps}
                            handleMarkerClick={this.handleMarkerClick}
                            draftMode={this.state.draft.draftMode}
                            handleDraftMarkerDelete={this.handleDraftMarkerDelete}
                        />;
                    })}
                </div>

                {this.state.env === "development" &&
                    <div className="test-buttons">
                        <Button variant="secondary" onClick={this.handleClearEvent}>Clear</Button>
                        <Button variant="secondary" onClick={this.handleSwitchSideEvent}>SwitchSide</Button>
                        <Button variant="secondary" onClick={this.handleDebugEvent}>test</Button>
                    </div>
                }

                <div className="main-view-footer">
                    <div className="main-view-footer-list">
                        <div className={`main-view-footer-item ${this.state.footerTab === "HOME" ? "main-view-footer-item-active" : ""}`} onClick={() => this.navigationButton("HOME")}>
                            <svg className="main-view-footer-icons " width="24" height="24" viewBox="0 0 24 24"><path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm9-8.586 6 6V15l.001 5H6v-9.586l6-6z"></path><path d="M12 18c3.703 0 4.901-3.539 4.95-3.689l-1.9-.621c-.008.023-.781 2.31-3.05 2.31-2.238 0-3.02-2.221-3.051-2.316l-1.899.627C7.099 14.461 8.297 18 12 18z"></path></svg>
                        </div>

                        <div className={`main-view-footer-item ${this.state.footerTab === "ADDPHOTO" ? "main-view-footer-item-active" : ""}`} onClick={this.handleUploadImageDivClicked}>
                            <svg className="main-view-footer-icons" width="24" height="24" viewBox="0 0 24 24"><path d="M4 5h13v7h2V5c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h8v-2H4V5z"></path><path d="m8 11-3 4h11l-4-6-3 4z"></path><path d="M19 14h-2v3h-3v2h3v3h2v-3h3v-2h-3z"></path></svg>
                            <ImageUploadComponent
                                draftPhotoUploadedEvent={this.draftPhotoUploadedEvent}
                                setCreateTimeEpochInSeconds={this.setCreateTimeEpochInSeconds}
                                handleReadyToRouteEvent={this.handleReadyToRouteEvent}
                                ref={this.imageUploadRef}
                            />
                        </div>

                        <div className={`main-view-footer-item ${this.state.footerTab === "ADDFRIEND" ? "main-view-footer-item-active" : ""}`} onClick={this.onClickAddFriend}>
                            <svg className="main-view-footer-icons" viewBox="0 0 24 24" width="24" height="24"><path d="M19 8h-2v3h-3v2h3v3h2v-3h3v-2h-3zM4 8a3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4 3.91 3.91 0 0 0-4 4zm6 0a1.91 1.91 0 0 1-2 2 1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2zM4 18a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3v1h2v-1a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v1h2z"></path></svg>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}