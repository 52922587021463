import React, { useState, useEffect, useRef, useMemo } from 'react';
import './SideFriend.css';
import debounce from 'lodash.debounce';
import ProfilePhotoComponent from './common/ProfilePhotoComponent'
import { CSSTransitionGroup } from 'react-transition-group'

const SideFriendListComponent = (props) => {
    const [isFetching, setIsFetching] = useState(false);


    const debouncedChangeHandler = useMemo(
        () => debounce(handleScroll, 200)
        , []);

    const ulRef = useRef(null)

    // runs only once, because of [].
    useEffect(() => {
        console.log("SideFriendListComponent useEffect []");
        ulRef.current.addEventListener("scroll", debouncedChangeHandler);
        ulRef.current.addEventListener('wheel', debouncedChangeHandler);
        ulRef.current.addEventListener("mousewheel", debouncedChangeHandler);
        ulRef.current.addEventListener("DOMMouseScroll", debouncedChangeHandler);

        // load first batch
        fetchMoreListItems(5);

        return () => {
            ulRef.current.removeEventListener('scroll', debouncedChangeHandler);
            ulRef.current.removeEventListener('wheel', debouncedChangeHandler);
            ulRef.current.removeEventListener('mousewheel', debouncedChangeHandler);
            ulRef.current.removeEventListener('DOMMouseScroll', debouncedChangeHandler);
        };
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems(5);
    }, [isFetching]);

    function handleScroll() {
        console.log("scrolling...");
        let element = ulRef.current;
        // scrollHeight is total height of ul
        // scrollTop is distance to top. 0 means we are at top.
        // offsetHeight is height of element including borders
        if (element.scrollTop + element.offsetHeight !== element.scrollHeight) return;

        console.log('Fetch more list items!');
        setIsFetching(true);
    }

    function fetchMoreListItems(size) {
        console.log("fetchMoreListItems " + props.feedSearchBefore + " " + size);
        props.fetchFeed(props.feedSearchBefore, size);
        setIsFetching(false);
    }

    return (
        <div className={`side-friend-list ${props.currentViewedFeed != null ? "side-friend-list-active" : ""}`} ref={ulRef}>
            <CSSTransitionGroup
                transitionName="side-friend-item"
                transitionEnterTimeout={500}
                transitionLeave={false}>
                {props.feed.map(l =>
                    <SideFriendComponent
                        data={l}
                        key={l.user.userId + "#" + l.createTimeEpochInSeconds}
                        handleFeedClickEvent={props.handleFeedClickEvent}
                        active={ props.currentViewedFeed != null && props.currentViewedFeed.user.userId === l.user.userId && props.currentViewedFeed.createTimeEpochInSeconds === l.createTimeEpochInSeconds }
                    />)
                }
            </CSSTransitionGroup>
            <div className="side-friend-list-load-more-full" onClick={() => setIsFetching(true)}>Load More</div>
            <div className="side-friend-list-load-more-short" onClick={() => setIsFetching(true)}>...</div>
        </div>
    );
};
export default SideFriendListComponent;

const SideFriendComponent = (props) => {
    function handleProfileClickEvent() {
        props.handleFeedClickEvent(props.data);
    }

    return (
        <div className={`side-friend-li ${props.active === true ? "side-friend-li-active" : ""}`} onClick={handleProfileClickEvent} >
            <div className={`side-friend ${props.active === true ? "side-friend-active" : ""}`}>
                <ProfilePhotoComponent
                    className={`side-friend-avatar ${props.active === true ? "side-friend-avatar-active" : ""}`}
                    userId={props.data?.user?.userId}
                />
                <div className="side-friend-details">
                    <div>
                        <p className="side-friend-name">{props.data?.user?.username ?? props.data?.user?.full_name ?? props.data?.user?.email ?? ''}</p>
                        <span>{props.data?.title}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

/*
    need a div to represent the current selected feed
    if height is bigger, then the existing div in the list should increase height
    need to use js to keep track of the position of the div. this div should be on top of the existing div in the list.

    {
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25)
        transition: transform 300ms, box-shadow 300ms;
    }

    :hover {
        transform: scale(1.5)
        box-shadow: 0 15px 70px rgba(0, 0, 0, 0.25)
    }
*/