import React, { Component } from 'react';
import './SideFriend.css';
import ImageUploadComponent from './ImageUploadComponent';
import { ReactComponent as AddIcon } from './assets/zoom-in.svg';
import { ReactComponent as RouteIcon } from './assets/map-pin.svg';
import { ReactComponent as AnimateIcon } from './assets/play.svg';
import { ReactComponent as SaveIcon } from './assets/storage.svg';

export default class SideCreateComponent extends Component {

    constructor(props) {
        super();
        this.state = {
        };
        this.imageUploadRef = React.createRef();
    }

    componentDidMount() {
        console.log("mounted mounted");
    }

    handleUploadImageDivClicked = (e) => {
        console.log("handleUploadImageDivClicked");
        this.imageUploadRef.current.fileUploadAction(); // this will click this div again.
    }

    render() {
        return (
            <div className={this.props.sideTab !== "FEED" ? undefined : 'side-hidden' }>
                <ul className="side-friend-list flex-column">
                    <li className="side-friend-li" onClick={this.handleUploadImageDivClicked}>
                        <div className="side-friend">
                            <ImageUploadComponent
                                draftPhotoUploadedEvent={this.props.draftPhotoUploadedEvent}
                                setCreateTimeEpochInSeconds={this.props.setCreateTimeEpochInSeconds}
                                handleReadyToRouteEvent={this.props.handleReadyToRouteEvent}
                                ref={this.imageUploadRef}
                            />
                            <div className="side-friend-avatar">
                                <AddIcon />
                            </div>
                            <div className="side-friend-details">
                                <div>
                                    <p className="side-friend-name">Upload Photos</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="side-friend-li" onClick={this.props.handleRouteEvent}>
                        <div className="side-friend">
                            <div className="side-friend-avatar">
                                <RouteIcon />
                            </div>
                            <div className="side-friend-details">
                                <div>
                                    <p className="side-friend-name">Create Route</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="side-friend-li" onClick={this.props.handleAnimateEvent}>
                        <div className="side-friend">
                            <div className="side-friend-avatar">
                                <AnimateIcon />
                            </div>
                            <div className="side-friend-details">
                                <div>
                                    <p className="side-friend-name">Play</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="side-friend-li" onClick={this.props.handleSaveRouteEvent}>
                        <div className="side-friend">
                            <div className="side-friend-avatar">
                                <SaveIcon />
                            </div>
                            <div className="side-friend-details">
                                <div>
                                    <p className="side-friend-name">Save</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    
                </ul>
            </div>
        )
    }
}

