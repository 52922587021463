import Resizer from "react-image-file-resizer";
import exifr from 'exifr';
import convert from 'heic-convert';

/*
    need File with following additional properties:
    - originalImage
    - originalImageWidth
    - originalImageHeight
*/
export function CreateFileFromLocal(localPath, name, type, w, h, callback) {
    fetch(localPath)
        .then(function (res) {
            return res.blob();
        })
        .then(function (buf) {
            var newFile = new File(
                [buf],
                name,
                {
                    type: type
                }
            );
            var url = URL.createObjectURL(newFile);
            newFile.originalImage = url;
            newFile.originalImageWidth = w ?? 500;
            newFile.originalImageHeight = h ?? 500;
            callback(newFile);
            return newFile;
        });
}

export function CreateThumbnail(image, maxLen, returnType) {
    maxLen = maxLen || 80;
    returnType = returnType || "base64";
    let scaleRatio = 0;
    let imageWidth = image.originalImageWidth;
    let imageHeight = image.originalImageHeight;
    // console.log("Original: " + imageWidth + ", " + imageHeight);
    if (imageWidth > imageHeight) {
        scaleRatio = imageWidth / maxLen;
    } else {
        scaleRatio = imageHeight / maxLen;
    }
    var w = imageWidth / scaleRatio;
    var h = imageHeight / scaleRatio;
    // console.log("resized to: " + w + ", " + h);
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            image,
            w,
            h,
            image.type,
            100, // quality
            0, // rotation
            (uri) => {
                resolve({
                    uri, w, h
                });
            },
            returnType
        );
    });
}

/*
    process each file:
    1. use exifr (support both jpeg & heic) to get EXIF data (must do this before converting to JPEG format)
    2. if file is HEIC, convert to JPEG for display and save in AWS
*/
export function GetExifFromExifr (file, callback) {
    console.log("GetExifFromExifr");
    exifr.parse(file)
    .then(exif => {
        // now we have exif data.
        console.log(exif);
        // check if file is HEIC, then convert file to JPG first
        // should check file first few chars together with type.
        RequiresConversionFromHEIC(file)
        .then(r => {
            if (r === true) {
                console.log("File is HEIC/HEIF. Converting to image/jpeg now");
                // TODO: heavy processing, should replace this with offline process
                ConvertHeicToJpeg (file, function(outputFileBuffer){
                    // Blob is available. Create a new file from this blob
                    var newFile = new File(
                        [outputFileBuffer],
                        file.name,
                        {
                            type: "image/jpg"
                        }
                    );
                    newFile.datetime = file.datetime;
                    newFile.exifdata = exif;
                    newFile.latitude = file.latitude;
                    newFile.longitude = file.longitude;
                    newFile.originalImage = file.originalImage;
                    newFile.originalImageHeight = file.originalImageHeight;
                    newFile.originalImageWidth = file.originalImageWidth;
                    file = newFile;
                    GetExifPostProcessing (file, callback);
                });
            } else {
                console.log("File is NOT HEIC/HEIF, normal processing.");
                file.exifdata = exif;
                GetExifPostProcessing (file, callback);
            }
        })
    });
}

/*
    cannot rely on file extension.
    some files may have .heic, but the first few bytes actually have jfif.
    these files do not need conversion. they will fail in the heic-decode package.
*/
async function RequiresConversionFromHEIC (file) {
    if (file.type.toLocaleLowerCase().includes("heic") || file.type.toLocaleLowerCase().includes("heif")) {
        // double check file buffer, sometimes the file is actually JFIF (jpeg)
        const fileArrayBuffer = await file.arrayBuffer();
        const fileBuffer = Buffer.from(fileArrayBuffer);
        // heic-decode checks (8,12) for heic, heif etc. here check for a few more chars.
        const firstFewChars = String.fromCharCode(...fileBuffer.slice(0, 12)).toLowerCase();
        if (firstFewChars.includes("jfif")) {
            return false;
        }
        return true;
    }
    return false;
}

function GetExifPostProcessing (file, callback) {
    console.log("GetExifPostProcessing");
    console.log(file);
    var convertedLatLng = {
        latitude: convertEXIFGPSLatitudeToDecimal(file.exifdata.GPSLatitude[0], file.exifdata.GPSLatitude[1], file.exifdata.GPSLatitude[2], file.exifdata.GPSLatitudeRef),
        longitude: convertEXIFGPSLongitudeToDecimal(file.exifdata.GPSLongitude[0], file.exifdata.GPSLongitude[1], file.exifdata.GPSLongitude[2], file.exifdata.GPSLongitudeRef)
    }
    console.log("lat long from EXIF lib: ");
    console.log(convertedLatLng);
    file.latitude = convertedLatLng.latitude;
    file.longitude = convertedLatLng.longitude;
    file.datetime = file.exifdata.DateTimeOriginal;
    file.originalImage = URL.createObjectURL(file);

    // create image thumbnail
    var imageWidth = file.exifdata.PixelXDimension || file.exifdata.ExifImageWidth;
    var imageHeight = file.exifdata.PixelYDimension || file.exifdata.ExifImageHeight;
    var imageOrientation = file.exifdata.Orientation;

    if (ExifOrientationTextToInt(imageOrientation) % 2 === 0) {
        // rotated
        file.originalImageHeight = imageWidth;
        file.originalImageWidth = imageHeight;
    } else {
        file.originalImageHeight = imageHeight;
        file.originalImageWidth = imageWidth;
    }

    var scaleRatio = 0;
    var maxLen = 50;
    if (imageWidth > imageHeight) {
        scaleRatio = imageWidth / maxLen;
    } else {
        scaleRatio = imageHeight / maxLen;
    }
    var w = imageWidth / scaleRatio;
    var h = imageHeight / scaleRatio;

    var outputType = "base64";
    console.log("resize");
    console.log(file);
    Resizer.imageFileResizer(
        file,
        w,
        h,
        file.type,
        100, // quality
        0, // rotation
        (uri) => {
            console.log("resize done.");
            file.thumbnail = uri;
            callback(file); // HomeComponent.draftPhotoUploadedEvent(file)
        },
        outputType
    );
}

function convertEXIFGPSLatitudeToDecimal(hour, min, sec, dir) {
    return (dir === 'N' ? 1 : -1) * (hour + (min * 60 + sec) / 3600);
}

function convertEXIFGPSLongitudeToDecimal(hour, min, sec, dir) {
    return (dir === 'E' ? 1 : -1) * (hour + (min * 60 + sec) / 3600);
}

// use heic-convert to convert heic to jpeg buffer
function ConvertHeicToJpeg (sourceFile, callback) {
    const reader = new FileReader();
    reader.onload = async (e) => {
        const arraybuffer = e.target.result;
        const buffer = Buffer.from(arraybuffer);
        const outputBuffer = await convert({
            buffer: buffer, // the HEIC file buffer
            format: 'JPEG',      // output format
            quality: 0.5          // the jpeg compression quality, between 0 and 1
        });
        callback(outputBuffer);
    };
    reader.readAsArrayBuffer(sourceFile);
}

function ExifOrientationTextToInt (orientation) {
    switch(orientation) {
        case 'Horizontal (normal)':
            return 1;
        case 'Mirror horizontal':
            return 2;
        case 'Rotate 180':
            return 3;
        case 'Mirror vertical':
            return 4;
        case 'Mirror horizontal and rotate 270 CW':
            return 5;
        case 'Rotate 90 CW':
            return 6;
        case 'Mirror horizontal and rotate 90 CW':
            return 7;
        case 'Rotate 270 CW':
            return 8;
        default:
            return orientation;
    }
}

/*
    other code snippet

    Use heic2any to convert heic to jpeg:

        import heic2any from "heic2any";
        heic2any({
            blob: file,
            toType: "image/jpg",
        })
        .then(function (resultBlob) {
            // Blob is available. Create a new file from this blob
            var newFile = new File(
                [resultBlob],
                file.name,
                {
                    type: "image/jpg"
                }
            );
            newFile.exifdata = exif;
            ...
            file = newFile;
            GetExifPostProcessing (file, callback);
        })
        .catch(function (x) { console.log(x); });

*/