
export function NearestPointOnPath(waypoints, target) {
    let waypointNormalized = waypoints.map(p => {
        if (typeof p.lat == 'function') {
            return {
                lat: p.lat(),
                lng: p.lng()
            };
        } else {
            return {
                lat: p.lat,
                lng: p.lng
            };
        }
    });
    let targetNormalized = target;
    if (typeof targetNormalized.lat == 'function') {
        targetNormalized = {
            lat: target.lat(),
            lng: target.lng()
        }
    }
    var minIndex = 0;
    var currMin = 1100000;
    waypointNormalized.forEach((w, i) => {
        var lat1 = w.lat;
        var lng1 = w.lng;
        var lat2 = targetNormalized.lat;
        var lng2 = targetNormalized.lng;
        var euclideanDistanceSquared = Math.pow(lat1 - lat2, 2) + Math.pow(lng1 - lng2, 2);
        if (euclideanDistanceSquared < currMin) {
            currMin = euclideanDistanceSquared;
            minIndex = i;
        }
        // console.log(euclideanDistanceSquared + " => currMin: " + currMin);
    });
    return minIndex;
}


export function PerpendicularDistance(p0, pa, pb) {
    // console.log("perpendicularDistance");
    // console.log(p0.lat + " " + p0.lng + " TO " + pa.lat + " " + pa.lng + " | " + pb.lat + " " + pb.lng);
    // A = 0.5 * abs ( (xa - xc)(yb - ya) - (xa - xb)(yc - ya) )
    let area = 0.5 * Math.abs((p0.lat - pb.lat) * (pa.lng - p0.lng) - (p0.lat - pa.lat) * (pb.lng - p0.lng));
    // distance between a and b
    let abDistance = Math.sqrt(Math.pow(pa.lat - pb.lat, 2) + Math.pow(pa.lng - pb.lng, 2));
    let d = 2 * area / abDistance;
    // console.log(area + " " + d);
    return d;
}


export function DouglasPeucker(formatPointList, startIndex, endIndex, epsilon) {
    // Find the point with the maximum distance
    let dmax = 0;
    let index = 0;
    for (let i = startIndex + 1; i < endIndex; i++) {
        // for i = 2 to (end - 1) {
        let d = PerpendicularDistance(formatPointList[i], formatPointList[startIndex], formatPointList[endIndex]);
        if (d > dmax) {
            index = i;
            dmax = d;
        }
    }

    let resultList = [];

    // If max distance is greater than epsilon, recursively simplify
    if (dmax > epsilon) {
        // Recursive call
        let recResults1 = DouglasPeucker(formatPointList, startIndex, index, epsilon);
        let recResults2 = DouglasPeucker(formatPointList, index, endIndex, epsilon);

        // Build the result list
        resultList.push(...recResults1.slice(0, recResults1.length - 1), ...recResults2);
    } else {
        resultList.push(formatPointList[startIndex]);
        resultList.push(formatPointList[endIndex]);
    }
    // Return the result
    return resultList;
}

export function NormalizePoint(p) {
    if (typeof p.lat === 'function') {
        return {
            lat: p.lat(),
            lng: p.lng()
        };
    } else {
        return {
            lat: p.lat,
            lng: p.lng
        };
    }
}

export function FilterPlaceResults(placeResults) {
    let filtered = [];
    if (!placeResults || placeResults.length <= 1) {
        return placeResults;
    }
    placeResults.forEach((p, i) => {
        let wanted = false;
        // remove places that none of types is needed.
        p.types.forEach((t) => {
            if (IsTypeWanted(t)) wanted = true;
        });
        if (wanted) {
            // examine first types, if not wanted, discard.
            // this is to exclude some places we do not want to show.
            let type0 = p.types[0];
            if (!IsTypeWanted(type0)) wanted = false;
        }
        if (wanted) {
            filtered.push(p);
        } else {
        }
    });
    return filtered;
}

function IsTypeWanted(type) {
    if (type === undefined || type === null) {
        return false;
    }
    if (type in GOOGLE_MAP_PLACE_TYPES_1) {
        return GOOGLE_MAP_PLACE_TYPES_1[type];
    }
    if (type in GOOGLE_MAP_PLACE_TYPES_2) {
        return GOOGLE_MAP_PLACE_TYPES_2[type];
    }
    console.log("Google Map Type Not Found: " + type);
    return false;
}

// definition of place types:
// https://github.com/googlemaps/google-maps-services-java/blob/main/src/main/java/com/google/maps/model/AddressType.java
export const GOOGLE_MAP_PLACE_TYPES_1 = {
    "accounting": false,
    "airport": true,
    "amusement_park": true,
    "aquarium": true,
    "art_gallery": true,
    "atm": false,
    "bakery": true,
    "bank": true,
    "bar": true,
    "beauty_salon": false,
    "bicycle_store": false,
    "book_store": true,
    "bowling_alley": true,
    "bus_station": false,
    "cafe": true,
    "campground": true,
    "car_dealer": false,
    "car_rental": true,
    "car_repair": false,
    "car_wash": false,
    "casino": true,
    "cemetery": true,
    "church": true,
    "city_hall": true,
    "clothing_store": false,
    "convenience_store": true,
    "courthouse": true,
    "dentist": false,
    "department_store": true,
    "doctor": true,
    "drugstore": true,
    "electrician": true,
    "electronics_store": true,
    "embassy": true,
    "fire_station": true,
    "florist": true,
    "funeral_home": false,
    "furniture_store": true,
    "gas_station": true,
    "gym": false,
    "hair_care": false,
    "hardware_store": true,
    "hindu_temple": true,
    "home_goods_store": true,
    "hospital": true,
    "insurance_agency": true,
    "jewelry_store": false,
    "laundry": false,
    "lawyer": false,
    "library": true,
    "light_rail_station": true,
    "liquor_store": true,
    "local_government_office": true,
    "locksmith": true,
    "lodging": true,
    "meal_delivery": true,
    "meal_takeaway": true,
    "mosque": true,
    "movie_rental": true,
    "movie_theater": true,
    "moving_company": false,
    "museum": true,
    "night_club": true,
    "painter": true,
    "park": true,
    "parking": false,
    "pet_store": false,
    "pharmacy": false,
    "physiotherapist": false,
    "plumber": true,
    "police": true,
    "post_office": true,
    "primary_school": true,
    "real_estate_agency": false,
    "restaurant": true,
    "roofing_contractor": true,
    "rv_park": true,
    "school": true,
    "secondary_school": true,
    "shoe_store": false,
    "shopping_mall": true,
    "spa": true,
    "stadium": true,
    "storage": true,
    "store": true,
    "subway_station": true,
    "supermarket": true,
    "synagogue": true,
    "taxi_stand": true,
    "tourist_attraction": true,
    "train_station": true,
    "transit_station": true,
    "travel_agency": true,
    "university": true,
    "veterinary_care": true,
    "zoo": true,
};

export const GOOGLE_MAP_PLACE_TYPES_2 = {
    "administrative_area_level_1": true,
    "administrative_area_level_2": true,
    "administrative_area_level_3": true,
    "administrative_area_level_4": true,
    "administrative_area_level_5": true,
    "archipelago": true,
    "colloquial_area": true,
    "continent": true,
    "country": true,
    "establishment": false, // typically indicates a place that has not yet been categorized
    "finance": false,
    "floor": true,
    "food": true,
    "general_contractor": false,
    "geocode": true,
    "health": true,
    "intersection": true,
    "landmark": true,
    "locality": false, // indicates an incorporated city or town political entity.
    "natural_feature": true,
    "neighborhood": false,
    "place_of_worship": true,
    "plus_code": true,
    "point_of_interest": true, // a named point of interest. Typically, these "POI"s are prominent local entities that don't easily fit in another category, such as "Empire State Building" or "Statue of Liberty."
    "political": false, // A political entity. Usually, this type indicates a polygon of some civil administration.
    "post_box": true,
    "postal_code": true,
    "postal_code_prefix": true,
    "postal_code_suffix": true,
    "postal_town": true,
    "premise": true,
    "room": true,
    "route": true,
    "street_address": true,
    "street_number": true,
    "sublocality": false,
    "sublocality_level_1": false,
    "sublocality_level_2": false,
    "sublocality_level_3": false,
    "sublocality_level_4": false,
    "sublocality_level_5": false,
    "subpremise": true,
    "town_square": true,
};
