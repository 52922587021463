import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import './ProfileSetup.css';
import ReactTooltip from "react-tooltip";
import { CreateThumbnail } from '../helper/image.js';
import CloseButton from 'react-bootstrap/CloseButton'
import ProfilePhotoComponent from '../common/ProfilePhotoComponent';

/*
    Full Name - email, fb
    Username - email
    Email - email, fb
    Password - email
    DoB - email
    profilePhoto - fb

    view: postRegisterByEmail | postRegisterByFacebook | updateProfile

    postRegisterByEmail: set up profile photo
    postRegisterByFacebook: set up username, DOB, confirm full name
    updateProfile:
    - if registered by social network, then do not show PW.
    - else show PW
*/
const ProfileSetupComponent = (props) => {
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [profile, setProfile] = useState({});
    const [changedProfilePhoto, setChangedProfilePhoto] = useState(null);
    const [changedUsername, setChangedUsername] = useState();
    const [changedFullName, setChangedFullName] = useState();

    const [changedDoBYear, setChangedDoBYear] = useState(0);
    const [changedDoBMonth, setChangedDoBMonth] = useState(0);
    const [changedDoBDay, setChangedDoBDay] = useState(0);

    const [submitStatus, setSubmitStatus] = useState();

    const [fileUploadStatus, setFileUploadStatus] = useState();
    const [dataUpdateStatus, setDataUpdateStatus] = useState();

    const [validationError, setValidationError] = useState();

    const profilePhotoUploadRef = useRef(null)

    useEffect(() => {
        axios.get("/api/user/me", {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("access_token")
            }
        })
            .then(resp => {
                let res = resp.data;
                setProfile(res[0]);
                if (res[0].profilePhoto) {
                    // download profile photo
                    axios.get("/api/file?folder=profile&filename=" + res[0].profilePhoto, {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                        },
                        responseType: 'blob'
                    })
                        .then(resp => {
                            setProfilePhoto(resp.data);
                        })
                        .catch(error => {
                            console.log('There was an error while downloading profile photo!', error);
                        });
                }
            })
            .catch(error => {
                console.log('There was an error while getting current user profile!', error);
            });;
    }, []);

    useEffect(() => {
        if (submitStatus === "SUBMITTED") {
            const timeId = setTimeout(() => {
                setSubmitStatus();
                // auto-close profile update window.
                if (props.profileSetupView === "postRegisterByFacebook") {
                    props.onClickCloseProfileSetup();
                }
            }, 3000)
            return () => {
                clearTimeout(timeId);
            }
        }
    }, [submitStatus]);

    useEffect(() => {
        if ((fileUploadStatus === "NOCHANGE" || fileUploadStatus === "UPLOADED") && dataUpdateStatus === "UPDATED") {
            setSubmitStatus("SUBMITTED");
        }
    }, [fileUploadStatus, dataUpdateStatus]);

    useEffect(() => {

    }, [validationError]);

    function handleSubmitClick(e) {
        e.preventDefault();

        // validation
        if (props.profileSetupView === "postRegisterByFacebook") {
            if (!(changedDoBYear > 1900) || !(changedDoBMonth > 0) || !(changedDoBDay > 0)) {
                console.log(!(changedDoBYear > 1900) + " " + !(changedDoBMonth > 0) + " " + !(changedDoBDay > 0));
                setValidationError("Please populate date of birth.");
                return;
            } else {
                setValidationError();
            }
            // username not set
            if (changedUsername === undefined || changedUsername === null) {
                setValidationError("Please choose an username");
                return;
            } else if (changedUsername.length < 3 || changedUsername.length > 30) {
                setValidationError("Username must be between 3 and 30 characters");
                return;
            } else {
                setValidationError();
            }
        }

        setSubmitStatus("SUBMITTING");

        // save file
        if (changedProfilePhoto) {
            setFileUploadStatus("UPLOADING");
            let formData = new FormData();
            formData.append('folder', "profile");
            formData.append('file', changedProfilePhoto.file);
            formData.append('filename', changedProfilePhoto.fileName);
            axios.post(
                "/api/file",
                formData,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
                        "Content-Type": "multipart/form-data"
                    }
                }
            )
                .then(resp => {
                    setFileUploadStatus("UPLOADED");
                })
                .catch(error => {
                    console.log('There was an error while uploading new profile photo!', error);
                });
        } else {
            setFileUploadStatus("NOCHANGE");
        }

        // save metadata
        let metadataUpdate = {};
        if (changedProfilePhoto?.fileName) metadataUpdate["profilePhoto"] = changedProfilePhoto.fileName;
        if (changedUsername) metadataUpdate["username"] = changedUsername;
        if (changedFullName) metadataUpdate["fullName"] = changedFullName;
        // DoB setup
        if (changedDoBMonth > 0 && changedDoBDay > 0) {
            let dob = new Date(changedDoBYear ?? 1900, changedDoBMonth - 1, changedDoBDay);
            metadataUpdate["dateOfBirth"] = dob.toISOString();
        }

        if (Object.keys(metadataUpdate).length !== 0) {
            setDataUpdateStatus("UPDATING");
            axios.patch(
                "/api/user/me",
                metadataUpdate,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                    }
                }
            )
                .then(res => {
                    console.log("update successfully.");
                    setDataUpdateStatus("UPDATED");
                    setChangedProfilePhoto(null);
                    setChangedUsername();
                    setChangedFullName();
                })
                .catch(error => {
                    console.log('There was an error while updating user profile!', error);
                });
        }
    }

    function fileUploadAction() {
        console.log("fileUploadAction");
        profilePhotoUploadRef.current.click();
    }

    async function handleFileChange(e) {
        console.log("handleFileChange");
        console.log(e);
        var files = e.target.files;
        console.log(files);

        if (files && files.length > 0) {
            let file = files[0];

            //Read the contents of Image File to get width and height
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (loadedImage) {
                //Initiate the JavaScript Image object.
                var image = new Image();

                //Set the Base64 string return from FileReader as source.
                image.src = loadedImage.target.result;

                //Validate the File Height and Width.
                image.onload = async function () {
                    file.originalImageWidth = this.width;
                    file.originalImageHeight = this.height;

                    // shrink file
                    let shrinkedFile = (await CreateThumbnail(file, 400, "blob")).uri;

                    // put file in profile view
                    setProfilePhoto(shrinkedFile);

                    // generate file name
                    let timestamp = Math.round(new Date().getTime() / 1000);
                    let fileName = timestamp + "." + file.type.split('/')[1];
                    setChangedProfilePhoto({
                        file: shrinkedFile,
                        fileName: fileName
                    });
                    setSubmitStatus("PENDING");
                };
            };
        }
    }

    const onChangeUsername = ({ target: { value } }) => {
        setChangedUsername(value);
        setSubmitStatus("PENDING");
    }
    const onChangeFullName = ({ target: { value } }) => {
        setChangedFullName(value);
        setSubmitStatus("PENDING");
    }

    const onChangeDoBYear = ({ target: { value } }) => {
        setChangedDoBYear(parseInt(value));
        if (value > 1900) {
            setSubmitStatus("PENDING");
            setValidationError();
        }
    }
    const onChangeDoBMonth = ({ target: { value } }) => {
        setChangedDoBMonth(parseInt(value));
        if (value > 0) {
            setSubmitStatus("PENDING");
            setValidationError();
        }
    }
    const onChangeDoBDay = ({ target: { value } }) => {
        setChangedDoBDay(parseInt(value));
        if (value > 0) {
            setSubmitStatus("PENDING");
            setValidationError();
        }
    }

    /*
        view: postRegisterByEmail | postRegisterByFacebook | updateProfile

        postRegisterByEmail: set up profile photo
        postRegisterByFacebook: set up username, DOB, confirm full name
        updateProfile:
        - if registered by social network, then do not show PW.
        - else show PW
    */
    return (
        <div className="profile-setup">
            <h2>{props.profileSetupView === "postRegisterByEmail" || props.profileSetupView === "postRegisterByFacebook" || props.profileSetupView === "postRegisterMissingUserName" ? "Complete Profile" : "Profile Setting"}</h2>
            <div className="profile-setup-close-button">
                <CloseButton onClick={props.onClickCloseProfileSetup} />
            </div>
            <hr />
            <Form>
                <Form.Group as={Row} className="mb-3 align-items-center" controlId="profileFormProfilePhoto">
                    <Form.Label column sm="3">Profile Photo</Form.Label>
                    <Col sm="4">
                        <div className="profile-avatar-container">
                            <ProfilePhotoComponent
                                profilePhoto={profilePhoto}
                                className="profile-avatar"
                            />
                            <div className="profile-setup-change-photo" onClick={fileUploadAction}>
                                <input type="file" hidden ref={profilePhotoUploadRef} onChange={handleFileChange} accept="image/*" />
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M4 21a1 1 0 0 0 .24 0l4-1a1 1 0 0 0 .47-.26L21 7.41a2 2 0 0 0 0-2.82L19.42 3a2 2 0 0 0-2.83 0L4.3 15.29a1.06 1.06 0 0 0-.27.47l-1 4A1 1 0 0 0 3.76 21 1 1 0 0 0 4 21zM18 4.41 19.59 6 18 7.59 16.42 6zM5.91 16.51 15 7.41 16.59 9l-9.1 9.1-2.11.52z"></path></svg>
                            </div>
                        </div>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="profileFormUsername">
                    <Form.Label column sm="3">Username</Form.Label>
                    <Col sm="8">
                        {props.profileSetupView === "postRegisterByFacebook" || !profile.username
                            ? <Form.Control type="text" placeholder="Pick a username. Cannot be changed later" onChange={onChangeUsername} />
                            : <Form.Label column>{profile.username}</Form.Label>
                        }
                    </Col>
                </Form.Group>

                {profile.fullName && 
                    <Form.Group as={Row} className="mb-3" controlId="profileFormFullName">
                        <Form.Label column sm="3">Full Name</Form.Label>
                        <Col sm="8">
                            <Form.Label column>{profile.fullName}</Form.Label>
                        </Col>
                    </Form.Group>
                }

                {profile.email &&
                    <Form.Group as={Row} className="mb-3" controlId="profileFormEmail">
                        <Form.Label column sm="3">Email</Form.Label>
                        <Col sm="8">
                            <Form.Label column>{profile.email}</Form.Label>
                            <svg data-tip="Cannot be changed" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="exclamation-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                            <ReactTooltip place="top" type="dark" effect="solid" />
                        </Col>
                    </Form.Group>
                }

                {props.profileSetupView === "postRegisterByFacebook"
                    && <Form.Group as={Row} className="mb-2" controlId="profileFormDoB">
                        <Col sm="3">
                            <Form.Label column>Date Of Birth</Form.Label>
                            <svg data-tip="Will not be displayed to anyone" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="exclamation-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                            <ReactTooltip place="top" type="dark" effect="solid" />
                        </Col>
                        <Col sm="8">
                            <Row className="mb-2">
                                <Col>
                                    <select name="dobmm" className="form-control" onChange={onChangeDoBMonth}>
                                        <option key="dobmm-0" value="0">Month</option>
                                        <option key="dobmm-1" value="1">Jan</option>
                                        <option key="dobmm-2" value="2">Feb</option>
                                        <option key="dobmm-3" value="3">Mar</option>
                                        <option key="dobmm-4" value="4">Apr</option>
                                        <option key="dobmm-5" value="5">May</option>
                                        <option key="dobmm-6" value="6">Jun</option>
                                        <option key="dobmm-7" value="7">Jul</option>
                                        <option key="dobmm-8" value="8">Aug</option>
                                        <option key="dobmm-9" value="9">Sep</option>
                                        <option key="dobmm-10" value="10">Oct</option>
                                        <option key="dobmm-11" value="11">Nov</option>
                                        <option key="dobmm-12" value="12">Dec</option>
                                    </select>
                                </Col>
                                <Col>
                                    <select name="dobdd" className="form-control" onChange={onChangeDoBDay}>
                                        <option key="dobdd-0" value="0">Day</option>
                                        {
                                            Array.from(new Array(31), (x, i) => i + 1).map(dd => {
                                                return <option key={"dobdd-" + dd} value={dd}>{dd}</option>;
                                            })
                                        }
                                    </select>
                                </Col>
                                <Col>
                                    <select name="dobyyyy" className="form-control" onChange={onChangeDoBYear}>
                                        <option key="dobyyyy-0" value="0">Year</option>
                                        {
                                            Array.from(new Array(120), (x, i) => 2021 - i).map(yyyy => {
                                                return <option key={"dobyyyy-" + yyyy} value={yyyy}>{yyyy}</option>;
                                            })
                                        }
                                    </select>
                                </Col>
                            </Row>
                        </Col>
                    </Form.Group>
                }

                {validationError && validationError.length > 0 &&
                    <div className="profile-setup-validation-error">{validationError}</div>
                }
                <Form.Group as={Row} className="mb-2">
                    <Col sm={{ span: 9, offset: 3 }} className="submit-row">
                        <button className="submit-row-button" type="submit" disabled={submitStatus !== "PENDING"} onClick={handleSubmitClick}>Save</button>
                        {
                            submitStatus === "SUBMITTING"
                                ? <Spinner animation="border" variant="success" className="submitting" />
                                :
                                submitStatus === "SUBMITTED"
                                    ?
                                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                    </svg>
                                    : null
                        }
                    </Col>
                </Form.Group>
            </Form>
        </div>
    );
};
export default ProfileSetupComponent;
