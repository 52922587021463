import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import './form.css';
import ReactTooltip from "react-tooltip";


const RegisterForm = (props) => {
    return (
        <Formik
            initialValues={
                {
                    username: '',
                    fullname: '',
                    dobmm: 0,
                    dobdd: 0,
                    dobyyyy: 0,
                    email: '',
                    password: ''
                }
            }
            onSubmit={
                values => {
                    console.log("validation passed, submitting ...");
                    console.log(values);
                    props.handleRegisterEvent(values);
                }
            }
            validationSchema={Yup.object({
                username: Yup.string()
                    .min(3, 'Username is too short')
                    .max(30, 'Username is too long')
                    .required('Required'),
                fullname: Yup.string()
                    .min(2, 'Full name is required')
                    .required('Required'),
                dobmm: Yup.number()
                    .min(1, 'Select A Month'),
                dobdd: Yup.number()
                    .min(1, 'Select A Day'),
                dobyyyy: Yup.number()
                    .min(1900, 'Select A Year'),
                email: Yup.string()
                    .email('Invalid email address')
                    .required('Required'),
                password: Yup.string()
                    .min(8, 'Must be 8 characters or more')
                    .required('Required'),
            })}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {(formik) => (
                <Form>
                    <div className="mb-1">
                        <label className="login-register-form-label">Full Name</label>
                        <Field name="fullname" placeholder="Enter your name" type="text" className="form-control" />
                        {formik.touched.fullname && formik.errors.fullname ? (
                            <div className="login-register-error-message">{formik.errors.fullname}</div>
                        ) : null}
                    </div>

                    <div className="mb-1">
                        <label className="login-register-form-label">Username</label>
                        <Field name="username" placeholder="Choose a username" type="text" className="form-control" />
                        {formik.touched.username && formik.errors.username ? (
                            <div className="login-register-error-message">{formik.errors.username}</div>
                        ) : null}
                    </div>

                    <div className="mb-1">
                        <label className="login-register-form-label">Email address</label>
                        <Field name="email" placeholder="Enter email" type="email" className="form-control" />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="login-register-error-message">{formik.errors.email}</div>
                        ) : null}
                    </div>

                    <div className="mb-3">
                        <label className="login-register-form-label">Password</label>
                        <Field name="password" placeholder="Password" type="password" className="form-control" />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="login-register-error-message">{formik.errors.password}</div>
                        ) : null}
                    </div>

                    <Row className="mb-1">
                        <Col className="form-register-dob">
                            <label className="login-register-form-label">Date Of Birth</label>
                            <svg data-tip="Will not be displayed to anyone" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                            </svg>
                            <ReactTooltip place="top" type="dark" effect="solid"/>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Field name="dobmm" as="select" className="form-control">
                                <option key="dobmm-0" value="0">Select Month</option>
                                <option key="dobmm-1" value="1">Jan</option>
                                <option key="dobmm-2" value="2">Feb</option>
                                <option key="dobmm-3" value="3">Mar</option>
                                <option key="dobmm-4" value="4">Apr</option>
                                <option key="dobmm-5" value="5">May</option>
                                <option key="dobmm-6" value="6">Jun</option>
                                <option key="dobmm-7" value="7">Jul</option>
                                <option key="dobmm-8" value="8">Aug</option>
                                <option key="dobmm-9" value="9">Sep</option>
                                <option key="dobmm-10" value="10">Oct</option>
                                <option key="dobmm-11" value="11">Nov</option>
                                <option key="dobmm-12" value="12">Dec</option>
                            </Field>
                            {formik.touched.dobmm && formik.errors.dobmm ? (
                                <div className="login-register-error-message">{formik.errors.dobmm}</div>
                            ) : null}
                        </Col>
                        <Col>
                            <Field name="dobdd" as="select" className="form-control">
                                <option key="dobdd-0" value="0">Select Day</option>
                                {
                                    Array.from(new Array(31), (x, i) => i + 1).map(dd => {
                                        return <option key={"dobdd-" + dd} value={dd}>{dd}</option>;
                                    })
                                }
                            </Field>
                            {formik.touched.dobdd && formik.errors.dobdd ? (
                                <div className="login-register-error-message">{formik.errors.dobdd}</div>
                            ) : null}
                        </Col>
                        <Col>
                            <Field name="dobyyyy" as="select" className="form-control">
                                <option key="dobyyyy-0" value="0">Select Year</option>
                                {
                                    Array.from(new Array(120), (x, i) => 2021 - i).map(yyyy => {
                                        return <option key={"dobyyyy-" + yyyy} value={yyyy}>{yyyy}</option>;
                                    })
                                }
                            </Field>
                            {formik.touched.dobyyyy && formik.errors.dobyyyy ? (
                                <div className="login-register-error-message">{formik.errors.dobyyyy}</div>
                            ) : null}
                        </Col>
                    </Row>

                    <div className="col text-center">
                        <button className="unregistered-form-button" type="submit">Register</button>
                    </div>
                </Form>)}
        </Formik>
    );
};
export default RegisterForm;