import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Landing.css';
import HomeComponent from './HomeComponent';
import UnregisteredComponent from './UnregisteredComponent';

const animationDuration = 200;

/*
    handles everything on the landing page, regardless of user is logged in or not.
    - initialize FB, Google library
    - check FB login status
 */
const LandingComponent = (props) => {
    const [hasAccessToken, setHasAccessToken] = useState(null);
    const [fbToken, setFbToken] = useState(null);
    const [loggedIn, setLoggedIn] = useState(null);
    const [pathActiveClass, setPathActiveClass] = useState("");
    const [animationComplete, setAnimationComplete] = useState(false);
    const [profileSetupView, setProfileSetupView] = useState(null);

    /*
        on page load:
        - show animation
        - check if there is access token. if so, try login. if not, stay on login page.
        - try to renew access token
        - load facebook sdk, check login status.
        - load Google sign in js
    */
    useEffect(() => {
        console.log("mounted");
        addAnimation();

        // Load the SDK asynchronously
        (function (d, s, id) {
            console.log("Loading the SDK asynchronously...");
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        // check FB login status
        window.fbAsyncInit = function () {
            console.log("FB.init()...");
            window.FB.init({
                appId: '151350358347579',
                //cookie : true, // enable cookies to allow the server to access the session
                xfbml: true, // parse social plugins on this page
                version: 'v11.0'
            });
            checkFbLogin();
        };

        // first check if access token and refresh token exists
        // if so, try to renew token
        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
            // refresh access token
            async function tryRenewAccessToken() {
                const refreshedToken = await axios.get('/api/auth/renew-token', {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                });
                const newAccessToken = refreshedToken.data.accessToken;
                if (newAccessToken) {
                    // store access token in local storage
                    localStorage.setItem("access_token", newAccessToken);
                    setHasAccessToken(true);
                } else {
                    setHasAccessToken(false);
                }
            }
            tryRenewAccessToken();
        } else {
            setHasAccessToken(false);
        }
    }, []);

    useEffect(() => {
        if (hasAccessToken === true) {
            setLoggedIn(true);
        } else if (hasAccessToken === false) {
            setLoggedIn(false);
        }
    }, [hasAccessToken]);

    /*
        if token already exists, do nothing
        if no token, then check FB login status
        - if logged in, set FBToken
        - if not logged in, do nothing
    */
    function checkFbLogin() {
        console.log("checkFbLogin");
        if (fbToken?.data_access_expiration_time && fbToken.data_access_expiration_time > new Date().getTime() / 1000) {
            console.log("token is valid");
            console.log(fbToken);
        } else {
            window.FB.getLoginStatus(function (response) {
                console.log(response);
                if (response.status === 'connected') {
                    setFbToken(response.authResponse); // fb is connected and save fb token to state
                }
            }, true); // send true to force a call, to avoid status being cached.
        }
    }

    function onClickFacebookLogout() {
        console.log("onClickFacebookLogout");
        if (fbToken?.data_access_expiration_time && fbToken.data_access_expiration_time > new Date().getTime() / 1000) {
            window.FB.logout(function (response) {
                // Person is now logged out
                console.log(response);
                setFbToken(null);
            });
        }
        // if logged into google, sign out

        onLogout();
    }

    /*
        remove access token from localstorage
        cookie should be unset from logout API response
        reset all state
    */
    function onLogout() {
        fetch("/api/user/logout", {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("access_token")
            },
        })
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((res) => {
                localStorage.removeItem("access_token");
                return res.json();
            })
            .catch(error => {
                console.log('There was an error!', error);
            });
        setHasAccessToken(null);
        setLoggedIn(false);
    }

    function onLoginFormSuccessful(data) {
        console.log("onLoginFormSuccessful");
        if (data.accessToken) {
            localStorage.setItem("access_token", data.accessToken);
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }

    function onRegisterFormSuccessful(profileSetupView, data) {
        console.log("onRegisterFormSuccessful");
        console.log(data);
        localStorage.setItem("access_token", data.accessToken);
        setProfileSetupView(profileSetupView);
        setLoggedIn(true);
    }

    function addAnimation() {
        setPathActiveClass("loading-animation-active");
        setTimeout(() => {
            setAnimationComplete(true);
        }, animationDuration)
    }

    if ((animationComplete === false) || (loggedIn === null)) {
        return (<div className="landingPage">
            <div className="loading-animation">
                <svg id="landing-loading-animation"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="310.62"
                    height="108.26"
                    viewBox="188.122 228.21 314.619 112.256"
                >
                    <defs>
                        <path
                            className={pathActiveClass}
                            id="l2qHjZpmVs"
                            d="M194.74,229.21C191.75,286.12 189.87,321.69 189.12,335.92C189.04,337.52 191.22,338.08 191.91,336.63C199.48,320.95 219.46,279.53 227.13,263.61C228.23,261.35 231.42,261.26 232.63,263.47C239.85,276.62 258.26,310.14 265.4,323.12C267.11,326.25 271.28,326.98 273.96,324.63C283.17,316.54 307.22,295.43 316.84,286.99C318.74,285.31 321.48,284.98 323.73,286.16C333.74,291.37 361.59,305.88 371.98,311.29C373.12,311.89 374.43,312.06 375.68,311.78C392.22,308.1 433.57,298.91 499.74,284.21"
                            strokeWidth="2"
                        ></path>
                    </defs>
                    <use fillOpacity="0" stroke="#FF0000" xlinkHref="#l2qHjZpmVs"></use>
                </svg>
            </div>
        </div>)
    } else {
        return (loggedIn !== null && animationComplete &&
            (loggedIn
                ? <HomeComponent onClickLogout={onClickFacebookLogout} profileSetupView={profileSetupView} />
                : <UnregisteredComponent
                    fbToken={fbToken}
                    setFbToken={setFbToken}
                    setLoggedIn={setLoggedIn}
                    onLoginFormSuccessful={onLoginFormSuccessful}
                    onRegisterFormSuccessful={onRegisterFormSuccessful}
                />))
    }
};
export default LandingComponent;