import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Button, Col, CloseButton } from 'react-bootstrap';
import './draft.css';

const DraftComponent = (props) => {
    const searchAreaRef = useRef(null);
    const [inputTitle, setInputTitle] = useState('');
    const [inputLocation, setInputLocation] = useState('');

    const escFunction = useCallback((event) => {
        // esc
        if(event.keyCode === 27) {
            console.log("esc clicked");
            handleDiscardEvent();
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    });

    function handleShareEvent () {
        console.log("handleShareEvent: " + inputTitle + " " + inputLocation);
        if (props.handleSaveRouteEvent) {
            props.handleSaveRouteEvent(inputTitle, inputLocation);
        }
    }

    function handleDiscardEvent () {
        console.log("handleDiscardEvent");
        props.navigationButton("HOME");
    }

    return (
        <div className="draft-view">
            <div className="draft-view-header">
                <CloseButton className="draft-view-header-discard" onClick={handleDiscardEvent}/>
                <span className="draft-view-header-title">Share a new route</span>
                <Button variant="primary" className="draft-view-button" onClick={handleShareEvent}>Share</Button>
            </div>
            <div className="draft-view-info">
                <div className="draft-view-row">
                    <Col xs={1} className="draft-view-col">
                        <svg className="draft-view-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" ><path d="M19 4h-3V2h-2v2h-4V2H8v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM5 20V7h14V6l.002 14H5z"></path><path d="M7 9h10v2H7zm0 4h5v2H7z"></path></svg>
                    </Col>
                    <Col xs={11}>
                        <input className="form-control" placeholder="Title" onInput={e => setInputTitle(e.target.value)} />
                    </Col>
                </div>
                {/* <div className="draft-view-row">
                    <Col xs={1} className="draft-view-col">
                        <svg className="draft-view-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" ><path d="M12 2C7.589 2 4 5.589 4 9.995 3.971 16.44 11.696 21.784 12 22c0 0 8.029-5.56 8-12 0-4.411-3.589-8-8-8zm0 12c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"></path></svg>
                    </Col>
                    <Col xs={11}>
                        <input className="form-control" placeholder="Primary location" onInput={e => setInputLocation(e.target.value)} />
                    </Col>
                </div> */}
            </div>
        </div>
    );
};
export default DraftComponent;
