import React, { useState, useEffect, useRef } from 'react';
import './ImageViewer.css';
import { Button, Col, CloseButton } from 'react-bootstrap';

/*
    open new image
    move to left/right image

    note: should rewrite this
    should load all images into html, instead of changing the same img tag with new src and witdh.
    changing src and width is difficult to make sure the change is in sync.
*/
const ImageViewerComponent = (props) => {
    const divElement = useRef(null);

    /*
        {
            width: calculated
            image: props.clickedMarker?.file?.originalImage
        }
    */
    const [imageToDisplay, setImageToDisplay] = useState(null);

    function escFunction(event) {
        if (event.keyCode === 27) {
            props.onCloseHandler();
        }
    }

    // when image is provided, calculate display width and height
    useEffect(() => {
        console.log("originalImage [props.displayImage, props.clickedMarker]");
        if (props.displayImage === true) {
            if (props.clickedMarker?.file?.originalImage) {
                document.addEventListener('keydown', escFunction);
                setImageToDisplay(props.clickedMarker?.file?.originalImage);
            }
        }

        return () => {
            // Note: everytime dependencies change, new function is created
            // So must remove listener here when clean up happens.
            document.removeEventListener('keydown', escFunction);
        };
    }, [props.displayImage, props.clickedMarker]);

    const draftLocationUpdated = ({ target: { value } }) => {
        if (props.draftMode) {
            props.handleDraftLocationSelected(props.clickedMarker, props.clickedMarker.placeResults[value]);
        }
    }

    const formatDateToString = (date) => {
        if (date === undefined || date === null) return "";
        if (!(date instanceof Date)) {
            date = new Date(date);
        }
        const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var year = date.getFullYear();
        var monthShortString = monthShortNames[date.getMonth()];
        var day = date.getDate();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = ('0' + minutes).slice(-2);
        return `${monthShortString} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
    }

    /*
        Note:
        - in demo mode, there is no timestamp.
        - in view mode, timestamp comes from props.clickedMarker?.media?.photo?.timestamp
        - in draft mode, timestamp comes from props.clickedMarker?.file?.datetime
    */
    return (
        <div
            className="image-viewer"
            ref={divElement}
        >
            {
                imageToDisplay &&
                <>
                    <div className="image-viewer-timestamp">
                        {formatDateToString(props.clickedMarker?.media?.photo?.createDate ?? props.clickedMarker?.file?.datetime)}
                    </div>
                    {props.draftMode === true &&
                        (props.clickedMarker?.placeResults && props.clickedMarker?.placeResults.length > 0
                            ?
                            <div className="image-viewer-header">
                                <div className="image-viewer-location">
                                    <svg className="image-viewer-location-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" ><path d="M12 2C7.589 2 4 5.589 4 9.995 3.971 16.44 11.696 21.784 12 22c0 0 8.029-5.56 8-12 0-4.411-3.589-8-8-8zm0 12c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"></path></svg>
                                    <select className="image-viewer-select" onChange={draftLocationUpdated} value={
                                        (props.clickedMarker.placeResult === undefined || props.clickedMarker.placeResult === null)
                                            ? 0
                                            : props.clickedMarker.placeResults.findIndex(p => p.place_id === props.clickedMarker.placeResult?.place_id)
                                    }>
                                        <option key="default" value={null}></option>
                                        {props.clickedMarker.placeResults.map((r, i) => <option key={r.place_id} value={i}>{r.name}</option>)}
                                    </select>
                                </div>
                            </div>
                            :
                            <div className="image-viewer-header-no-content"></div>
                        )}

                    {props.draftMode === false &&
                        (props.clickedMarker?.placeResult?.name
                            ?
                            <div className="image-viewer-header">
                                <div className="image-viewer-location">
                                    <svg className="image-viewer-location-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" ><path d="M12 2C7.589 2 4 5.589 4 9.995 3.971 16.44 11.696 21.784 12 22c0 0 8.029-5.56 8-12 0-4.411-3.589-8-8-8zm0 12c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"></path></svg>
                                    <span>{props.clickedMarker.placeResult.name}</span>
                                </div>
                            </div>
                            :
                            <div className="image-viewer-header-no-content"></div>
                        )}

                    <div
                        className="image-viewer-original-image"
                        style={{
                            backgroundImage: "url(" + imageToDisplay + ")"
                        }}
                    ></div>
                </>
            }

            <div className="image-viewer-close-button">
                <CloseButton variant="white" onClick={props.onCloseHandler} />
            </div>

            {props.onClickImageArrowHandler &&
                <>
                    <div className="image-viewer-left-button" onClick={() => props.onClickImageArrowHandler(props.clickedMarker, -1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16">
                            <path d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </div>
                    <div className="image-viewer-right-button" onClick={() => props.onClickImageArrowHandler(props.clickedMarker, 1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16">
                            <path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </>
            }
        </div>
    )

}
export default ImageViewerComponent;