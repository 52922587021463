import React, { Component } from 'react';
import ImageMega from './ImageUploadComponent';
import ImageViewer from './ImageViewerComponent';
import './Home.css';
import { Loader } from '@googlemaps/js-api-loader';
import { Button, Container, Dropdown, InputGroup, FormControl, Form } from 'react-bootstrap';
import { Tween, update, Easing } from "@tweenjs/tween.js";
import { NearestPointOnPath, DouglasPeucker, NormalizePoint } from './helper/calculation.js'
import HeaderComponent from './common/HeaderComponent';
import GoogleMapComponent from './GoogleMapComponent';
import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import MarkerThumbnailComponent from './MarkerThumbnailComponent'
import { CreateFileFromLocal } from './helper/image.js'


// API loading parameters
const loader = new Loader({
    apiKey: "AIzaSyDCHOO41I21tKSMCzpoXI0Aizz39epDs2g",
    version: "beta",
    mapIds: ["1a9316535142ffae"],
    libraries: ["geometry", "places"]
});


const londonDemo = [
    {
        name: "London Eye",
        lat: 51.5032982,
        lng: -0.1201549,
        image: "londonEye.jpeg"
    },
    {
        name: "Big Ben",
        lat: 51.5007325,
        lng: -0.1267877,
        image: "bigBen.jpeg"

    },
    {
        name: "Buckingham Palace",
        lat: 51.5013673,
        lng: -0.1440787,
        image: "buckinghamPalace.jpeg"
    },
    {
        name: "Trafalgar Square",
        lat: 51.5080917,
        lng: -0.1291379,
        image: "r1.jpeg"
    },
    {
        name: "Tower of London",
        lat: 51.5081157,
        lng: -0.078138,
        image: "r2.jpeg"
    },
    {
        name: "Tower Bridge",
        lat: 51.5054594,
        lng: -0.0773435,
        image: "towerBridge.jpeg"
    }
];

const newYorkDemo = [
    {
        name: "?",
        lat: 40.762312,
        lng: -73.979345,
        image: ""
    }
];

const parisDemo = {
    init: {
        lat: 48.8571973,
        lng: 2.3416429,
        zoom: 13
    },
    points: [
        {
            name: "Eiffel Tower",
            lat: 48.8539241,
            // lng: 2.2913515,
            lng: 2.2713515,
            image: "/demo/london/bigBen.jpeg",
            originalImageWidth: 960,
            originalImageHeight: 636,
        },
        {
            name: "Arc de Triomphe",
            lat: 48.8737952,
            lng: 2.2928388,
            image: "/demo/london/londonEye.jpeg",
            originalImageWidth: 960,
            originalImageHeight: 636,
        },
        {
            name: "Louvre Museum",
            lat: 48.8606146,
            lng: 2.3354553,
            image: "/demo/london/buckinghamPalace.jpeg",
            originalImageWidth: 960,
            originalImageHeight: 636,
        }
    ]
};

const initialState = {
    photos: [],
    directionsRenderers: [],
    markers: [],
    file: null,
    displayImage: false,
    rdpPoints: [],
    rdpEpsilon: 0.01,
    rdpMarkers: [],
    rdpPolylines: []
};

const defaultMapOptions = {
    center: {
        lat: parisDemo.init.lat,
        lng: parisDemo.init.lng,
    },
    tilt: 45,
    heading: 0,
    zoom: parisDemo.init.zoom,
    mapId: "1a9316535142ffae",
    disableDefaultUI: true,
};

export default class DemoComponent extends Component {
    // defaultProps is Component class properties.
    // this.props.center
    static defaultProps = defaultMapOptions;

    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleAnimateEvent = this.handleAnimateEvent.bind(this);
        this.handleDebugEvent = this.handleDebugEvent.bind(this);
        this.handleClearEvent = this.handleClearEvent.bind(this);
        this.renderDirectionResult = this.renderDirectionResult.bind(this);
    }

    componentDidMount() {
        let self = this;
        loader.load().then((google) => {
            const map = new google.maps.Map(
                self.googleMapDiv,
                defaultMapOptions);
            this.setState({
                loaded: true,
                map: map,
                maps: google.maps,
                google: google,
                directionsService: new google.maps.DirectionsService
            });
            map.addListener("zoom_changed", () => {
                self.setState({zoomLevel: map.getZoom() + " " + map.getTilt() + " " + map.getHeading()});
            });
            map.addListener("heading_changed", () => {
                self.setState({zoomLevel: map.getZoom() + " " + map.getTilt() + " " + map.getHeading()});
            });

            // const webglOverlayView = new google.maps.WebglOverlayView();
            // let scene, renderer, camera, loader;
            // webglOverlayView.onAdd = () => {
            //     scene = new THREE.Scene();
            //     // camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
            //     camera = new THREE.PerspectiveCamera();
            //     const ambientLight = new THREE.AmbientLight( 0xffffff, 0.75 );
            //     scene.add(ambientLight);
            //     const directionalLight = new THREE.DirectionalLight(0xffffff, 0.25);
            //     directionalLight.position.set(0.5, -1, 0.5);
            //     scene.add(directionalLight);

            //     // loader = new GLTFLoader();
            //     // const source = "pin.gltf";
            //     // loader.load(
            //     //     source,
            //     //     gltf => {
            //     //         gltf.scene.scale.set(25,25,25);
            //     //         gltf.scene.rotation.x = 180 * Math.PI/180;
            //     //         scene.add(gltf.scene);
            //     //     }
            //     // );

            //     // loader = new THREE.ImageLoader();
            //     // loader.load(
            //     //     "beachflag.png",
            //     //     function ( image ) {
            //     //         console.log("loaded image")
            //     //         // use the image, e.g. draw part of it on a canvas
            //     //         const canvas = document.createElement( 'canvas' );
            //     //         const context = canvas.getContext( '2d' );
            //     //         context.drawImage( image, 100, 100 );
            //     //     },
            //     //     undefined,
            //     //     function () {
            //     //         console.error( 'unable to load image using ImageLoader.' );
            //     //     }
            //     // );

            //     const geometry = new THREE.BoxGeometry(1000, 100, 300);
            //     // const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
            //     const loader = new THREE.TextureLoader();
            //     let frontTexture = loader.load('beachflag.png');
            //     let backTexture = loader.load('beachflag.png');
            //     backTexture.flipY = false;
            //     // const material = new THREE.MeshBasicMaterial( { map: texture } );
            //     const materials = [
            //         new THREE.MeshBasicMaterial({ color: 0xffffff }), // right
            //         new THREE.MeshBasicMaterial({ color: 0xffffff }), // left
            //         new THREE.MeshBasicMaterial({map: backTexture}), // back
            //         new THREE.MeshBasicMaterial({map: frontTexture}), // front
            //         new THREE.MeshBasicMaterial({ color: 0xffffff }), // top
            //         new THREE.MeshBasicMaterial({ color: 0xffffff }), // bottom, no one will see
            //     ];
                
            //     const cube = new THREE.Mesh( geometry, materials );
                
            //     scene.add( cube );

            // };
            // webglOverlayView.onContextRestored = (gl) => {
            //     renderer = new THREE.WebGLRenderer({
            //         canvas: gl.canvas,
            //         context: gl,
            //         ...gl.getContextAttributes(),
            //     });
            //     renderer.autoClear = false;

            //     // loader.manager.onLoad = () => {
            //     //     renderer.setAnimationLoop(() => {
            //     //         map.moveCamera({
            //     //             "tilt": defaultMapOptions.tilt,
            //     //             "heading": defaultMapOptions.heading,
            //     //             "zoom": defaultMapOptions.zoom
            //     //         });
            //     //         if (defaultMapOptions.tilt < 67.5) {
            //     //             defaultMapOptions.tilt += 0.5
            //     //         } else if (defaultMapOptions.heading <= 360) {
            //     //             defaultMapOptions.heading += 0.2;
            //     //         } else {
            //     //             renderer.setAnimationLoop(null)
            //     //         }
            //     //     });
            //     // }
            // };
            // webglOverlayView.onDraw = (gl, transformer) => {
            //     const matrix = transformer.fromLatLngAltitude(defaultMapOptions.center, 120);
            //     camera.projectionMatrix = new THREE.Matrix4().fromArray(matrix);

            //     webglOverlayView.requestRedraw();
            //     renderer.render(scene, camera);
            //     renderer.resetState();
            // };
            // webglOverlayView.setMap(map);
            // initWebglOverlayView(map);

            self.handleTest1Event();
        });
    }

    // same as Home
    handleAnimateEvent () {
        console.log("old draw polyline handleAnimateEvent");
        const self = this;
        
        var directionsRoute = this.state.lastRouteResponse.routes[0];
        var overview_path = directionsRoute.overview_path;
        // var overview_polyline = directionsRoute.overview_polyline;

        const lineSymbol = {
            path: this.state.maps.SymbolPath.CIRCLE,
            scale: 8,
            strokeColor: "#393",
        };

        var polylines = [];

        for (var i = 0; i < overview_path.length - 1; i = i + 1) {
            var currPolyline = new this.state.maps.Polyline({
                path: [overview_path[i], overview_path[i + 1]],
                geodesic: true,
                strokeColor: '#FFA500',
                strokeWeight: 5
            });
            polylines.push(currPolyline);
        }

        var polylineIndex = 0;
        var myFunction = function() {
            polylines[polylineIndex].setMap(self.state.map);
            polylineIndex++;

            if (polylineIndex < polylines.length) {
                setTimeout(myFunction, 20);
            }
        }
        setTimeout(myFunction, 20);
    }

    handleAnimate1Event = () => {
        console.log("handleAnimate1Event");
        this.googleMapJs.animate(this.state.rdpPoints);
    }

    handleAnimate2Event = () => {
        console.log("NOT USED handleAnimate2Event");

        var lastRouteResp = this.state.lastRouteResponse;
        console.log(lastRouteResp);

        var originalRequest = lastRouteResp.request;
        console.log("=== originalRequest ===");

        var reqOrigin = originalRequest.origin.location;
        var reqWps = [];
        console.log("start: " + reqOrigin.lat() + " " + reqOrigin.lng());
        originalRequest.waypoints.forEach(w => {
            reqWps.push(w.location.location);
            console.log("wp: " + w.location.location.lat() + " " + w.location.location.lng());
        });
        var reqDestination = originalRequest.destination.location;
        console.log("end: " + reqDestination.lat() + " " + reqDestination.lng());


        console.log("=== overview_path ===");
        var directionsRoute = lastRouteResp.routes[0];
        var overview_path = directionsRoute.overview_path.map(p => {
            if (typeof p.lat === 'function') {
                return {
                    lat: p.lat(),
                    lng: p.lng()
                };
            } else {
                return {
                    lat: p.lat,
                    lng: p.lng
                };
            }
        });

        if (this.state.rdpPoints && this.state.rdpPoints.length > 0) {
            console.log("USING  RDP points.");
            overview_path = this.state.rdpPoints;
        }

        console.log(overview_path);
        var overview_pathLen = overview_path.length;
        var overview_pathOrigin = overview_path[0];
        var overview_pathWps = [];
        var overview_pathDestination = overview_path[overview_pathLen-1];
        console.log("start: " + overview_pathOrigin.lat + " " + overview_pathOrigin.lng);

        for (var i = 1; i < overview_pathLen-1; i++) {
            var overviewPathCurrWp = overview_path[i];
            overview_pathWps.push(overviewPathCurrWp);
        }
        console.log("end: " + overview_pathDestination.lat + " " + overview_pathDestination.lng);

        var findNearest = NearestPointOnPath (overview_pathWps, reqWps[0]);
        console.log(findNearest);
        var wp1onPath = overview_pathWps[findNearest];
        console.log(wp1onPath.lat + " " + wp1onPath.lng);
        console.log(reqWps[0].lat() + " " + reqWps[0].lng());
        // var overview_polyline = directionsRoute.overview_polyline;

        var start = lastRouteResp.request.origin.location;
        var end = lastRouteResp.request.destination.location;

        var currZoom = this.state.map.getZoom();

        var destinationZoom = Math.min(18, Math.ceil(currZoom + 2));
        console.log(currZoom + " => " + destinationZoom);


        // 1. animate to START
        var currTilt = this.state.map.getTilt() ?? 0;
        var currHeading = this.state.map.getHeading() ?? 0;
        var currZoom = this.state.map.getZoom();
        var currCenter = this.state.map.getCenter();
        var currCameraOption = {
            tilt: currTilt,
            heading: currHeading,
            zoom: currZoom,
            center: {
                lat: currCenter.lat(),
                lng: currCenter.lng()
            },
        };
        console.log(currCameraOption);

        var startTilt = 45; // need calculation
        var startHeading = 45; // need to be heading from START to the next point.
        var startZoom = destinationZoom; // need calculation to smooth among all points
        var startCenter = start;
        var startCameraOption = {
            tilt: startTilt,
            heading: startHeading,
            zoom: startZoom,
            center: {
                lat: startCenter.lat(),
                lng: startCenter.lng()
            },
        };
        console.log(startCameraOption);

        // this.animateFromAToB(currCameraOption, startCameraOption, 5000);


        // 2. animate A to B loop
        console.log("=== start animation from start to wps ===");
        this.animatePath (overview_path, 0, findNearest, 30000);

        // 3 optional animate from END
    }

    handleDebugEvent = () => {
        console.log(this.state);
        console.log({
            tilt: this.state.map.getTilt(),
            heading: this.state.map.getHeading(),
            zoom: this.state.map.getZoom(),
            center: {
                lat: this.state.map.getCenter().lat(),
                lng: this.state.map.getCenter().lng()
            }
        });
    }

    handleClearEvent ()
    {
        this.state.directionsRenderers.map(function(dr){
            dr.setMap(null);
        });
        this.state.markers.map(function(m){
            m.setMap(null);
        });
        this.setState(prevState => (initialState));
    }

    renderDirectionResult (directionsRenderer, response) {
        var overview_path = response.routes[0].overview_path;
        new this.state.maps.Polyline({
            path: overview_path,
            geodesic: true,
            strokeColor: '#FFA500',
            strokeWeight: 4,
            strokeOpacity: 0.5,
            map: this.state.map
        });
        // directionsRenderer.setDirections(response);

        // for testing purpose. put marker on each point on overview_path
        let points = overview_path.map(p => NormalizePoint(p));
        console.log("beginning total points: " + points.length);
        let result = DouglasPeucker(points, 0, overview_path.length - 1, this.state.rdpEpsilon);

        console.log(result);

        this.setState({
            lastRouteResponse: response,
            rdpPoints: result
        });
        // store directionsRenderer to state, so we can use it in other area.
        this.setState(prevState => ({
            directionsRenderers: [...prevState.directionsRenderers, directionsRenderer] // ...prevState is a clone of the array
        }));
    }

    onImageViewerClose = (e) => {
        console.log("click closed");
        this.setState({ displayImage: false });
    }

    onSelectDropdown = (k, e) => {
        console.log(k);
        console.log(e);
        this.state.map.setMapTypeId(k);
    }

    handleRotateEvent = () => {
        const heading = this.state.map.getHeading() || 0;
        this.state.map.setHeading(heading + 45);

        // test tokyo
        // const cameraOptions = {
        //     tilt: 0,
        //     heading: 0,
        //     zoom: 14,
        //     center: { lat: 35.6594945, lng: 139.6999859 },
        // };
        // this.state.map.moveCamera(cameraOptions);
    }

    /*
        each point needs to have:
        - tile
        - heading
        - zoom
        - center: {lat: , lng: }
    */
    animateFromAToB = (a, b, totalDuration) => {
        console.log(a);
        console.log(b);
        console.log(totalDuration);

        let self = this;
        new Tween(a) // Create a new tween that modifies 'cameraOptions'.
            .to(b, totalDuration) // Move to destination in 15 second.
            .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
            .onUpdate(() => {
                self.state.map.moveCamera(a);
            })
            .start(); // Start the tween immediately.
        
        let start = Date.now();
        requestAnimationFrame(function animate(timestamp) {
            let interval = Date.now() - start;
            if (interval < totalDuration) {
                requestAnimationFrame(animate);
                update(timestamp);
            }
        });
    }

    animatePath (overview_path, pathStart, pathEnd, totalDuration) {
        console.log(overview_path);
        console.log(pathStart + " " + pathEnd + " " + totalDuration);

        let self = this;
        let totalLength = 0;
        let animationPointsAndDuration = [];

        var currPosMarker = new this.state.maps.Marker({
            position: {lat: 0, lng: 0},
            map: this.state.map,
            icon: {
                path: this.state.maps.SymbolPath.CIRCLE,
                scale: 8,
                strokeColor: "#393"
            }
        });
        let currZoom = this.state.map.getZoom();
        let currTilt = this.state.map.getTilt();
        let currHeading = this.state.map.getHeading();
        for (let i = pathStart; i < pathEnd; i++) {
            let pathAtI = overview_path[i];
            let pathAtINext = overview_path[i + 1];
            console.log(pathAtI.lat + " " +  pathAtI.lng + " - " + pathAtINext.lat + " " + pathAtINext.lng);
            let pathLengthSquared = 10000000 * Math.sqrt(Math.pow(pathAtI.lat - pathAtINext.lat, 2) + Math.pow(pathAtI.lng - pathAtINext.lng, 2));
            totalLength += pathLengthSquared;

            let currAnimationStart = {
                lat: pathAtI.lat,
                lng: pathAtI.lng
            };
            let currAnimationEnd = {
                lat: pathAtINext.lat,
                lng: pathAtINext.lng,
            };
            // heading need to be set as the END point's heading, because we are moving from current camera position to the END camera position.
            let headingAtNext = this.state.maps.geometry.spherical.computeHeading(
                new this.state.maps.LatLng(pathAtI.lat, pathAtI.lng),
                new this.state.maps.LatLng(pathAtINext.lat, pathAtINext.lng)
            );
            let currAnimationTilt = 60;
            let nextAnimationTilt = 60;
            let currAnimationZoom = Math.min(18, Math.ceil(currZoom + 1));
            let nextAnimationZoom = Math.min(18, Math.ceil(currZoom + 1));
            let currAnimationHeading = headingAtNext;
            let nextAnimationHeading = headingAtNext;
            if (i === pathStart) {
                currAnimationTilt = currTilt;
                currAnimationZoom = currZoom;
                currAnimationHeading = currHeading;
            } else if (i === pathEnd - 1) {
                nextAnimationTilt = currTilt;
                nextAnimationZoom = currZoom;
                nextAnimationHeading = currHeading;
                // if this is not starting point, then start heading is the previous location's end heading
                currAnimationHeading = animationPointsAndDuration[i-1].end.heading;
            } else {
                // if this is not starting point, then start heading is the previous location's end heading
                currAnimationHeading = animationPointsAndDuration[i-1].end.heading;
            }

            // heading correction, so we dont move camera heading by 180 degree
            if (Math.abs(currAnimationHeading - nextAnimationHeading) > 180) {
                if (currAnimationHeading > 0) {
                    currAnimationHeading -= 360;
                } else if (currAnimationHeading < 0) {
                    currAnimationHeading += 360;
                }
            }
            animationPointsAndDuration.push({
                start: {
                    location: currAnimationStart,
                    lat: pathAtI.lat,
                    lng: pathAtI.lng,
                    tilt: currAnimationTilt,
                    zoom: currAnimationZoom,
                    heading: currAnimationHeading 
                },
                end: {
                    location: currAnimationEnd,
                    lat: pathAtINext.lat,
                    lng: pathAtINext.lng,
                    tilt: nextAnimationTilt,
                    zoom: nextAnimationZoom,
                    heading: nextAnimationHeading
                },
                length: pathLengthSquared
            });
        }
        let time = totalDuration / totalLength;
        for (let i = pathStart; i < pathEnd; i++) {
            animationPointsAndDuration[i]["time"] = time * animationPointsAndDuration[i]["length"];
        }

        console.log(animationPointsAndDuration);

        let startingCameraPosition = {
            tilt: this.state.map.getTilt(),
            heading: this.state.map.getHeading() ?? 0,
            zoom: this.state.map.getZoom(),
            center: {
                lat: this.state.map.getCenter().lat(),
                lng: this.state.map.getCenter().lng(),
            },
        };
        let index1CameraPositoin = {
            tilt: this.state.map.getTilt(),
            heading: this.state.map.getHeading() ?? 0,
            zoom: this.state.map.getZoom(),
            center: {
                lat: animationPointsAndDuration[0].end.lat,
                lng: animationPointsAndDuration[0].end.lng,
            }
        };
        let startingTween = new Tween(startingCameraPosition) // Create a new tween that modifies 'cameraOptions'.
            .to(index1CameraPositoin, 2000) // Move to destination in 15 second.
            .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
            .onUpdate(() => {
                self.state.map.moveCamera(startingCameraPosition);
                console.log("starting animation");
            })
            .onComplete(() => {
                console.log("starting animation is completed.");
            });
        

        // create tweens
        let tweens = [];
        for (let i = 0; i < animationPointsAndDuration.length; i = i + 1) {
            let startPoint = animationPointsAndDuration[i].start;
            let endPoint = animationPointsAndDuration[i].end;
            let startCameraPosition = {
                tilt: startPoint.tilt,
                heading: startPoint.heading,
                zoom: startPoint.zoom,
                center: {
                    lat: startPoint.lat,
                    lng: startPoint.lng,
                }
            };
            let endCameraPosition = {
                tilt: endPoint.tilt,
                heading: endPoint.heading,
                zoom: endPoint.zoom,
                center: {
                    lat: endPoint.lat,
                    lng: endPoint.lng,
                }
            };

            let tweenCurr = new Tween(startCameraPosition)
                .to(endCameraPosition, animationPointsAndDuration[i].time) // Move to destination in 15 second.
                .easing(Easing.Linear.None) // Use an easing function to make the animation smooth.
                .onUpdate((currCameraPos) => {
                    self.state.map.moveCamera(startCameraPosition);
                    currPosMarker.setPosition(currCameraPos.center);
                })
                .onComplete((z) => {
                    console.log(i + " animation is completed.");
                    console.log(z)
                });
            tweens.push(tweenCurr);


            // if (i == 1) {
            //     console.log(i);
            //     console.log(animationPointsAndDuration[i].start.lat + " " + animationPointsAndDuration[i].start.lng);
            //     console.log(animationPointsAndDuration[i+10].end.lat + " " + animationPointsAndDuration[i+10].end.lng);
            //     var cameraB = {
            //         tilt: this.state.map.getTilt(),
            //         heading: this.state.map.getHeading() ?? 0,
            //         zoom: this.state.map.getZoom(),
            //         center: {
            //             lat: animationPointsAndDuration[i].start.lat,
            //             lng: animationPointsAndDuration[i].start.lng,
            //         }
            //     };
            //     tweenB = new Tween(cameraB)
            //         .to({
            //             tilt: this.state.map.getTilt(),
            //             heading: this.state.map.getHeading() ?? 0,
            //             zoom: this.state.map.getZoom(),
            //             center: {
            //                 lat: animationPointsAndDuration[i+10].end.lat,
            //                 lng: animationPointsAndDuration[i+10].end.lng,
            //             }
            //         }, 2000) // Move to destination in 15 second.
            //         .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
            //         .onUpdate(() => {
            //             self.state.map.moveCamera(cameraB);
            //         })
            //     ;
            //     tweens.push(tweenB);
            // }

            // if (i == 11){
            //     console.log(i+1);
            //     console.log(animationPointsAndDuration[i+1].start.lat + " " + animationPointsAndDuration[i+1].start.lng);
            //     console.log(animationPointsAndDuration[100].end.lat + " " + animationPointsAndDuration[100].end.lng);
            //     var cameraC = {
            //         tilt: this.state.map.getTilt(),
            //         heading: this.state.map.getHeading() ?? 0,
            //         zoom: this.state.map.getZoom(),
            //         center: {
            //             lat: animationPointsAndDuration[i+1].start.lat,
            //             lng: animationPointsAndDuration[i+1].start.lng,
            //         }
            //     };
            //     tweenC = new Tween(cameraC)
            //         .to({
            //             tilt: this.state.map.getTilt(),
            //             heading: this.state.map.getHeading() ?? 0,
            //             zoom: this.state.map.getZoom(),
            //             center: {
            //                 lat: animationPointsAndDuration[100].end.lat,
            //                 lng: animationPointsAndDuration[100].end.lng,
            //             }
            //         }, 2000) // Move to destination in 15 second.
            //         .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
            //         .onUpdate(() => {
            //             self.state.map.moveCamera(cameraC);
            //         })
            //     ;
            //     tweens.push(tweenC);
            // }
            
        }
        console.log("there are total : " + tweens.length);
        startingTween.chain(tweens[0]);

        for (let i = 0; i < tweens.length - 1; i++) {
            tweens[i].chain(tweens[i+1]);
        }
        

        startingTween.start();
        requestAnimationFrame(function animate(timestamp) {
            requestAnimationFrame(animate);
            update(timestamp);
        });
    }

    handleTest1Event = () => {
        console.log("handleTest1Event");
        let self = this;
        // var points = [
        //     // {lat: 0, lng: 0},
        //     // {lat: 2, lng: 1},
        //     // {lat: 0, lng: 2},
        //     // {lat: 1, lng: 3},
        //     // {lat: 0, lng: 4},
        //     // {lat: 3, lng: 5},
        //     // {lat: 0, lng: 6},
        //     {lat: 37.38398, lng: -121.97113},
        //     {lat: 37.37483, lng: -121.93280},
        //     {lat: 37.36487, lng: -121.90392},
        //     {lat: 37.36374, lng: -121.90125},
        //     {lat: 37.36327, lng: -121.90106},
        //     {lat: 37.36322, lng: -121.90194},
        //     {lat: 37.36549, lng: -121.90123},
        //     {lat: 37.37128, lng: -121.90175},
        //     {lat: 37.37148, lng: -121.90177},
        //     {lat: 37.37484, lng: -121.90259}
        // ];
        var points = parisDemo.points.map(l => {
            return {
                lat: l.lat,
                lng: l.lng
            }
        });
        // this.state.map.setCenter({lat: points[0].lat, lng: points[0].lng});
        // this.state.map.setCenter({lat: 1, lng: 2});
        // this.state.map.setZoom(5);
        // this.state.map.setTilt(0);

        let bounds = new this.state.maps.LatLngBounds();
        for (let i = 0; i < parisDemo.points.length; i++) {
            bounds.extend(parisDemo.points[i]);
            CreateFileFromLocal (
                parisDemo.points[i].image,
                parisDemo.points[i].name, 
                "image/jpeg",
                parisDemo.points[i].originalImageWidth,
                parisDemo.points[i].originalImageHeight,
                function(newFile){
                    self.putMarkerOnMap(newFile, parisDemo.points[i]);
                }
            );
        }

        // fitBounds will change tilt = 0. so need to immediately set tilt back to original.
        let beforeTilt = this.state.map.getTilt();
        this.state.map.fitBounds(bounds);
        this.state.map.setTilt(beforeTilt);

        this.googleMapJs.route(
            points[0],
            points[points.length - 1],
            points.slice(1, points.length - 1).map(w => { return { location: w };})
        );
    }

    putMarkerOnMap = (file, location) => {
        console.log(file);
        console.log(location);
        let marker = {
            location: location,
            file: file
        }
        this.setState(prevState => ({
            markers: [...prevState.markers, marker]
        }));
    }

    handleTest2Event = () => {
        console.log("handleTest2Event");
        var points = [
            // {lat: 37.38398, lng: -121.97113},
            {lat: 37.37483, lng: -121.93280},
            {lat: 37.36487, lng: -121.90392},
            {lat: 37.36374, lng: -121.90125},
            {lat: 37.36327, lng: -121.90106},
            {lat: 37.36322, lng: -121.90194},
            {lat: 37.36549, lng: -121.90123},
            {lat: 37.37128, lng: -121.90175},
            {lat: 37.37148, lng: -121.90177},
            // {lat: 37.37484, lng: -121.90259}
        ];
        this.animatePath (points, 0, points.length - 1, 30000);
    }


    handleMoveEvent = () => {
        let self = this;
        console.log("handleMoveEvent");

        var currCameraOption = {
            tilt: this.state.map.getTilt(),
            heading: this.state.map.getHeading() ?? 0,
            zoom: this.state.map.getZoom(),
            center: {
                lat: this.state.map.getCenter().lat(),
                lng: this.state.map.getCenter().lng(),
            },
        };
        console.log(currCameraOption);

        var destinationCameraOption = {
            tilt: 65,
            heading: 90,
            zoom: 16,
            center: {
                lat: 40.802312,
                lng: -73.939345
            },
        }
        console.log(destinationCameraOption);


        // test tokyo
        // const cameraOptions = {
        //     tilt: 0,
        //     heading: 0,
        //     zoom: 10,
        //     center: { lat: 35.6594945, lng: 139.6999859 },
        // };
        // new Tween(cameraOptions) // Create a new tween that modifies 'cameraOptions'.
        // .to({ tilt: 65, heading: 90, zoom: 18 }, 15000) // Move to destination in 15 second.
        // .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        // .onUpdate(() => {
        //     self.state.map.moveCamera(cameraOptions);
        // })
        // .start();

        var tweenA = new Tween(currCameraOption) // Create a new tween that modifies 'cameraOptions'.
            .to(destinationCameraOption, 3000) // Move to destination in 15 second.
            .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
            .onUpdate(() => {
                self.state.map.moveCamera(currCameraOption);
            }); // Start the tween immediately.
        
        var tweenB = new Tween(currCameraOption) // Create a new tween that modifies 'cameraOptions'.
            .to({
                tilt: 30,
                heading: 45,
                zoom: 14,
                center: {
                    lat: 40.762312,
                    lng: -73.979345
                }
            }, 3000) // Move to destination in 15 second.
            .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
            .onUpdate(() => {
                self.state.map.moveCamera(currCameraOption);
            }); // Start the tween immediately.
        
        tweenA.chain(tweenB);
        tweenA.start();
        
        let start = Date.now();
        requestAnimationFrame(function animate(timestamp) {
            let interval = Date.now() - start;
            // if (interval < 3000) {
                requestAnimationFrame(animate);
                update(timestamp); // update is a tween function
            // }
        });
    }

    handleRDPEvent = () => {
        console.log("handleRDPEvent");
        console.log(this.state.rdpEpsilon);        
        var lastRouteResp = this.state.lastRouteResponse;
        var overview_path = lastRouteResp.routes[0].overview_path;

        let points = overview_path.map(p => NormalizePoint(p));
        console.log("beginning total points: " + points.length);
        let result = DouglasPeucker(points, 0, overview_path.length - 1, this.state.rdpEpsilon);
        console.log(result);

        let rdpMarkers = [];
        let rdpPolylines = [];
        let rdpMarkerStart = new this.state.maps.Marker({
            position: {lat: result[0].lat, lng: result[0].lng},
            map: this.state.map,
            icon: {
                path: this.state.maps.SymbolPath.CIRCLE,
                scale: 8,
                strokeColor: "#e28743"
            }
        });
        rdpMarkers.push(rdpMarkerStart);
        let rdpMarkerEnd = new this.state.maps.Marker({
            position: {lat: result[result.length-1].lat, lng: result[result.length-1].lng},
            map: this.state.map,
            icon: {
                path: this.state.maps.SymbolPath.CIRCLE,
                scale: 8,
                strokeColor: "#e28743"
            }
        });
        rdpMarkers.push(rdpMarkerEnd);
        // for (let i = 0; i < this.state.rdpMarkers.length; i++) {
        //     this.state.rdpMarkers[i].setMap(null);
        // }
        // for (let i = 0; i < this.state.rdpPolylines.length; i++) {
        //     this.state.rdpPolylines[i].setMap(null);
        // }
        // for (var i = 0; i < result.length - 1; i = i + 1) {
        //     var resultPoint = result[i];
        //     var resultPointNext = result[i+1];

        //     let rdpMarker = new this.state.maps.Marker({
        //         position: {lat: resultPoint.lat, lng: resultPoint.lng},
        //         map: this.state.map,
        //         icon: {
        //             path: this.state.maps.SymbolPath.CIRCLE,
        //             scale: 4,
        //             strokeColor: "#e28743"
        //         }
        //     });
        //     rdpMarkers.push(rdpMarker);
        //     let rdpPolyline = new this.state.maps.Polyline({
        //         path: [resultPoint, resultPointNext],
        //         geodesic: true,
        //         strokeColor: '#FFA500',
        //         strokeWeight: 3,
        //         map: this.state.map
        //     });
        //     rdpPolylines.push(rdpPolyline);
        // }
        this.setState({
            rdpPoints: result,
            rdpMarkers: rdpMarkers,
            rdpPolylines: rdpPolylines
        });
    }

    handleResetEvent = () => {
        console.log("handleResetEvent");
        let resetMapOption = {
            tilt: 45,
            heading: 0,
            zoom: 14,
            center: defaultMapOptions.center,
        };
        this.state.map.moveCamera(resetMapOption);
    }


    handleEpsilonChangeEvent = (e) => {
        this.setState({ rdpEpsilon: e.target.value });
    }

    handleMarkerClick = (e) => {
        console.log("clicked");
    }
    

    render() {
        return (
            <>
                <div className="main-view-header">
                    <HeaderComponent />
                </div>
                <div className="main-view">
                    <div
                        style={{ height: '100vh', width: '100%' }}
                        ref={(ref) => { this.googleMapDiv = ref }}
                    >
                        {this.state.map && <GoogleMapComponent
                            ref={(ref) => { this.googleMapJs = ref }}
                            map={this.state.map}
                            maps={this.state.maps}
                            directionsService={this.state.directionsService}
                            renderDirectionResult={this.renderDirectionResult}
                        />}
                    </div>
                    {this.state.markers.map((m, index) => {
                        return <MarkerThumbnailComponent
                            key={`#marker-${index}`}
                            marker={m}
                            map={this.state.map}
                            maps={this.state.maps}
                            handleMarkerClick={this.handleMarkerClick}
                        />;
                    })}
                </div>

                <div className="test-buttons">
                    <Button variant="secondary" onClick={this.handleDebugEvent}>Debug</Button>
                    <Button variant="secondary" onClick={this.handleClearEvent}>Clear</Button>
                    <Button variant="secondary" onClick={this.handleAnimateEvent}>Animate</Button>
                    <Button variant="secondary" onClick={this.handleAnimate1Event}>Animate1</Button>
                    <Button variant="secondary" onClick={this.handleTest1Event}>Test1</Button>
                    <Button variant="secondary" onClick={this.handleTest2Event}>Test2</Button>
                    <Button variant="secondary" onClick={this.handleRDPEvent}>RDP</Button>
                    <InputGroup className="mb-3">
                        <Form.Control
                            name="name"
                            ref={(ref) => { this.epsilonTest = ref }}
                            onChange={this.handleEpsilonChangeEvent}
                        />
                    </InputGroup>

                    
                    <Container >

                        <Dropdown onSelect={(k, e) => this.onSelectDropdown(k, e)}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Dropdown Button
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="roadmap">roadmap</Dropdown.Item>
                                <Dropdown.Item eventKey="satellite">satellite</Dropdown.Item>
                                <Dropdown.Item eventKey="hybrid">hybrid</Dropdown.Item>
                                <Dropdown.Item eventKey="terrain">terrain (no)</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <p>zoom level: {this.state.zoomLevel}</p>
                        <Button variant="secondary" onClick={this.handleRotateEvent}>Rotate</Button>
                        <Button variant="secondary" onClick={this.handleMoveEvent}>Move</Button>
                        <Button variant="secondary" onClick={this.handleResetEvent}>Reset</Button>
                    </Container>
                    
                </div>

                {this.state.displayImage &&
                    <div className="left-imageViewer" >
                        <ImageViewer
                            originalImage={this.state.file?.originalImage}
                            originalImageWidth={this.state.file?.originalImageWidth}
                            originalImageHeight={this.state.file?.originalImageHeight}
                            display={this.state.displayImage}
                            onCloseHandler={this.onImageViewerClose}
                        />
                    </div>
                }
            </>
        )
    }
}