import React, { useCallback, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import './searchFriend.css';
import ProfilePhotoComponent from '../common/ProfilePhotoComponent';

const SearchFriendComponent = (props) => {
    const searchAreaRef = useRef(null);
    const searchInputRef = useRef(null);
    const [searchResult, setSearchResult] = useState(null);
    const [friendshipStatus, setFriendshipStatus] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);

    const escFunction = useCallback((event) => {
        // esc
        if(event.keyCode === 27) {
            handleEscEvent();
        }
        // enter
        if (event.keyCode === 13) {
            handleSearchEvent(event);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    });

    useEffect(() => {
        if (searchResult) {
            if (searchResult.profilePhoto) {
                axios.get(
                    "/api/file?folder=profile&filename=" + searchResult.profilePhoto + "&userId=" + searchResult.userId,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                        },
                        responseType: 'blob'
                    }
                )
                .then(res => {
                    setProfilePhoto(res.data);
                })
                .catch(error => {
                    console.log('There was an error while fetching profile photo!', error);
                });
            } else {
                setProfilePhoto(null);
            }
        }
    }, [searchResult]);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (searchAreaRef.current && !searchAreaRef.current.contains(event.target)) {
                handleEscEvent();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchAreaRef]);

    // note: event is the keydown event.
    function handleSearchEvent(event) {
        console.log("handle search click");
        // trigger search API call
        if (event?.target?.value && event.target.value.length >= 3) {
            axios.post(
                "/api/user/friend/search",
                {
                    "username": event.target.value
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                    }
                }
            )
            .then(res => {
                let data = res.data;
                if (data.username) {
                    setSearchResult(data);
                    setFriendshipStatus(data.currStatus);
                    searchInputRef.current.blur();
                } else {
                    setSearchResult(null);
                    setFriendshipStatus(null);
                }
            })
            .catch(error => {
                console.error('There was an error while searching for user!', error);
                setSearchResult(null);
                setFriendshipStatus(null);
            });
        } else {
            setSearchResult(null);
            setFriendshipStatus(null);
        }
    }

    function handleEscEvent () {
        // clear input text
        if (searchInputRef.current && searchInputRef.current.value) {
            searchInputRef.current.value = ''
            setSearchResult(null);
            setFriendshipStatus(null);
        }

        // if parent passes in a close handler, call it
        if (props.onCloseHandler) {
            props.onCloseHandler();
        }
    }

    function handleFriendInvitationEvent (type) {
        console.log("handleFriendInvitationEvent - " + type);
        if (searchResult.userId && type) {
            axios.post(
                "/api/user/friend/request",
                {
                    "friendId": searchResult.userId,
                    "type": type
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                    }
                }
            )
            .then(res => {
                let newStatus = "";
                if (type === "REQUEST") newStatus = "REQUESTING";
                else if (type === "ACCEPT") newStatus = "CONFIRMED";
                else if (type === "IGNORE" || type === "DELETE") newStatus = "NOT_FRIEND";
                setFriendshipStatus(newStatus);
            })
            .catch(error => {
                console.log('There was an error while submitting friendship request!', error);
            });
        }
    }

    return (
        <div className="search-friend-container" ref={searchAreaRef}>
            <div className="search-friend-search-bar">
                <input autoFocus className="search-friend-search-bar-input" placeholder="Search by username" enterkeyhint="search" ref={searchInputRef} />
                <div className="search-friend-search-bar-icon-area" onClick={handleSearchEvent}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="search-friend-search-bar-icon" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg>
                </div>
            </div>

            {searchResult && <div className="search-friend-result">
                <div className="search-friend-profile-avatar-container">
                    <ProfilePhotoComponent
                        profilePhoto={profilePhoto}
                        className="search-friend-profile-avatar"
                    />
                </div>
                <h1>{searchResult.username}</h1>
                {searchResult.friendsCount && <div>{searchResult.friendsCount} friend{searchResult.friendsCount > 1 ? 's' : ''}</div>}
                {searchResult.commonFriendsCount && <div>{searchResult.commonFriendsCount} mutual friend{searchResult.commonFriendsCount > 1 ? 's' : ''}</div>}

                {friendshipStatus === "NOT_FRIEND" && <Button variant="primary" onClick={() => handleFriendInvitationEvent("REQUEST")}>Add Friend</Button>}
                {friendshipStatus === "REQUESTED" &&
                <div>
                    <Button className="search-friend-reaction-buttons" variant="primary" onClick={() => handleFriendInvitationEvent("ACCEPT")}>Accept</Button>
                    <Button className="search-friend-reaction-buttons" variant="secondary" onClick={() => handleFriendInvitationEvent("IGNORE")}>Ignore</Button>
                </div>
                }
                {friendshipStatus === "CONFIRMED" &&
                <div>
                    <Button className="search-friend-reaction-buttons" variant="primary" disabled>Friends</Button>
                    <Button className="search-friend-reaction-buttons" variant="danger" onClick={() => handleFriendInvitationEvent("DELETE")}>Unfriend</Button>
                </div>
                }
                {friendshipStatus === "REQUESTING" && <Button variant="primary" disabled>Request sent</Button>}
            </div>}
        </div>
    );
};
export default SearchFriendComponent;
