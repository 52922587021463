/*
    1. check if file is jpeg: start with FFD8?
    2. if jpeg, then
        address tag     type    count
            0   0xffd8  SOI
            2   0xffe1  App1    15288
        15292   
        ...
                0xffda  SOS
    
    
    
    3. if not jpeg, then if heic
        0   00000028  66747970  68656963  00 00 00 00  6D696631 4D694845 4D695072 6D696166 4D694842  68 65 69 63 
            <l = 40>   f t y p   h e i c  <minor brand> m i f 1  M i H E  M i P r  m i a f  M i H B  h  e  i  c
        40  0000126A 6D657461 00000000
            <l=4714>  m e t a <version>
        52      00000022 68646C72  00000000  00000000  70 69 63 74  00 00 00 00  00 00 00 00  00 00 00 00  00 00
                <l  =34>  h d l r  <version> < flags>   p  i  c  t
                hdlr is Handler Box, declares the media (handler) type, which is "pict"
        86      00000024 64696E66
                <l  =36>  d i n f
        94          0000  001C 64 72  65 66 00 00  00000000  00 01
                    <lengt=28>  d  r   e  f
                        0000000C 7572 6C20 0000 0001
                        <l  =12>  u r  l ...
         122    0000000E 7069746D 00 00  00 00 00 31
                <l  =14>  p i t m
         136    000004A4 69696E66 00000000 0036
                <l=1188>  i i n f <versio> <E#> = Number of Entries = 54
         150            00000015 696E6665 02000001 00010000 68766331 00
                        <len=21>  i n f e <V >flag <ID>      h v c l
         1221           00000029 696E6665 02000001 00340000 6D696D65 00617070 6C696361 74696F6E 2F726466 2B786D6C 00
                        <len=41>  i n f e <V >flag <ID>      m i m e    a p p  l i c a  t i o n  / r d f  + x m l
        * 1262 *        00000015 696E6665 02000001 00350000 45786966 00
                        <len=21>  i n f e <V >flag <ID>      E x i f
         1283           00000029 696E6665 02000001 00360000 6D696D65 00617070 6C696361 74696F6E 2F726466 2B786D6C 00
                        <len=41>  i n f e <V >flag <ID>      m i m e    a p p  l i c a  t i o n  / r d f  + x m l

        1324    000000BE 69726566 00000000 0000006C ....
                <l =190>  i r e f 
        1514    00000928 69707270 000007D9 6970636F 00000230 636F
                <l=2344>  i p r p
        3858    00000010 69646174 00000507 ...
                <l = 16>  i d a t
        3874    00000370 696C6F63 01000000  4400          0036 
                <l =880>  i l o c <FullBox> offset-length <E#> = Number of Entries = 54
        3890            0001 00000000 0001 0000 7431 0000 7A39 
                        0002 00000000 0001 0000 EE6A 0000 8EEC
                        0003 00000000 0001 0001 7D56 0000 8E73
                        ...
                        0035 00000000 0001 0000 595F       0000 0A9C
                        <ID=53>                 index=22879     size=2716
                        0036 00000000 0001 0000 63FB 0000 1036
                        <ID> <CM><DRI><OFF><EXC>     <??> 
                        ID = item_ID
                        CM = construction_method
                        DRI = data_reverence_index
                        OFF = base_offset
                        EXC = extent_count


        4754    00000001 6D646174 00000000 001A ... 
                          m d a t
        22879   00000006 45786966 0000 4D4D 002A 0000 0008
                          E x i f       M M           <index of IFD (Image File Directory)>

        22897   000B <11 entries>(2-byte count of the number of entries)
                <tag type><field type>  <count of type><value/offset> (offset is counting from 4D4D (MM), i.e. 22889)
        22899   010F      0002 Ascii    00000006(6)    00000092(146)   Exif.Image.Make                 23035: 41(A) 70(p) 70(p) 6C(l) 65(e) 00 
        22911   0110      0002 Ascii    00000012(18)   00000098(152)   Exif.Image.Model                23041: 69(i) 50(P) 68(h) 6F(o) 6E(n) 65(e) 20( ) 31(1) 33(3) 20( ) 50(P) 72(r) 6F(o) 20( ) 4D(M) 61(a) 78(x) 00
        22923   0112      0003 Short    00000001(1)    00010000(65536) Exif.Image.Orientation          '1' means upper left, '3' lower right, '6' upper right, '8' lower left, '9' undefined.
        22935   011A      0005 Rational 00000001(1)    000000AA(170)   Exif.Image.XResolution          23059: 00000048 00000001 => 72/1
        22947   011B      0005 Rational 00000001(1)    000000B2(178)   Exif.Image.YResolution          23067: 00000048 00000001 => 72/1
        22959   0128      0003 Short    00000001(1)    00020000(131072)Exif.Image.ResolutionUnit       Unit of XResolution(0x011a)/YResolution(0x011b). '1' means no-unit, '2' means inch, '3' means centimeter.
        22971   0131      0002 Ascii    00000007(7)    000000BA(186)   Exif.Image.Software             23075: 31352E31 2E3100 => 15.1.1 
        22983   0132      0002 Ascii    00000014(20)   000000C2(194)   Exif.Image.DateTime             23083: 32303231 3A31323A 32322031 373A3038 3A313100 => 2021:12:22 17:08:11 
        22995   013C      0002 Ascii    00000012(18)   000000D6(214)   Exif.Image.HostComputer         23103: 6950686F 6E652031 33205072 6F204D61 7800 => iPhone 13 Pro Max 
        23007   8769      0004 Long     00000001(1)    000000E8(232)   Exif.Image.ExifTag              23121 (=22899 + 232)
        23019   8825      0004 Long     00000001(1)    00000980(2432)  Exif.Image.GPSTag               25321 (=22899 + 2432)
        23031   00000000 <end of IFD>
                note: if field type is larger than 4 bytes, then value/offset is offset.
        23035   41 70 70 6C 65 00 Apple
        23041   iphone...
        23059   00000048 00000001
        23067   00000048 00000001
        23075   31352E31 2E310000
        23083   32303231 3A31323A 32322031 373A3038 3A313100
        23103   6950686F 6E652031 33205072 6F204D61 7800
        
        23121   ExifTag SubIFD
                0021 <33 entries>
        23123   829A    0005 Rational      00000001     0000027A(634)   ExposureTime            23523 = 22889 + 634
        23135   829D    0005 Rational      00000001     00000282(642)   FNumber                 23531
        23147   8822    0003 Short         00000001     00020000        ExposureProgram
        23159   8827    0003 Short         00000001     01900000        ISOSpeedRatings
        23171   9000    0007 undefined     00000004     30323332        ExifVersion
        23183   9003    0002 Ascii         00000014(20) 0000028A(650)   DateTimeOriginal        23539
                9004    0002 Ascii         00000014(20) 0000029E(670)   DateTimeDigitized       23559
        23207   9010    0002 Ascii         00000007     000002B2(690)                           23579
                9011    0002 Ascii         00000007     000002BA(698)                           23587
        23231   9012    0002 Ascii         00000007     000002C2(706)                           23595
                9201    000A signedRational00000001     000002CA(714)   ShutterSpeedValue       23603
        23255   9202    0005 Rational      00000001     000002D2(722)   ApertureValue           23611
                9203    000A signedRational00000001     000002DA        BrightnessValue
        23279   9204    000A signedRational00000001     000002E2        ExposureBiasValue
                9207    0003 Short         00000001     00050000        MeteringMode
        23303   9209    0003 Short         00000001     00100000        Flash
                920A    0005 Rational      00000001     000002EA        FocalLength
        23327   9214    0003 Short         00000004     000002F2        SubjectLocation
                927C    0007 undefined     0000062E     000002FA        MakerNote
        23351   9291    0002 Ascii         00000004     35303800        SubSecTimeOriginal
                9292    0002 Ascii         00000004     35303800        SubSecTimeDigitized
        23375   A001    0003 Short         00000001     FFFF0000        ColorSpace
                A002    0004 Long(4 byte)  00000001     00000FC0(4032)  ExifImageWidth
        23399   A003    0004 Long(4 byte)  00000001     00000BD0(3024)  ExifImageHeight
                A217    0003 Short         00000001     00020000        SensingMethod
        23423   A301    0007 undefined     00000001     01000000  A402 0003 00000001 00000000  A403 0003 00000001 00000000  A405 0003 00000001 001A0000  A432    0005 Rational      00000004     00000928
                A433    0002 Ascii         00000006     00000948(2376)  ?       25265
        23495   A434    0002 Ascii         00000031(49) 0000094E(2382)  ?       25271
        23507   A460    0003 Short         00000001     00020000
        23519   00000000 <end of IFD>
                
        23523   00000001 0000002F       ExposureTime: 1/47
        23531   00000003 00000002       FNumber: 3/2
        23539   32303231 3A31323A 32322031 373A3038 3A313100    DateTimeOriginal: 2021:12:22 17:08:11
        23559   32303231 3A31323A 32322031 373A3038 3A313100    DateTimeDigitized: 2021:12:22 17:08:11
        23579   2D30383A 30300000       9010 ?
        23587   2D30383A 30300000       9011 ?
        23595   2D30383A 30300000       9012 ?
        23603   0001AA4D 00004CE6       ShutterSpeedValue
        23611   00006C77 00005CB6       ApertureValue: 27767/23734
                00008528 0000CC29 00000000 00000001 00000039 0000000A 067C0432 00FC00FA 4170706C 6520694F 53000001 4D4D0031 00010009 00000001 0000000E 00020007 0000022E 00000260 00030007 00000068 0000048E 00040009 00000001 00000001 00050009 00000001 000000BB 00060009 00000001 000000BA 00070009 00
        ...
        25265   4170706C 6500 Apple
        25271   6950686F 6E652031 33205072 6F204D61 78206261 636B2074 7269706C 65206361 6D657261 20352E37 6D6D2066 2F312E35 0000
                 i P h o  n e   1  3   P r  o   M a  x   b a  c k   t  r i p l  e   c a  m e r a    5 . 7  m m   f  / 1 . 5

        25321   GPSTag GPS Info IFD
                000E <14 entries>
        25323   0001    0002 Ascii      00000002(len=2) 4E000000        GPSLatitudeRef  4E to text is: N
        25335   0002    0005 Rational   00000003        00000A2E(2606)  GPSLatitude     ref: 25495
        25347   0003    0002 Ascii      00000002(len=2) 57000000        GPSLongitudeRef 57 to text is: W
        25359   0004    0005 Rational   00000003        00000A46(2630)  GPSLongitude    ref: 25519
        25371   0005    0001 byte       00000001        00000000        GPSAltitudeRef 0 = Above sea level | 1 = Below sea level
        25383   0006    0005 Rational   00000001        00000A5E(2654)  GPSAltitude     ref: 25543      or 2654 meters?
        25395   000C    0002 Ascii      00000002(len=2) 4B000000        GPSSpeedRef     4B to text is: K Kilometers per hour
        25407   000D    0005 Rational   00000001        00000A66(2662)  GPSSpeed        ref: 25551      or 2662?
        25419   0010    0002 Ascii      00000002(len=2) 54000000        GPSImgDirectionRef      54 to text is: T: True direction
        25431   0011    0005 Rational   00000001        00000A6E(2670)  GPSImgDirection ref: 25559
        25443   0017    0002 Ascii      00000002(len=2) 54000000        GPSDestBearingRef        54 to text is: T: True direction
        25455   0018    0005 Rational   00000001        00000A76(2678)  GPSDestBearing  ref: 25567
        25467   001D    0002 Ascii      0000000B(len=11)00000A7E(2686)  GPSDateStamp    ref: 25575
        25479   001F    0005 Rational   00000001(len=1) 00000A8A(2698)  ??              ref: 25587
        25491   00000000 <end of IFD>

        25495   00000025 00000001       GPSLatitude:    37/1
                0000001C 00000001                       28/1
                0000050E 00000064                       1294/100
        25519   00000079 00000001      GPSLongitude:    121/1
                00000037 00000001                       55/1
                00000DC0 00000064                       3520/100
        25543   0000C32F 00001A8C
        25551   00000000 00000001
        25559   0001B38E 000003C3       111502 / 963
        25567   0001B38E 000003C3       111502 / 963
        25575   32303231 3A31323A 32330000      GPSDateStamp: 2021:12:23
        25587   0001A6B0 00002425
                3C783A78 6D706D65 74612078 6D6C6E73 3A783D22 61646F62 653A6E73 3A6D6574 612F2220 783A786D 70746B3D 22584D50 20436F72 6520362E 302E3022 3E0A2020 203C7264 663A5244 4620786D 6C6E733A 7264663D 22687474 703A2F2F 7777772E 77332E6F 72
        
*/

function getStringFromDB(buffer, start, length) {
    var outstr = "";
    for (var n = start; n < start + length; n++) {
        outstr += String.fromCharCode(buffer.getUint8(n));
    }
    return outstr;
}

function readTagValue(file, entryOffset, tiffStart, dirStart, bigEnd) {
    var type = file.getUint16(entryOffset + 2, !bigEnd),
        numValues = file.getUint32(entryOffset + 4, !bigEnd),
        valueOffset = file.getUint32(entryOffset + 8, !bigEnd) + tiffStart,
        offset,
        vals, val, n,
        numerator, denominator;

    switch (type) {
        case 1: // byte, 8-bit unsigned int
        case 7: // undefined, 8-bit byte, value depending on field
            if (numValues == 1) {
                return file.getUint8(entryOffset + 8, !bigEnd);
            } else {
                offset = numValues > 4 ? valueOffset : (entryOffset + 8);
                vals = [];
                for (n = 0; n < numValues; n++) {
                    vals[n] = file.getUint8(offset + n);
                }
                return vals;
            }

        case 2: // ascii, 8-bit byte
            offset = numValues > 4 ? valueOffset : (entryOffset + 8);
            return getStringFromDB(file, offset, numValues - 1);

        case 3: // short, 16 bit int
            if (numValues == 1) {
                return file.getUint16(entryOffset + 8, !bigEnd);
            } else {
                offset = numValues > 2 ? valueOffset : (entryOffset + 8);
                vals = [];
                for (n = 0; n < numValues; n++) {
                    vals[n] = file.getUint16(offset + 2 * n, !bigEnd);
                }
                return vals;
            }

        case 4: // long, 32 bit int
            if (numValues == 1) {
                return file.getUint32(entryOffset + 8, !bigEnd);
            } else {
                vals = [];
                for (n = 0; n < numValues; n++) {
                    vals[n] = file.getUint32(valueOffset + 4 * n, !bigEnd);
                }
                return vals;
            }

        case 5: // rational = two long values, first is numerator, second is denominator
            if (numValues == 1) {
                numerator = file.getUint32(valueOffset, !bigEnd);
                denominator = file.getUint32(valueOffset + 4, !bigEnd);
                val = new Number(numerator / denominator);
                val.numerator = numerator;
                val.denominator = denominator;
                return val;
            } else {
                vals = [];
                for (n = 0; n < numValues; n++) {
                    numerator = file.getUint32(valueOffset + 8 * n, !bigEnd);
                    denominator = file.getUint32(valueOffset + 4 + 8 * n, !bigEnd);
                    vals[n] = new Number(numerator / denominator);
                    vals[n].numerator = numerator;
                    vals[n].denominator = denominator;
                }
                return vals;
            }

        case 9: // slong, 32 bit signed int
            if (numValues == 1) {
                return file.getInt32(entryOffset + 8, !bigEnd);
            } else {
                vals = [];
                for (n = 0; n < numValues; n++) {
                    vals[n] = file.getInt32(valueOffset + 4 * n, !bigEnd);
                }
                return vals;
            }

        case 10: // signed rational, two slongs, first is numerator, second is denominator
            if (numValues == 1) {
                return file.getInt32(valueOffset, !bigEnd) / file.getInt32(valueOffset + 4, !bigEnd);
            } else {
                vals = [];
                for (n = 0; n < numValues; n++) {
                    vals[n] = file.getInt32(valueOffset + 8 * n, !bigEnd) / file.getInt32(valueOffset + 4 + 8 * n, !bigEnd);
                }
                return vals;
            }
    }
}

function readTags(file, tiffStart, dirStart, strings, bigEnd) {
    var entries = file.getUint16(dirStart, !bigEnd),
        tags = {},
        entryOffset, tag,
        i;

    for (i = 0; i < entries; i++) {
        entryOffset = dirStart + i * 12 + 2;
        tag = strings[file.getUint16(entryOffset, !bigEnd)];
        // if (!tag && debug) console.log("Unknown tag: " + file.getUint16(entryOffset, !bigEnd));
        tags[tag] = readTagValue(file, entryOffset, tiffStart, dirStart, bigEnd);
    }
    return tags;
}

const TiffTags = {
    0x0100: "ImageWidth",
    0x0101: "ImageHeight",
    0x8769: "ExifIFDPointer",
    0x8825: "GPSInfoIFDPointer",
    0xA005: "InteroperabilityIFDPointer",
    0x0102: "BitsPerSample",
    0x0103: "Compression",
    0x0106: "PhotometricInterpretation",
    0x0112: "Orientation",
    0x0115: "SamplesPerPixel",
    0x011C: "PlanarConfiguration",
    0x0212: "YCbCrSubSampling",
    0x0213: "YCbCrPositioning",
    0x011A: "XResolution",
    0x011B: "YResolution",
    0x0128: "ResolutionUnit",
    0x0111: "StripOffsets",
    0x0116: "RowsPerStrip",
    0x0117: "StripByteCounts",
    0x0201: "JPEGInterchangeFormat",
    0x0202: "JPEGInterchangeFormatLength",
    0x012D: "TransferFunction",
    0x013E: "WhitePoint",
    0x013F: "PrimaryChromaticities",
    0x0211: "YCbCrCoefficients",
    0x0214: "ReferenceBlackWhite",
    0x0132: "DateTime",
    0x010E: "ImageDescription",
    0x010F: "Make",
    0x0110: "Model",
    0x0131: "Software",
    0x013B: "Artist",
    0x8298: "Copyright"
};

const ExifTags = {

    // version tags
    0x9000: "ExifVersion", // EXIF version
    0xA000: "FlashpixVersion", // Flashpix format version

    // colorspace tags
    0xA001: "ColorSpace", // Color space information tag

    // image configuration
    0xA002: "PixelXDimension", // Valid width of meaningful image
    0xA003: "PixelYDimension", // Valid height of meaningful image
    0x9101: "ComponentsConfiguration", // Information about channels
    0x9102: "CompressedBitsPerPixel", // Compressed bits per pixel

    // user information
    0x927C: "MakerNote", // Any desired information written by the manufacturer
    0x9286: "UserComment", // Comments by user

    // related file
    0xA004: "RelatedSoundFile", // Name of related sound file

    // date and time
    0x9003: "DateTimeOriginal", // Date and time when the original image was generated
    0x9004: "DateTimeDigitized", // Date and time when the image was stored digitally
    0x9290: "SubsecTime", // Fractions of seconds for DateTime
    0x9291: "SubsecTimeOriginal", // Fractions of seconds for DateTimeOriginal
    0x9292: "SubsecTimeDigitized", // Fractions of seconds for DateTimeDigitized

    // picture-taking conditions
    0x829A: "ExposureTime", // Exposure time (in seconds)
    0x829D: "FNumber", // F number
    0x8822: "ExposureProgram", // Exposure program
    0x8824: "SpectralSensitivity", // Spectral sensitivity
    0x8827: "ISOSpeedRatings", // ISO speed rating
    0x8828: "OECF", // Optoelectric conversion factor
    0x9201: "ShutterSpeedValue", // Shutter speed
    0x9202: "ApertureValue", // Lens aperture
    0x9203: "BrightnessValue", // Value of brightness
    0x9204: "ExposureBias", // Exposure bias
    0x9205: "MaxApertureValue", // Smallest F number of lens
    0x9206: "SubjectDistance", // Distance to subject in meters
    0x9207: "MeteringMode", // Metering mode
    0x9208: "LightSource", // Kind of light source
    0x9209: "Flash", // Flash status
    0x9214: "SubjectArea", // Location and area of main subject
    0x920A: "FocalLength", // Focal length of the lens in mm
    0xA20B: "FlashEnergy", // Strobe energy in BCPS
    0xA20C: "SpatialFrequencyResponse", //
    0xA20E: "FocalPlaneXResolution", // Number of pixels in width direction per FocalPlaneResolutionUnit
    0xA20F: "FocalPlaneYResolution", // Number of pixels in height direction per FocalPlaneResolutionUnit
    0xA210: "FocalPlaneResolutionUnit", // Unit for measuring FocalPlaneXResolution and FocalPlaneYResolution
    0xA214: "SubjectLocation", // Location of subject in image
    0xA215: "ExposureIndex", // Exposure index selected on camera
    0xA217: "SensingMethod", // Image sensor type
    0xA300: "FileSource", // Image source (3 == DSC)
    0xA301: "SceneType", // Scene type (1 == directly photographed)
    0xA302: "CFAPattern", // Color filter array geometric pattern
    0xA401: "CustomRendered", // Special processing
    0xA402: "ExposureMode", // Exposure mode
    0xA403: "WhiteBalance", // 1 = auto white balance, 2 = manual
    0xA404: "DigitalZoomRation", // Digital zoom ratio
    0xA405: "FocalLengthIn35mmFilm", // Equivalent foacl length assuming 35mm film camera (in mm)
    0xA406: "SceneCaptureType", // Type of scene
    0xA407: "GainControl", // Degree of overall image gain adjustment
    0xA408: "Contrast", // Direction of contrast processing applied by camera
    0xA409: "Saturation", // Direction of saturation processing applied by camera
    0xA40A: "Sharpness", // Direction of sharpness processing applied by camera
    0xA40B: "DeviceSettingDescription", //
    0xA40C: "SubjectDistanceRange", // Distance to subject

    // other tags
    0xA005: "InteroperabilityIFDPointer",
    0xA420: "ImageUniqueID" // Identifier assigned uniquely to each image
};

const GPSTags = {
    0x0000: "GPSVersionID",
    0x0001: "GPSLatitudeRef",
    0x0002: "GPSLatitude",
    0x0003: "GPSLongitudeRef",
    0x0004: "GPSLongitude",
    0x0005: "GPSAltitudeRef",
    0x0006: "GPSAltitude",
    0x0007: "GPSTimeStamp",
    0x0008: "GPSSatellites",
    0x0009: "GPSStatus",
    0x000A: "GPSMeasureMode",
    0x000B: "GPSDOP",
    0x000C: "GPSSpeedRef",
    0x000D: "GPSSpeed",
    0x000E: "GPSTrackRef",
    0x000F: "GPSTrack",
    0x0010: "GPSImgDirectionRef",
    0x0011: "GPSImgDirection",
    0x0012: "GPSMapDatum",
    0x0013: "GPSDestLatitudeRef",
    0x0014: "GPSDestLatitude",
    0x0015: "GPSDestLongitudeRef",
    0x0016: "GPSDestLongitude",
    0x0017: "GPSDestBearingRef",
    0x0018: "GPSDestBearing",
    0x0019: "GPSDestDistanceRef",
    0x001A: "GPSDestDistance",
    0x001B: "GPSProcessingMethod",
    0x001C: "GPSAreaInformation",
    0x001D: "GPSDateStamp",
    0x001E: "GPSDifferential"
};


/*
// enable this block to test locally
var fs = require('fs');
let filePath = "/Users/Dongminator/Downloads/IMG_7458.HEIC"
var ab = fs.readFileSync(filePath, null).buffer;
var dataView = new DataView(ab);
console.log(dataView);
var gpsData = getGpsDataFromDataView(dataView);
console.log(gpsData);
console.log(gpsData["GPSLatitude"]);
console.log(gpsData["GPSLatitudeRef"]);
*/

function getGpsDataFromDataView(dataView) {

    var ftypSize = dataView.getUint32(0);
    console.log("ftypSize: " + ftypSize);

    var metadataSize = dataView.getUint32(ftypSize); //size of metadata box
    console.log("size of metadata box: " + metadataSize);

    //Scan through metadata until we find (a) Exif, (b) iloc
    var exifOffset = -1;
    var ilocOffset = -1;

    for (var i = ftypSize; i < metadataSize + ftypSize; i++) {
        if (getStringFromDB(dataView, i, 4) == "Exif") {
            exifOffset = i;
        } else if (getStringFromDB(dataView, i, 4) == "iloc") {
            ilocOffset = i;
        }
    }
    console.log(exifOffset); // 1278
    console.log(ilocOffset);

    var exifItemIndex = dataView.getUint16(exifOffset - 4); // 1274
    console.log(exifItemIndex);
    //Scan through ilocs to find exif item location
    // 3890 - 4754; 16 increment

    var start = ilocOffset + 12;
    var end = metadataSize + ftypSize;
    console.log(start + " " + end + " with 16 increment");
    var exifLocation = 0;
    for (var i = start; i < end; i += 16) {
        var itemIndex = dataView.getUint16(i);
        if (itemIndex == exifItemIndex) { // 53
            exifLocation = dataView.getUint32(i + 8); // exiflocation = 22879
            break;
        }
    }
    // 00000006 45786966 0000 4D4D 002A 0000 0008
    return getGpsFromPosition(dataView, exifLocation + 4, dataView.getUint32(exifLocation));
}

function getGpsDataFromJpgDataView(dataView) {
    var exifLocation = 0;
    for (var i = 0; i < 96; i += 2) {
        var ifExif = dataView.getUint32(i);
        console.log(ifExif.toString(16));
        if (ifExif == 0x45786966) {
            var ifMM = dataView.getUint32(i + 4);
            if (ifMM == 0x00004D4D) {
                exifLocation = i;
                break;
            }
        }
    }
    // 45786966 00004D4D 002A
    return getGpsFromPosition(dataView, exifLocation, 6);
}

// exifStart: 45786966 0000 4D4D 002A 0000 0008
function getGpsFromPosition(dataView, exifStart, offset) {
    var tiffOffset = exifStart + offset; // 4D4D 002A 0000 0008
    var bigEnd = true;
    var firstIFDOffset = dataView.getUint32(tiffOffset + 4, !bigEnd);
    console.log(exifStart);
    console.log(tiffOffset);
    console.log(firstIFDOffset);
    var tags = readTags(dataView, tiffOffset, tiffOffset + firstIFDOffset, TiffTags, bigEnd);
    console.log(tags);

    var exifData = readTags(dataView, tiffOffset, tiffOffset + tags.ExifIFDPointer, ExifTags, bigEnd);
    console.log(exifData);

    var gpsData = readTags(dataView, tiffOffset, tiffOffset + tags.GPSInfoIFDPointer, GPSTags, bigEnd);
    return gpsData;
}

export function getGpsData(file, stateSetGpsData) {
    console.log("image-get-exif.js getGpsData");

    var reader = new FileReader();
    reader.onload = function() {
        var data = reader.result;
        // 1. build DateViewer
        var dataView = new DataView(data);
        console.log(dataView);

        // 2. check first if start with FFD8, if so, JPEG
        // getUint32 will read 4 bytes (4*8=32)
        // getUint16 will read 2 bytes (2*8=16)
        var first2bytes = dataView.getUint16(0);
        console.log(dataView.getUint32(0));
        console.log(dataView.getUint32(4));

        // find "Exif MM"
        if (first2bytes == 0xFFD8) {
            console.log("file is jpeg");

            var gpsData = getGpsDataFromJpgDataView(dataView);
            console.log(gpsData);
            stateSetGpsData(gpsData);
            return gpsData;

            // use exifr lib to parse file
            // TODO
        } else {
            console.log("file is not jpeg");

            var ftypSize = dataView.getUint32(0); // size of ftyp box
            var ftypStringCheck = dataView.getUint32(4);
            var exifStringCheck = dataView.getUint32(8);
            console.log("ftypSize: " + ftypSize);
            if (ftypStringCheck == 0x66747970 && exifStringCheck == 0x68656963) {
                console.log("file is heic");

                // 2.a. if jpeg

                // 2.b. if not jpeg,
                // check first 12 bytes:
                // 00 00 00 xx  
                // 66 74 79 70 => ftyp
                // 68 65 69 63 => heic
                // get meta block size
                // find Exif
                // find iloc

                var gpsData = getGpsDataFromDataView(dataView);
                console.log(gpsData);
                stateSetGpsData(gpsData);
                return gpsData;
            } else {
                console.log("file is unknown");
            }
        }
    };
    reader.readAsArrayBuffer(file);
}