import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'react-bootstrap';


const LoginForm = (props) => {
    // Pass the useFormik() hook initial form values and a submit function that will
    // be called when the form is submitted
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            password: Yup.string()
                .min(8, 'Must be 8 characters or more')
                .required('Required'),
        }),
        onSubmit: values => {
            console.log("validation passed, submitting ...");
            console.log(values);
            props.handleLoginEvent(values);
        },
        validateOnBlur: false,
        validateOnChange: false,
    });
    return (
        <Form className="login-register-form" onSubmit={formik.handleSubmit}>
            <div className="login-register-form-grid-left">
                Email address
            </div>
            <div className="login-register-form-grid-right">
                <input
                    name="email"
                    placeholder="Enter email"
                    type="email"
                    id="formLoginEmail"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                />
            </div>
            {formik.touched.email && formik.errors.email ? (
                <div className="login-register-error-message">{formik.errors.email}</div>
            ) : null}

            <div className="login-register-form-grid-left">
                Password
            </div>
            <div className="login-register-form-grid-right">
                <input
                    id="formLoginPassword"
                    className="form-control"
                    name="password"
                    type="password"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                />
            </div>
            {formik.touched.password && formik.errors.password ? (
                <div className="login-register-error-message">{formik.errors.password}</div>
            ) : null}

            <div className="col text-center">
                <button className="unregistered-form-button" type="submit">Login</button>
            </div>
        </Form>
    );
};
export default LoginForm;