import React, { Component } from 'react';
import { GetExifFromExifr } from './helper/image.js'

const initialState = {
  fileUploadState: "",
  userSelectedPhotos: [],
  uploadCompletePhotos: []
}
export default class ImageUploadComponent extends Component {

  constructor(props) {
    super(props);

    this.state = initialState;
    this.inputReference = React.createRef();
  }

  fileUploadAction = () => {
    console.log("fileUploadAction");
    this.inputReference.current.click();
  }

  handleChange = (e) => {
    console.log("handleChange called");
    var files = e.target.files;
    console.log(files);
    if (files && files.length > 0)
    {
      this.props.setCreateTimeEpochInSeconds();
      let userSelectedPhotos = Array.from(files).map(f => {
        return {
          lastModified: f.lastModified,
          name: f.name,
          size: f.size,
          type: f.type
        };
      });
      console.log(userSelectedPhotos);
      this.setState({
        userSelectedPhotos: userSelectedPhotos
      });
      // files is type of FileList, which is not an Array object.
      Array.from(files).forEach(file => {
        if (file && file.name) {
          console.log(file);
          const self = this;
          GetExifFromExifr(file, function (updatedFile) {
            console.log(updatedFile);
            self.props.draftPhotoUploadedEvent(updatedFile);
            self.setState(prevState => ({
                uploadCompletePhotos: [...prevState.uploadCompletePhotos, {
                    lastModified: updatedFile.lastModified,
                    name: updatedFile.name,
                    size: updatedFile.size,
                    type: updatedFile.type
                }]
            }));
            self.handlePhotosReady();
          });
        }
      });
    }

    // set selected file to nothing, so when we select the same file again, the onChange event is fired.
    e.target.value = ''
  }

  convertEXIFGPSLatitudeToDecimal (hour, min, sec, dir) {
    return (dir === 'N' ? 1 : -1) * (hour + (min * 60 + sec) / 3600);
  }

  convertEXIFGPSLongitudeToDecimal (hour, min, sec, dir) {
    return (dir === 'E' ? 1 : -1) * (hour + (min * 60 + sec) / 3600);
  }

  handlePhotosReady = () => {
    console.log("handlePhotosReady");
    console.log(this.state.userSelectedPhotos);
    console.log(this.state.uploadCompletePhotos);
    if (this.state.userSelectedPhotos.length === this.state.uploadCompletePhotos.length)
    {
      this.setState(prevState => (initialState));
      this.props.handleReadyToRouteEvent();
    }
  }

  // use exifr library to get gps data.
  // this call this.load2(file);
  // NOT USED.
  // async load2(file) {
  //   // only get gps data, i.e. lat, lng
  //   var z = await exifr.gps(file);
  //   console.log("lat long from exifr lib: ");
  //   console.log(z);
  //   this.props.draftPhotoUploadedEvent(z);
  // }

  render() {
    return (
      <>
          <input
            hidden
            ref={this.inputReference}
            type="file"
            accept=".jpg, .png, .heif, .heic"
            onChange={this.handleChange}
            multiple
          />
      </>
    )
  }

}