import React from 'react';
import './Header.css';
import { Navbar, Nav, Container } from 'react-bootstrap';

const HeaderComponent = (props) => {
    return (
        <Navbar bg="light" expand="lg" id="header-navbar" >
            <Container fluid>
                <Navbar.Brand href="/">Route</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav className="justify-content-end">
                        <Nav.Link onClick={(e) => props.onClickLogout(e)}>Logout</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
export default HeaderComponent;
