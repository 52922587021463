import React, { Component } from 'react';
import './App.css';
import DemoComponent from './DemoComponent';
import LandingComponent from './LandingComponent';
import PrivacyComponent from './PrivacyComponent';
import TermsComponent from './TermsComponent';
import TestComponent from './test/TestComponent';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/test">
            <TestComponent />
          </Route>
          <Route path="/demo">
            <DemoComponent />
          </Route>
          <Route path={["/privacy", "/privacy.html"]}>
            <PrivacyComponent />
          </Route>
          <Route path={["/tos", "/tos.html"]}>
            <TermsComponent />
          </Route>
          <Route path="/">
            <LandingComponent />
          </Route>
        </Switch>

      </Router>
    );
  }
}
export default App;
