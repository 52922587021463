import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom'
import { CreateThumbnail } from './helper/image.js';
import './MarkerThumbnail.css';

/*
class marker
{
    icon
    map
    location: LatLng|LatLngLiteral
    animation

    routeCreateTimeEpochInSeconds
    positionOnRoute

    file (this is a blob/file)
        type
        originalImage
        originalImageWidth
        originalImageHeight
        thumbnail
}

to create url: URL.createObjectURL(blob)

event
=> onclick:
{
    show image
}
*/
const MarkerThumbnailComponent = (props) => {
    const clickDelta = 6;
    let clickStartX;
    let clickStartY;
    const [thumbnail, setThumbnail] = useState(null);

    /*
        nothing is rendered by react, so maybe do not use useEffect.
        only thing can change is:
        - draftMode: true/false
        - marker?
        - this can be removed
        test add/remove marker
        test draft mode, test view mode

        onadd and useEffect[props.draftMode] got run at the same itme.
        - onadd has draftMode = false
        - useEffect[props.draftMode] does not have markerDiv
        so make sure draftMode is set early.
     */

    // in functional component, store local variable in useRef.
    let _t_thumbnail = useRef();
    let _t_overlayView = useRef();
    let markerDiv = useRef();
    // component mounted
    useEffect(() => {
        // download thumbnail
        GetThumbnail();

        return () => {
            if (_t_overlayView.current) {
                _t_overlayView.current.setMap(null); // this will invoke overlayView.onRemove
            }
        };
    }, []);

    const GetThumbnail = async () => {
        setThumbnail(await CreateThumbnail(props.marker.file));
    }

    useEffect(() => {
        if (thumbnail) {
            // thumbnail = thumbnail;
            // create overlayview
            let _overlayView = new props.maps.OverlayView();
            _overlayView.onAdd = onAdd;
            _overlayView.draw = draw;
            _overlayView.onRemove = onRemove;
            _overlayView.setMap(props.map); // when running setMap, will run onAdd and onRemove.
            _t_overlayView.current = _overlayView;
        }
    }, [thumbnail]);

    // this also loads in the first run, where the div has not been setup
    useEffect(() => {
        updateCloseButton(markerDiv.current);
    }, [props.draftMode])

    const updateCloseButton = (_localDiv) => {
        if (_localDiv) {
            const closeButtonElements = _localDiv.getElementsByClassName("marker-thumbnail-btn");
            if (props.draftMode === true && closeButtonElements.length === 0) {
                let closeButtonDiv = document.createElement("div");
                closeButtonDiv.className = "marker-thumbnail-btn";
                let closeButton = document.createElement("button");
                closeButton.className = "btn-close";
                closeButton.onclick = function(e){
                    console.log("close button clicked");
                    console.log(e);
                    props.handleDraftMarkerDelete(props.marker);
                    e.stopPropagation();
                };
                closeButtonDiv.appendChild(closeButton);
                _localDiv.appendChild(closeButtonDiv);
            } else if (props.draftMode === false && closeButtonElements.length > 0) {
                _localDiv.removeChild(closeButtonElements[0]);
            }
        }
    }

    const onAdd = () => {
        let _div = document.createElement("div");
        _div.style.position = "absolute";
        _div.style.cursor = "pointer";
        _div.setAttribute('data-lat', props.marker.location.lat);
        _div.setAttribute('data-lng', props.marker.location.lng);
        _div.setAttribute('data-position', props.marker.positionOnRoute);
        _div.setAttribute('data-timestamp', props.marker.file.lastModified);
        _div.setAttribute('data-curr', new Date()/1000);
        let img = React.createElement(
            "img",
            {
                className: "info-window-img",
                src: thumbnail.uri,
                width: thumbnail.w,
                height: thumbnail.h
            });
        ReactDOM.render(img, _div);

        _div.innerHTML += '<svg viewbox="0 0 10 10" class="triagle"><polygon points="0 0 10 10 0 10 0 0" fill="white" /></svg>';

        const panes = _t_overlayView.current.getPanes();
        panes.floatPane.appendChild(_div);

        // div.addEventListener("touchstart", function(e){
        //     console.log("touchstart");
        //     console.log(e);
        //     props.addDebugEvent("touchstart");
        //     // let touch = e.changedTouches[0];
        //     // clickStartX = touch.pageX;
        //     // clickStartY = touch.pageY;
        //     // e.preventDefault(); // Disable click event.
        //     // e.stopPropagation && e.stopPropagation(); // Disable click event.
        // }, { passive: false, capture: true });

        // div.addEventListener("mousedown", function(e){
        //     console.log("mousedown");
        //     console.log(e);
        //     props.addDebugEvent("mousedown");
        // });


        // div.addEventListener("touchend", function(e){
        //     console.log("touchend");
        //     console.log(e);
        //     props.addDebugEvent("touchend");
        //     // let touch = e.changedTouches[0];
        //     // if (Math.abs(touch.pageX - clickStartX) < clickDelta && Math.abs(touch.pageY - clickStartY) < clickDelta) {
        //     //     console.log("clicked");
        //     //     e.preventDefault(); // Disable click event.
        //     //     e.stopPropagation && e.stopPropagation(); // Disable click event.
        //     //     props.handleMarkerClick(props.marker.file);
        //     // }
        // }, { passive: false, capture: true });


        // div.addEventListener("mouseup", function(e){
        //     console.log("mouseup");
        //     console.log(e);
        //     props.addDebugEvent("mouseup");
        // });


        // div.addEventListener("click", function(e){
        //     console.log("click");
        //     console.log(e);
        //     props.addDebugEvent("click");

        //     e.cancelBubble = true;
        //     if (e.stopPropagation) {
        //         e.stopPropagation();
        //     }
        //     if (e.preventDefault) {
        //         e.preventDefault(); 
        //     }
            
        // }, { passive: false, capture: true });

        // Click issue: when drag end, click event is also triggered.
        // So instead of triggering on click event, we trigger on mouseup event when moved distance is less than delta (6)
        // Order of events: touchstart -> touchmove -> touchend -> mousemove -> mousedown -> mouseup -> click
        props.maps.event.addDomListener(_div, "touchstart", function (e) {
            console.log(e);
            console.log(e.originalEvent);
            if (e.target.tagName.toLowerCase() !== "button") {
                // try find the touch
                let touch = e.changedTouches[0];
                // console.log('google.maps.event.addDomListener ' + e.type + "(" + e.touches[0].pageX + "," + e.touches[0].pageY + ")");
                clickStartX = touch.pageX;
                clickStartY = touch.pageY;
                e.preventDefault(); // Disable click event.
            }
        });
        props.maps.event.addDomListener(_div, "mousedown", function (e) {
            console.log(e);
            console.log('google.maps.event.addDomListener ' + e.type);
            if (e.target.tagName.toLowerCase() !== "button") {
                clickStartX = e.pageX;
                clickStartY = e.pageY;
            }
        });

        props.maps.event.addDomListener(_div, "touchend", function (e) {
            console.log(e);
            console.log(e.originalEvent);
            let touch = e.changedTouches[0];
            if (e.target.tagName.toLowerCase() !== "button") {
                // console.log('google.maps.event.addDomListener ' + e.type + "(" + e.touches[0].pageX + "," + e.touches[0].pageY + ")");
                if (Math.abs(touch.pageX - clickStartX) < clickDelta && Math.abs(touch.pageY - clickStartY) < clickDelta) {
                    console.log("clicked");
                    e.preventDefault(); // Disable click event.
                    e.stopPropagation && e.stopPropagation(); // Disable click event.
                    props.handleMarkerClick(props.marker);
                }
                e.preventDefault(); // Disable click event.
            }
        });
        props.maps.event.addDomListener(_div, "mouseup", function (e) {
            console.log(e);
            console.log('google.maps.event.addDomListener ' + e.type);
            if (e.target.tagName.toLowerCase() !== "button") {
                if (Math.abs(e.pageX - clickStartX) < clickDelta && Math.abs(e.pageY - clickStartY) < clickDelta) {
                    console.log("clicked");
                    e.preventDefault(); // Disable click event.
                    e.stopPropagation && e.stopPropagation(); // Disable click event.
                    props.handleMarkerClick(props.marker);
                }
            }
        });

        props.maps.event.addDomListener(_div, 'click', function (e) {
            console.log(e);
            console.log("google.maps.event.addDomListener click");
            if (e.target.tagName.toLowerCase() !== "button") {
                e.preventDefault(); // Disable click event.
                e.stopPropagation && e.stopPropagation(); // Disable click event.
            }
        });

        updateCloseButton(_div);
        markerDiv.current = _div;
    }

    // re-calculate position
    const draw = () => {
        let pos = props.marker.location;
        const point = _t_overlayView.current.getProjection().fromLatLngToContainerPixel(new props.maps.LatLng(pos.lat, pos.lng));
        if (point) {
            // this div is centered in the map, so to get the true absolute position, need to move to top left corner
            // then move to left by half of image width
            let left = point.x - props.map.getDiv().offsetWidth / 2 - thumbnail.w / 2;
            // then move up by full image height and triangle height (10px)
            let top = point.y - props.map.getDiv().offsetHeight / 2 - thumbnail.h - 10;

            // if left or top is outside view, then do not re-draw.
            markerDiv.current.style.left = `${left}px`;
            markerDiv.current.style.top = `${top}px`;
        }
    }

    // when setMap(null) is called, this onRemove is called
    const onRemove = () => {
        if (markerDiv.current) {
            let parent = markerDiv.current.parentNode;
            parent.removeChild(markerDiv.current);
        }
    }
    return (null);
};
export default MarkerThumbnailComponent;
